import { Observable } from 'rxjs';

import {
  EmptyResponse,
  MediaPageResponse,
  MediaResponse,
  SnippetResponse,
  UserFeedType,
  ViewReq,
  ViewResp,
} from '@api/backend';

export abstract class MediaRepository {
  /**
   * getMediaById
   * @param media_id
   */
  public abstract getMediaById(media_id: string): Observable<MediaResponse>;

  /**
   * getRecommendedMedia
   * @param page_token
   * @param country_code
   */
  public abstract getRecommendedMedia(page_token?: string, country_code?: string): Observable<MediaPageResponse>;

  /**
   * getUserMedia
   *
   * @param user_id
   * @param feed_type
   * @param page_token
   */
  public abstract getUserMedia(user_id: string, feed_type: UserFeedType, page_token?: string): Observable<MediaPageResponse>;

  /**
   * method getMediaSnippet
   * @param media_id
   */
  public abstract getMediaSnippet(media_id: string): Observable<SnippetResponse>;

  /**
   * method shareAsync
   * @param media_id
   */
  public abstract shareAsync(media_id: string): Observable<EmptyResponse>;

  /**
   * method postMediaView
   * @param body
   */
  public abstract postMediaView(body: ViewReq): Observable<ViewResp>;

}
