import { AfterViewInit, ChangeDetectionStrategy, Component, input, signal, Signal, viewChild } from '@angular/core';

import { BreakpointFacade, UserFacade } from '@core/facades';
import { I18NextString, User } from '@core/models';
import { OverlayService, PlatformService } from '@core/services';
import { isNil } from 'lodash';
import { IconComponent, LoaderComponent } from '@shared/components';
import { ShortNumberPipe } from '@shared/pipes';
import { I18NextModule } from 'angular-i18next';
import { ShowMoreTextComponent } from '@shared/components/show-more-text/show-more-text.component';
import { StatusComponent } from '@shared/components/status/status.component';
import { InfiniteScrollDirective, RippleDirective } from '@shared/directives';
import { EllipsisDirective } from 'ngx-ellipsis';
import { asyncScheduler } from 'rxjs';

interface UserCounter {
  property: keyof User['counters'];
  name: I18NextString;
  action?: () => void;
}

type UserStatus = 'enabled' | 'blocked' | 'deleted';

@Component({
  selector: 'cheelee-user-content',
  standalone: true,
  templateUrl: './user-content.component.html',
  styleUrls: [ './user-content.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    IconComponent,
    ShortNumberPipe,
    I18NextModule,
    ShowMoreTextComponent,
    StatusComponent,
    InfiniteScrollDirective,
    RippleDirective,
    LoaderComponent,
    EllipsisDirective,
  ],
})
export class UserContentComponent implements AfterViewInit {

  public user = input<User>();

  public readonly counters: Signal<UserCounter[]> = signal([
    {
      property: 'likes',
      name: 'common:user likes',
    },
    {
      property: 'followers',
      name: 'common:user followers',
      action: () => {},
    },
    {
      property: 'followings',
      name: 'common:user followings',
      action: () => {},
    },
  ]);

  public readonly isMediaLoading = this.userFacade.feedIsLoading;

  public readonly userMedia = this.userFacade.userMedia;

  public readonly nextPageToken = this.userFacade.nextPageToken;

  private ellipsis = viewChild<EllipsisDirective>('ell');

  public showEllipsis = true;

  public get userStatus(): UserStatus {
    return this.user()?.is_deleted ? 'deleted' : this.user()?.is_blocked ? 'blocked' : 'enabled';
  }

  constructor(
    private readonly userFacade: UserFacade,
    private readonly overlayService: OverlayService,
    private readonly breakpointFacade: BreakpointFacade,
    private platformService: PlatformService,
  ) {}

  ngAfterViewInit() {
    asyncScheduler.schedule(() => this.ellipsis()?.applyEllipsis(), 10);
  }

  public loadNextUserMediaFeed(): void {
    if (isNil(this.nextPageToken())) {
      return;
    }
    this.userFacade.loadUserMedia(true);
  }

  public openMedia(media_id: string): void {
    // this.overlayService.openGetAppDialog();
    this.userFacade.openMedia(media_id);
  }
}
