import { inject, Pipe, PipeTransform } from '@angular/core';

import { I18NextService } from 'angular-i18next';

@Pipe({ name: 'shortNumber', standalone: true })
export class ShortNumberPipe implements PipeTransform {
  private readonly i18NextService = inject(I18NextService);

  public transform(value: number): string {
    let newValue: string;

    if (value >= 1_000) {
      newValue = String((value / 1_000).toFixed(1)) + this.i18NextService.t('common:thousand short');
    } else if (value >= 1_000_000) {
      newValue = String((value / 1_000_000).toFixed(1)) + this.i18NextService.t('common:million short');
    } else {
      newValue = value.toString();
    }

    return newValue;
  }
}
