import { createAction, props } from '@ngrx/store';

import { RecommendationsPage, Snippet } from '@api/backend';
import { ErrorResponse, User } from '@core/models';

export const userActions = {
  /**
   * Get Snippet
   */
  getSnippet: createAction(
    '[User] Get Snippet',
  ),
  getSnippetSuccess: createAction(
    '[User] Get Snippet Success',
    props<{ snippet: Snippet }>(),
  ),
  getSnippetFailure: createAction(
    '[User] Get Snippet Failure',
    props<{ error: ErrorResponse }>(),
  ),

  /**
   * Get User
   */
  getUser: createAction(
    '[User] Get User',
  ),
  getUserSuccess: createAction(
    '[User] Get User Success',
    props<{ user: User }>(),
  ),
  getUserFailure: createAction(
    '[User] Get User Failure',
    props<{ error: ErrorResponse }>(),
  ),
  clearUser: createAction(
    '[User] Clear User',
  ),

  /**
   * Get Browsing user
   */
  getBrowsingUser: createAction(
    '[User] Get Browsing User',
  ),
  getBrowsingUserSuccess: createAction(
    '[User] Get Browsing User Success',
    props<{ browsingUser: User }>(),
  ),
  getBrowsingUserFailure: createAction(
    '[User] Get Browsing User Failure',
    props<{ error: ErrorResponse }>(),
  ),


  /**
   * Load User Media
   */
  loadFeedMedia: createAction(
    '[User] Load User Media',
    props<{
      isNextPart?: boolean;
      isNavigateNext?: boolean;
    }>(),
  ),
  loadFeedMediaInit: createAction(
    '[User] Load User Media Init',
    props<{
      page_token: string | null | undefined;
      isNextPart?: boolean;
      isNavigateNext?: boolean;
    }>(),
  ),
  loadFeedMediaSuccess: createAction(
    '[User] Load User Media Success',
    props<{
      page: RecommendationsPage;
      isNextPart?: boolean;
      isNavigateNext?: boolean;
    }>(),
  ),
  loadFeedMediaFailure: createAction(
    '[User] Load User Media Failure',
    props<{ error: ErrorResponse }>()),
  loadFeedMediaComplete: createAction(
    '[User] Load User Media Complete',
  ),
  clearFeed: createAction(
    '[User] Clear Media',
    (needToReloadFeedMedia: boolean = false) => ({ needToReloadFeedMedia }),
  ),

};
