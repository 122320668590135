<cheelee-header>
  <div class="feed-nav">
    <div class="feed-nav__top">
      <cheelee-top-button
        cheeleeOpenGetApp
        [icon]="'earn.png'"
        [picture]="'lee'"
      >
        <span [style.margin-left.px]="5">
          {{ ('common:start earning' | i18nextEager) }}&nbsp;
        </span>
      </cheelee-top-button>
      <cheelee-top-button
        cheeleeOpenGetApp
        [picture]="'earn'"
        [icon]="'cash.png'"
      >
        <div class="flex align-items-center">
          <cheelee-icon
            [style.display]="'inline-block'"
            iconId="trend-icon"
            size="20"
          />&nbsp;&nbsp;
        </div>
      </cheelee-top-button>
    </div>
    <div class="feed-nav__bottom">
      <cheelee-top-button
        class="feed-nav__left-bottom"
        cheeleeOpenGetApp [picture]="'easy'"
        earn
        [icon]="'easy.png'"
      />
      <cheelee-top-button
        class="feed-nav__right-bottom"
        [icon]="!isMuted() ? 'sprite.svg#icon-not-muted' : 'sprite.svg#icon-muted'"
        (clicked)="toggleMuteClicked.emit()"
      />
    </div>
  </div>
</cheelee-header>
