import { ComponentType } from '@angular/cdk/overlay';
import { Signal } from '@angular/core';

import { I18NextString } from '@core/models/app.model';

export interface CurtainData<T> {
  component: ComponentType<T>;
  title?: I18NextString;
  dynamicTitle?: Signal<string>;
  componentInputs?: { [K in keyof T]?: T[K] };
  isStretchDisabled?: boolean;
  initHeight?: number; // only in dvh
}

export interface ConfirmModalData {
  title: I18NextString;
  text?: I18NextString;
  cancelButtonText: I18NextString;
  confirmButtonText: I18NextString;
}

export interface AvatarDialogData {
  imageEvent: Event;
}
