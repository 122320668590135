<div class="about">
  <div class="about__views">
    <cheelee-icon
      class="about__views-icon"
      iconId="play-video-icon"
      size="16"
    />
    <span class="about__views-count">
      {{ mediaItem.counters.views | shortNumber }}
    </span>
  </div>

  <div class="about__username">
    <a
      class="about__username-text text-1 bold"
      (click)="goToUserPage()"
    >
      <span> &#64;{{ mediaItem.author.user_name }} </span>
    </a>
    <cheelee-icon
      class="about__confirmed-icon"
      *ngIf="mediaItem.author.is_identity_confirmed"
      iconId="confirmed-user-icon"
      size="14"
    />
  </div>

  <cheelee-show-more-text
    class="about__row"
    *ngIf="mediaItem.media_description"
    [text]="mediaItem.media_description"
  />

  <div
    class="about__audio about__row"
    *ngIf="mediaItem.audio as audio"
  >
    <img
      class="about__music-img"
      alt="media audio"
      cheeleeRepeatAnimation
      height="20"
      ngSrc="./assets/img/music.png"
      repeatAnimationAfter="8000"
      width="20"
    />
    <div class="about__audio-name-wrapper">
      <span
        #audioName
        class="about__audio-name"
        [class.running-text]="canPlayRunningText()"
        cheeleeRepeatAnimation
        needPlayAnimationImmediately
        repeatAnimationAfter="2000"
      >
        {{ audioTrackName() }}
        {{ canPlayRunningText() ? '&nbsp;&nbsp;' + audioTrackName() : '' }}
      </span>
    </div>
    <span *ngIf="canPlayRunningText()">&nbsp;...</span>
  </div>
</div>
