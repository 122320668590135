import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, numberAttribute, OnInit } from '@angular/core';

import { SafeUrlPipe } from '@shared/pipes/safe-url.pipe';

@Component({
  standalone: true,
  selector: 'cheelee-icon',
  templateUrl: './icon.component.html',
  styleUrls: [ './icon.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ NgIf, SafeUrlPipe ],
})
export class IconComponent implements OnInit {
  @Input()
  public path = '';

  @Input()
  public iconId = '';

  @Input({ transform: numberAttribute })
  public width = 24;

  @Input({ transform: numberAttribute })
  public height = 24;

  @Input()
  public color = 'white';

  @Input({ transform: numberAttribute })
  public set size(value: number) {
    if (value) {
      this.width = this.height = value;
    }
  }

  public useSprite: boolean | undefined;

  public readonly defaultPath = '/assets/icons/';

  public get fullPath(): string {
    return this.iconId ? 'ui-kit-icons.sprite.svg#' + this.iconId : this.path;
  }

  public ngOnInit(): void {
    if (!this.path && !this.iconId) {
      throw new Error('Icon id or path are required');
    }

    this.useSprite = this.fullPath.includes('.svg#');
  }
}
