import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { sessionActions, sessionSelectors } from '@store/session';

@Injectable({ providedIn: 'root' })
export class SessionFacade {
  public readonly isAuthorized = this.store.selectSignal(sessionSelectors.selectIsSetUser);

  public readonly userId = this.store.selectSignal(sessionSelectors.selectSessionUserId);

  public readonly sessionToken$ = this.store.select(sessionSelectors.selectSessionToken);

  constructor(private readonly store: Store) {}

  public removeAuthSession(): void {
    this.store.dispatch(sessionActions.removeAuthSession());
  }
}
