import actionsEn from '@locales/en/actions.json';
import agreementEn from '@locales/en/agreement.json';
import authEn from '@locales/en/auth.json';
import categoryEn from '@locales/en/category.json';
import commonEn from '@locales/en/common.json';
import dateEn from '@locales/en/date.json';
import editEn from '@locales/en/edit.json';
import errorEn from '@locales/en/error.json';
import filterEn from '@locales/en/filter.json';
import nftEn from '@locales/en/nft.json';
import purchaseEn from '@locales/en/purchase.json';
import seoEn from '@locales/en/seo.json';
import tabBarEn from '@locales/en/tabbar.json';
import actionsRu from '@locales/ru/actions.json';
import agreementRu from '@locales/ru/agreement.json';
import authRu from '@locales/ru/auth.json';
import categoryRu from '@locales/ru/category.json';
import commonRu from '@locales/ru/common.json';
import dateRu from '@locales/ru/date.json';
import editRu from '@locales/ru/edit.json';
import errorRu from '@locales/ru/error.json';
import filterRu from '@locales/ru/filter.json';
import nftRu from '@locales/ru/nft.json';
import purchaseRu from '@locales/ru/purchase.json';
import seoRu from '@locales/ru/seo.json';
import tabBarRu from '@locales/ru/tab-bar.json';

export enum LanguageLocales {
  EN = 'en-US',
  RU = 'ru-RU',
}

export const resources = {
  [LanguageLocales.EN]: {
    actions: actionsEn,
    agreement: agreementEn,
    auth: authEn,
    category: categoryEn,
    common: commonEn,
    error: errorEn,
    filter: filterEn,
    nft: nftEn,
    purchase: purchaseEn,
    seo: seoEn,
    tabbar: tabBarEn,
    date: dateEn,
    edit: editEn,
  },
  [LanguageLocales.RU]: {
    actions: actionsRu,
    agreement: agreementRu,
    auth: authRu,
    category: categoryRu,
    common: commonRu,
    error: errorRu,
    filter: filterRu,
    nft: nftRu,
    purchase: purchaseRu,
    seo: seoRu,
    tabbar: tabBarRu,
    date: dateRu,
    edit: editRu,
  },
};
