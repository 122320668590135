import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconComponent } from '@shared/components';

interface SupportContact {
  icon: string;
  color: string;
  text: string;
  link: string;
}

@Component({
  selector: 'cheelee-support-contacts',
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: './support-contacts.component.html',
  styleUrls: ['./support-contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportContactsComponent {
  public readonly contacts: SupportContact[] = [
    {
      icon: 'support-telegram-icon',
      color: '#60ACF1',
      text: 'Telegram',
      link: 'https://t.me/CheeleeSupportBot',
    },
    {
      icon: 'support-email-icon',
      color: '#9F01F4',
      text: 'Email',
      link: 'mailto:support@cheelee.io',
    },
  ];
}
