import { SwiperOptions } from 'swiper/types';

export const SWIPER_CONFIG: SwiperOptions = {
  direction: 'vertical',
  slidesPerView: 1,
  lazyPreloadPrevNext: 3,
  roundLengths: true,
  grabCursor: false,
  speed: 200,
  simulateTouch: true,
  mousewheel: {
    forceToAxis: true, // Only scrolls on the axis, not diagonally
    sensitivity: 0.5,  // Lower value for smoother scrolling
    releaseOnEdges: true, // Allows scrolling past the first and last slides
  },
};
