/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 */
export type CurrencyEnum = 'RUB' | 'USD' | 'EUR';

export const CurrencyEnum = {
    Rub: 'RUB' as CurrencyEnum,
    Usd: 'USD' as CurrencyEnum,
    Eur: 'EUR' as CurrencyEnum
};

