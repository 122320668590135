import { inject, Injectable } from '@angular/core';
import { Analytics, logEvent } from '@angular/fire/analytics';
import { YandexMetrikaService } from '@core/services';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {

  private analytics = inject(Analytics);

  private yandexMetrikaService = inject(YandexMetrikaService);

  public logEvent(eventName: string, params?: { [key:string]: string | undefined }): void {
    try {
      this.yandexMetrikaService.reachGoal(eventName);
      logEvent(this.analytics, eventName, {
        ...params,
      });
    } catch (e) {}
  }
}
