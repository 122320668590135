import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ComponentHelperService {
  constructor(private router: Router) {}

  public reloadComponent(isSelf: boolean, urlToNavigateTo = '/'): void {
    const url = isSelf ? this.router.url : urlToNavigateTo;

    void this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      void this.router.navigate([`/${url}`]);
    });
  }
}
