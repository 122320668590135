/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ListCommentsReq { 
    media_id: string;
    parent_id?: string | null;
    /**
     * Can be empty or null for first response
     */
    page_token?: string | null;
    page_size: number | null;
}

