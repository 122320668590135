import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { BannerPictureType, Media, MediaActionsMode } from '@core/models';
import { OverlayService, ShareLinkService } from '@core/services';
import { AvatarComponent, IconComponent } from '@shared/components';
import { RippleDirective, ThrottleClickDirective } from '@shared/directives';
import { ShortNumberPipe } from '@shared/pipes';
import { AnalyticsDirective } from '@shared/directives/analytics.directive';
import { AnalyticsEvents } from '@core/models/analytics.model';

@Component({
  selector: 'cheelee-media-actions',
  standalone: true,
  imports: [
    CommonModule,
    AvatarComponent,
    IconComponent,
    ShortNumberPipe,
    RippleDirective,
    ThrottleClickDirective,
    AnalyticsDirective,
  ],
  templateUrl: './media-actions.component.html',
  styleUrls: ['./media-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaActionsComponent {

  @Output()
  public openComments = new EventEmitter<void>();

  @Output()
  public closeComments = new EventEmitter<void>();

  @Input()
  public mode: MediaActionsMode = MediaActionsMode.RECOMMENDATIONS;

  @Output()
  public like = new EventEmitter<void>();

  // @ViewChild('avatar', { read: AvatarComponent })
  // private readonly avatar!: AvatarComponent;

  @Input({ required: true })
  public set mediaItem(media: Media) {
    this.media.set(media);
  }

  public media = signal<Media | undefined>(undefined);

  public withLikeAnimation = signal(false);

  public readonly analyticsEvents = AnalyticsEvents;

  constructor(
    private readonly overlayService: OverlayService,
    private readonly shareLinkService: ShareLinkService,
  ) {}


  public openGetAppDialog(picture?: BannerPictureType): void {
    this.overlayService.openGetAppDialog(picture);
  }

  public openShareCurtain(): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion,@typescript-eslint/naming-convention
    const { media_id, media_name } = this.media()!;
    this.shareLinkService.share(media_id, media_name || 'Watch Cheelee - get cash | Cheelee', 'media');
  }
}
