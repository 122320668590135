import {
  RequestUserInfo,
  UserResponse as ApiUserResponse,
  User as ApiUser,
  GenderType,
  UserFollowersPage,
  UserFollowingsPage,
  Snippet as ApiSnippet,
} from '@api/backend';

export type User = ApiUser;
export type UserResponse = ApiUserResponse;
export type UserRequest = RequestUserInfo;

export type UserGender = GenderType;

export interface FollowData {
  id: string;
  type: 'followers' | 'followings';
}

export type UserFollowers = UserFollowersPage;

export type UserFollowings = UserFollowingsPage;

export type FollowUser = 'follower' | 'following';

export type Snippet = ApiSnippet;
