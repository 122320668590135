import { DOCUMENT, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, Inject, Input, signal } from '@angular/core';

import { I18NextModule, I18NextService } from 'angular-i18next';
import { DeviceDetectorService } from 'ngx-device-detector';

import { FeedFacade } from '@core/facades';
import { SharedData } from '@core/models';
import { OverlayService, SnackbarService } from '@core/services';
import { ModalsNames } from '@data/const';
import { IconComponent } from '@shared/components';
import { RippleDirective } from '@shared/directives';
import { environment } from '@environments/environment';

interface ShareLinks {
  link: string;
  facebook: string;
  vkontakte: string;
  twitter: string;
  linkedin: string;
  telegram: string;
  viber: string;
  whatsapp: string;
}

@Component({
  standalone: true,
  selector: 'cheelee-share',
  templateUrl: './share.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ NgIf, I18NextModule, IconComponent, RippleDirective ],
})
export class ShareComponent {
  @Input()
  public sharedData!: SharedData;

  public remoteLocale: string | null = this.document.defaultView?.localStorage.getItem('remoteLocale') || null;

  public isMobile = signal(this.deviceDetectorService.isMobile());

  private shareLinks: ShareLinks = {
    link: '{link}',
    facebook: 'https://www.facebook.com/sharer.php?src=sp&u={link}&title={title}',
    vkontakte: 'https://vk.com/share.php?url={link}&title={title}',
    twitter: 'https://twitter.com/intent/tweet?text={title}&url={link}',
    linkedin: 'https://www.linkedin.com/shareArticle?mini=true&url={link}&title={title}',
    telegram: 'https://t.me/share/url?url={link}&text={title}',
    viber: 'viber://forward?text={title}%20{link}',
    whatsapp: 'https://api.whatsapp.com/send?text={title}%20{link}',
  };

  constructor(
    private feedFacade: FeedFacade,
    private deviceDetectorService: DeviceDetectorService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private i18NextService: I18NextService,
    @Inject(DOCUMENT)
    private document: Document,
  ) {}

  @HostListener('window:copy', ['$event'])
  public onCopy(event: ClipboardEvent): void {
    const clipboard = event.clipboardData;
    clipboard?.setData('text', this.sharedData.link ?? this.generateLink());
    event.preventDefault();
  }

  public copyLink(): void {
    if (!navigator.clipboard) {
      // noinspection JSDeprecatedSymbols
      this.document.execCommand('copy');
    } else {
      void this.document.defaultView?.navigator.clipboard.writeText(this.sharedData.link ?? this.generateLink()).then();
    }

    if (this.sharedData.id && this.sharedData.type) {
      this.feedFacade.updateShareCounter(this.sharedData.id, this.sharedData.type);
    }

    this.snackbarService.show('common:link copied');
    this.closeOverlay();
  }

  public shareLink(resource: keyof ShareLinks): void {
    let link = this.shareLinks[resource as keyof ShareLinks].replace(
      /{link}/gi,
      this.sharedData.link ?? this.generateLink(),
    );

    link = link.replace(
      /{title}/gi,
      this.sharedData.link
        ? ''
        : 'Watch Cheelee - get cash | Cheelee',
      // : (this.i18NextService.t('common:you must see this') as string),
    );

    this.document.defaultView?.window.open(link, '_blank');

    if (this.sharedData.id && this.sharedData.type) {
      this.feedFacade.updateShareCounter(this.sharedData.id, this.sharedData.type);
    }

    this.closeOverlay();
  }

  private generateLink(): string {
    const shareBaseUrl = environment.redirectServiceUrl || this.document.defaultView?.location.origin || '';
    if (this.sharedData.type === 'media') {
      return `${shareBaseUrl}/media/${this.sharedData.id}`;
    }

    return `${shareBaseUrl}/users/${this.sharedData.id}`;
  }

  public closeOverlay(): void {
    const name = this.sharedData.view === 'dialog' ? ModalsNames.ShareMedia : ModalsNames.Curtain;
    this.overlayService.close(name);
  }
}
