/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserVideoV2Likes } from './userVideoV2Likes';
import { ChallengeMediaStage } from './challengeMediaStage';
import { SystemFrameAppRatingV1 } from './systemFrameAppRatingV1';
import { SystemFrameRegionV1 } from './systemFrameRegionV1';
import { UserVideoV2 } from './userVideoV2';
import { UserVideoV2Shares } from './userVideoV2Shares';
import { UserLikePreview } from './userLikePreview';
import { Media } from './media';
import { FeedVideo } from './feedVideo';
import { MediaCounters } from './mediaCounters';
import { SystemVideoV1Button } from './systemVideoV1Button';
import { SystemVideoV1 } from './systemVideoV1';
import { UserVideoV2Comments } from './userVideoV2Comments';
import { ModerationStatus } from './moderationStatus';
import { SystemVideoV1Profile } from './systemVideoV1Profile';
import { UserVideoV2Audio } from './userVideoV2Audio';
import { ParentResource } from './parentResource';
import { UserVideoV2Meta } from './userVideoV2Meta';
import { UserVideoV2Author } from './userVideoV2Author';
import { UserVideoV2Cover } from './userVideoV2Cover';
import { UserVideoV2Views } from './userVideoV2Views';


/**
 * @type FeedItemObject
 * @export
 */
export type FeedItemObject = Media | SystemFrameAppRatingV1 | SystemFrameRegionV1 | SystemVideoV1 | UserVideoV2;

