/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FeedVideo } from './feedVideo';
import { SystemVideoV1Button } from './systemVideoV1Button';
import { SystemVideoV1Profile } from './systemVideoV1Profile';


export interface SystemVideoV1 { 
    id?: string;
    video?: FeedVideo;
    button?: SystemVideoV1Button;
    text?: string;
    age_restriction?: string;
    profile?: SystemVideoV1Profile;
    views_key?: string;
    firebase_key?: string;
}

