/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 */
export type PhoneAuthType = 'sms' | 'flash_call';

export const PhoneAuthType = {
    Sms: 'sms' as PhoneAuthType,
    FlashCall: 'flash_call' as PhoneAuthType
};

