/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TokensViaEmail { 
    email: string;
    hash?: string;
    refresh_token: string;
    access_token: string;
    user_id?: string;
    required_2fa?: boolean;
    is_multiapp?: boolean;
    is_new?: boolean;
}

