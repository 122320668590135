import { CdkScrollable } from '@angular/cdk/overlay';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, provideClientHydration, withNoHttpTransferCache } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';

import * as Sentry from '@sentry/angular';

import { I18NextModule } from 'angular-i18next';

import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { CoreModule } from '@core/core.module';
import { AvatarComponent, ButtonComponent } from '@shared/components';
import { TabBarComponent } from '@shared/components/tab-bar/tab-bar.component';
import { OpenGetAppDirective } from '@shared/directives';

@NgModule({
  declarations: [ AppComponent ],
  bootstrap: [ AppComponent ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    CdkScrollable,
    ReactiveFormsModule,
    I18NextModule,
    // IconComponent,
    ButtonComponent,
    OpenGetAppDirective,
    AvatarComponent,
    TabBarComponent,
  ],
  providers: [
    provideClientHydration(withNoHttpTransferCache()),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    { provide: Sentry.TraceService, deps: [ Router ] },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [ Sentry.TraceService ],
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
}
