import { createReducer, on } from '@ngrx/store';

import { Media, Snippet } from '@api/backend';

import { mediaActions } from './media.actions';
import {
  MediaFeedState,
  initialMediaFeedState,
  mediaFeedAdapter,
  MediaViewState,
  initialMediaViewState, mediaViewsAdapter,
} from '@store/media/entity-adapters';

export const mediaFeatureKey = 'media';

export interface MediaState {
  feed: MediaFeedState;
  mediaViews: MediaViewState;
  media?: Media;
  mediaIsLoading: boolean;
  snippet?: Snippet;
  isPlaying: boolean;
  isMuted: boolean;
  isModalView: boolean;
  currentMediaIndex: number;
}

export const initialState: MediaState = {
  feed: initialMediaFeedState,
  mediaViews: initialMediaViewState,
  currentMediaIndex: 0,
  mediaIsLoading: false,
  isPlaying: true,
  isMuted: true,
  isModalView: false,
};

export const mediaReducer = createReducer(
  initialState,
  on(
    mediaActions.loadMedia,
    (state): MediaState => ({
      ...state,
      mediaIsLoading: true,
    }),
  ),
  on(
    mediaActions.loadMediaSuccess,
    (state, { media }): MediaState => ({
      ...state,
      media,
      mediaIsLoading: false,
    }),
  ),
  on(
    mediaActions.loadMediaFailure,
    (state): MediaState => ({
      ...state,
      mediaIsLoading: false,
    }),
  ),
  on(mediaActions.clearMedia, (state): MediaState => ({ ...state, media: undefined })),
  on(
    mediaActions.loadFeedMedia,
    (state, { isNextPart }): MediaState => ({
      ...state,
      feed: {
        ...state.feed,
        nextPageToken: isNextPart ? state.feed.nextPageToken : undefined,
      },
    }),
  ),
  on(
    mediaActions.loadFeedMediaInit,
    (state): MediaState => ({
      ...state,
      feed: {
        ...state.feed,
        isLoading: true,
      },
    }),
  ),
  on(
    mediaActions.loadFeedMediaSuccess,
    (state, { page, isNextPart }): MediaState => ({
      ...state,
      feed: isNextPart
        ? mediaFeedAdapter.addMany(page.media, {
          ...state.feed,
          nextPageToken: page.next_page_token as string,
          // recommendation_id: page.recommendation_id,
          isLoading: false,
        })
        : mediaFeedAdapter.setAll(page.media, {
          ...state.feed,
          nextPageToken: page.next_page_token as string,
          // recommendation_id: page.recommendation_id,
          isLoading: false,
        }),
    }),
  ),
  on(
    mediaActions.loadFeedMediaFailure,
    (state): MediaState => ({
      ...state,
      feed: {
        ...state.feed,
        isLoading: false,
      },
    }),
  ),
  on(
    mediaActions.loadFeedMediaComplete,
    (state): MediaState => ({
      ...state,
      feed: {
        ...state.feed,
        isLoading: false,
      },
    }),
  ),
  on(
    mediaActions.clearFeed,
    (state): MediaState => ({
      ...state,
      feed: mediaFeedAdapter.getInitialState({
        recommendation_id: undefined,
        nextPageToken: undefined,
        isLoading: false,
      }),
    }),
  ),
  on(
    mediaActions.playMedia,
    (state): MediaState => ({
      ...state,
      isPlaying: true,
    }),
  ),
  on(
    mediaActions.pauseMedia,
    (state): MediaState => ({
      ...state,
      isPlaying: false,
    }),
  ),
  on(
    mediaActions.muteMedia,
    (state, { isMuted }): MediaState => ({
      ...state,
      isMuted,
    }),
  ),
  on(
    mediaActions.closeMedia,
    (state): MediaState => ({
      ...state,
      currentMediaIndex: -1,
    }),
  ),
  on(
    mediaActions.navigateMedia,
    (state): MediaState => ({
      ...state,
    }),
  ),
  on(
    mediaActions.setCurrentMediaIndex,
    (state, { media_id }): MediaState => ({
      ...state,
      currentMediaIndex: state.feed.ids.findIndex((id) => id === media_id) ?? 0,
    }),
  ),
  on(
    mediaActions.resetCurrentMediaIndex,
    (state): MediaState => ({ ...state, currentMediaIndex: 0 }),
  ),
  on(
    mediaActions.shareMediaSuccess,
    (state, { media_id, isSingle }): MediaState => {
      if (!isSingle) {
        const media = state.feed.entities[media_id] as Media;
        const counters = media.counters;

        return {
          ...state,
          feed: mediaFeedAdapter.updateOne(
            {
              id: media_id,
              changes: {
                counters: {
                  ...counters,
                  reposts: counters.reposts + 1,
                },
              },
            },
            state.feed,
          ),
        };
      }

      return {
        ...state,
        media: !state.media
          ? state.media
          : {
            ...state.media,
            counters: {
              ...state.media.counters,
              reposts: state.media.counters.reposts + 1,
            },
          },
      };
    }),
  on(
    mediaActions.updateMediaViewStorage,
    (state, { data }): MediaState => ({
      ...state,
      mediaViews: mediaViewsAdapter.addOne(data, state.mediaViews),
    }),
  ),
  on(
    mediaActions.pushMediaViewsSuccess,
    mediaActions.pushMediaViewsFailure,
    (state, { data }): MediaState => ({
      ...state,
      mediaViews: data ? mediaViewsAdapter.addOne(data, mediaViewsAdapter.getInitialState()) : state.mediaViews,
    }),
  ),
);
