import { Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, of, switchMap, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ErrorResponse, SessionTokenData } from '@core/models';
import { AuthRepository } from '@core/repositories';
import { FingerprintService } from '@core/services';
import { CookieService } from '@core/services/cookies/cookie.service';
import { DeviceDetectorService } from '@core/services/device-detector/device-detector.service';
import { TOKEN_NAME } from '@data/config';
import { environment } from '@environments/environment';
import { userActions } from '@store/user';

import { sessionActions } from './session.actions';

@Injectable({ providedIn: 'root' })
export class SessionEffects {
  public getAuthTokenSuccess$ = createEffect(() => {
    return this.actions.pipe(
      ofType(
        sessionActions.getAuthSessionSuccess,
        sessionActions.refreshAuthSessionSuccess,
        // authActions.verifyCodeSuccess,
        // authActions.verifyUser2faSuccess,
      ),
      tap(({ token }) => {
        if (!token.required_2fa) {
          this.cookieService.set(TOKEN_NAME, JSON.stringify(token));
        }
      }),
      map(({ token }) => sessionActions.getAuthTokenSuccess({ token })),
    );
  },
  );

  public getAuthSession$ = createEffect(() => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { browser, browser_version } = this.deviceDetectorService.getDeviceInfo();

    return this.actions.pipe(
      ofType(sessionActions.getAuthSession),
      switchMap(() => this.fingerprintService.getVisitorData()),
      switchMap(({ visitorId }) =>
        this.authRepository
          .postAuthSession({
            installation_token: visitorId,
            device: {
              platform: 'Web',
              platform_version: `${browser} V${browser_version}`,
            },
            application: {
              app_name: environment.app_name,
              app_version: environment.app_version,
              // app_build: environment.app_build,
              app_type: environment.app_type,
            },
          })
          .pipe(
            map((tokenData) => sessionActions.getAuthSessionSuccess({ token: tokenData.data })),
            catchError((error: unknown) => of(sessionActions.getAuthSessionFailure({ error: error as ErrorResponse }))),
          ),
      ),
    );
  });

  public refreshAuthSession$ = createEffect(() => {
    return this.actions.pipe(
      ofType(sessionActions.refreshAuthSession),
      switchMap(() =>
        this.authRepository.postAuthSessionToken().pipe(
          map((tokenData: SessionTokenData) => sessionActions.refreshAuthSessionSuccess({ token: tokenData.data })),
          catchError((error: unknown) =>
            of(sessionActions.refreshAuthSessionFailure({ error: error as ErrorResponse })),
          ),
        ),
      ),
    );
  },
  );

  public removeAuthSession$ = createEffect(() => {
    return this.actions.pipe(
      ofType(sessionActions.removeAuthSession),
      switchMap(() =>
        this.authRepository.deleteAuthSession().pipe(
          tap(() => {
            this.cookieService.clear(TOKEN_NAME);
            void this.router.navigate(['/']);
          }),
          map(() => sessionActions.removeAuthSessionSuccess()),
          catchError((error: unknown) =>
            of(sessionActions.removeAuthSessionFailure({ error: error as ErrorResponse })),
          ),
        ),
      ),
    );
  },
  );

  public removeAuthSessionSuccess$ = createEffect(() => {
    return this.actions.pipe(
      ofType(sessionActions.removeAuthSessionSuccess),
      map(() => userActions.clearUser()),
    );
  },
  );

  constructor(
    @Optional()
    private readonly actions: Actions,
    private readonly router: Router,
    private readonly authRepository: AuthRepository,
    private readonly cookieService: CookieService,
    private readonly deviceDetectorService: DeviceDetectorService,
    private readonly fingerprintService: FingerprintService,
  ) {}
}
