import { NgClass, NgIf } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { I18NextModule } from 'angular-i18next';

import { I18NextString, InfoBlockVariant } from '@core/models';
import { IconComponent } from '@shared/components';
import { RippleDirective } from '@shared/directives';

const ICONS_IDS: { [key in InfoBlockVariant]: string } = {
  info: 'info-circle-icon',
  success: 'tick-circle-icon',
  error: 'exclamation-bubble-icon',
};

@Component({
  selector: 'cheelee-info-block',
  standalone: true,
  imports: [NgIf, I18NextModule, NgClass, IconComponent, RippleDirective],
  templateUrl: './info-block.component.html',
  styleUrls: ['./info-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoBlockComponent implements OnInit {
  @Input()
  public variant: InfoBlockVariant = 'success';

  @Input({ required: true })
  public message!: I18NextString;

  @Input()
  public action?: () => void;

  @Input()
  public actionBtnText!: I18NextString;

  @Input({ transform: booleanAttribute })
  public isSnackbarMode = false;

  public iconId!: string;

  public ngOnInit(): void {
    this.iconId = ICONS_IDS[this.variant];
  }
}
