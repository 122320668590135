<cheelee-dialog-wrapper class="qr">
  <div
    class="qr__header"
    ngProjectAs="header"
  >
    <div class="qr__image-wrapper">
      <img
        class="qr__image"
        alt="Earn in Cheelee"
        ngSrc="./assets/img/qr-headx2.png"
        fill
      />
    </div>

    <div class="qr__close-btn">
      <button
        cheeleeRipple
        isCenteredRipple
        (click)="close()"
      >
        <cheelee-icon
          iconId="cross-icon"
          color="#AB9BFD"
        />
      </button>
    </div>
  </div>

  <div class="qr__content">
    <div class="qr__code">
      <img
        class="qr__code-img"
        alt="Earn in Cheelee"
        ngSrc="./assets/img/qr-code_x2.png"
        width="460"
        height="460"
      />
    </div>
    <p class="qr__download-text">{{ 'common:join the cheelee' | i18nextEager }}</p>
    <p class="qr__download-text">{{ 'common:watch video and earn' | i18nextEager }}</p>
  </div>
  <div class="qr__info">
    <img
      class="qr__code-img"
      alt="Earn in Cheelee"
      ngSrc="./assets/img/info.png"
      width="80"
      height="80"
    />
    <p>Отсканируй QR-код на своем смартфоне и скачивай приложение</p>
  </div>
</cheelee-dialog-wrapper>
