<div
  class="show-more"
  [class.expanded]="!canShowFullText()"
  [isDisabledRipple]="!canShowButton()"
  cheeleeRipple
  (click)="toggle()"
  (transitionend)="onHeightTransitionEnd()"
>
  <span
    #textContent
    class="show-more__text text-1"
    [class.full]="isFullText()"
    [innerHTML]="text | mentionLink"
    [style.max-width]="canShowFullText() ? 'calc(100% - 48px)' : '100%'"
    cheeleeMentionLink
  ></span>
  <button
    class="show-more__btn bold uppercase"
    *ngIf="canShowButton()"
    [class.user-mode]="mode === 'user'"
  >
    {{ (canShowFullText() ? 'common:more' : 'common:hide') | i18nextEager }}
  </button>
</div>
