@if (dummyBanner && !isMobile()) {
} @else {
  @if (!isMobile()) {
    <header>
      <div #headerContainer style="border: 1px solid red">
        <ng-content select="header"/>
      </div>
      @if (!headerContainer.children.length) {
        <cheelee-header
          [background]="'var(--color-lilac-135)'"
          [maxWidth]="'var(--feed-main-grid-width)'"
        >
          <a class="header__logo" routerLink="/">
            <img
              [ngSrc]="'assets/img/cheelee-logo-right-text.png'"
              alt="CHEELEE"
              priority
              fill
            />
          </a>
          <cheelee-button isSmall>Войти</cheelee-button>
        </cheelee-header>
      }
    </header>
  }
  <section class="main-grid container">
    @if (!isMobile()) {
      <div class="main-grid__sticky">
        <div #leftContainer style="outline: 1px solid red">
          <ng-content select="left"/>
        </div>
        @if (!leftContainer.children.length) {
          <!--default content-->
        }
      </div>
    }
    <div>
      <div #mainContainer class="main-grid__center relative">
        <ng-content/>
      </div>
      @if (!mainContainer.children.length) {
        <router-outlet/>
      }
    </div>

    @if (!isMobile()) {
      <div class="main-grid__sticky">
        <div #rightContainer style="outline: 1px solid red">
          <ng-content select="right"/>
        </div>
        @if (!rightContainer.children.length) {
          <!--default content-->
        }
      </div>
    }
  </section>
  @if (isMobile() && tabbar()) {
    <cheelee-tab-bar></cheelee-tab-bar>
  }
}
