import { APP_INITIALIZER, Provider } from '@angular/core';

import { BreakpointService } from '@core/services';

export const BREAKPOINTS_PROVIDER: Provider = {
  provide: APP_INITIALIZER,
  useFactory: (breakpointService: BreakpointService) => () => breakpointService.init(),
  deps: [BreakpointService],
  multi: true,
};
