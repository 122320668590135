import { cloneDeep as _cloneDeep, get as _get, pick as _pick, isEqual as _isEqual } from 'lodash';

export function cloneDeep<T>(value: T): T {
  return _cloneDeep(value);
}

export function getValue<T>(object: T, key: keyof T): T[keyof T] {
  return _get(object, key) as T[keyof T];
}

export function pick<T extends object, U extends keyof T>(object: T, props: Array<U>): Pick<T, U> {
  return _pick(object, props);
}

export function isEqual(value: any, other: any): boolean {
  return _isEqual(value, other);
}
