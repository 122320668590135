/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CommentAuthor } from './commentAuthor';
import { CommentTokensInner } from './commentTokensInner';


export interface Comment { 
    id: string;
    /**
     * When true - this means that current user was liked this comment before.
     */
    is_liked: boolean;
    text: string;
    author: CommentAuthor;
    /**
     * UNIX timestamp (in seconds, UTC)
     */
    created_at: number;
    /**
     * Approximate likes count
     */
    likes_approx: number;
    tokens: Array<CommentTokensInner>;
    /**
     * Approximate comment replies
     */
    replies_approx: number;
    is_edited: boolean;
    is_deleted: boolean;
}

