import { ChangeDetectorRef, NgZone, OnDestroy, Pipe, PipeTransform } from '@angular/core';

import { I18NextService } from 'angular-i18next';

@Pipe({
  standalone: true,
  name: 'timeAgo',
  pure: false,
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {
  private timer: number | null = null;

  constructor(
    private i18NextService: I18NextService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
  ) {}

  public transform(time: number): string {
    this.removeTimer();
    const diffSec = new Date().getTime() / 1000 - time; // seconds
    const timeToUpdate = Number.isNaN(diffSec) ? 1000 : this.getSecondsUntilUpdate(diffSec) * 1000;

    this.timer = this.ngZone.runOutsideAngular(() =>
      typeof window !== 'undefined'
        ? window.setTimeout(() => this.ngZone.run(() => this.cdr.markForCheck()), timeToUpdate)
        : null,
    );

    switch (true) {
      case diffSec < 60:
        return `${diffSec.toFixed(0)}${this.i18NextService.t('date:sec ago')}`;

      case diffSec < 3_600:
        return `${(diffSec / 60).toFixed(0)}${this.i18NextService.t('date:min ago')}`;

      case diffSec < 86_400:
        return `${(diffSec / 60 / 60).toFixed(0)}${this.i18NextService.t('date:hours ago')}`;

      case diffSec < 2_629_746:
        return `${(diffSec / 60 / 60 / 24).toFixed(0)}${this.i18NextService.t('date:days ago')}`;

      case diffSec < 31_556_952:
        return `${(diffSec / 60 / 60 / 24 / 31).toFixed(0)}${this.i18NextService.t('date:months ago')}`;

      default:
        return `${(diffSec / 60 / 60 / 24 / 31 / 12).toFixed(0)}${this.i18NextService.t('date:years ago')}`;
    }
  }

  public ngOnDestroy(): void {
    this.removeTimer();
  }

  private removeTimer(): void {
    if (this.timer) {
      window.clearTimeout(this.timer);
      this.timer = null;
    }
  }

  private getSecondsUntilUpdate(seconds: number): number {
    const minSec = 60;
    const hrSec = minSec * 60;
    const daySec = hrSec * 24;

    switch (true) {
      case seconds < minSec:
        return 2;

      case seconds < hrSec:
        return 30;

      case seconds < daySec:
        return 300;

      default:
        // update every hour
        return 3600;
    }
  }
}
