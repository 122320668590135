<div class="contacts">
  <a
    class="contacts__item"
    *ngFor="let contact of contacts"
    [href]="contact.link"
    target="_blank"
  >
    <div
      class="contacts__item-icon-background"
      [ngStyle]="{ background: contact.color }"
    >
      <cheelee-icon
        [path]="'social-sprite.svg#' + contact.icon"
        color="white"
        size="16"
      />
    </div>
    <span class="contacts__item-text text-1">{{ contact.text }}</span>
    <cheelee-icon
      class="contacts__right-icon"
      iconId="right-icon"
      size="20"
    />
  </a>
</div>
