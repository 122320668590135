import {
  DestroyRef,
  Directive,
  EventEmitter,
  HostListener,
  inject,
  Input,
  numberAttribute,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Subject, throttleTime } from 'rxjs';

@Directive({
  selector: '[cheeleeThrottleClick]',
  standalone: true,
})
export class ThrottleClickDirective implements OnInit {
  @Input({ transform: numberAttribute })
  public readonly throttleTime = 500;

  @Output()
  public readonly cheeleeThrottleClick = new EventEmitter<MouseEvent | TouchEvent>();

  private readonly clicksSubject = new Subject<MouseEvent | TouchEvent>();

  private readonly destroyRef = inject(DestroyRef);

  public ngOnInit(): void {
    this.clicksSubject
      .pipe(throttleTime(this.throttleTime), takeUntilDestroyed(this.destroyRef))
      .subscribe((event) => this.cheeleeThrottleClick.emit(event));
  }

  @HostListener('click', ['$event'])
  private clickEvent(event: MouseEvent | TouchEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.clicksSubject.next(event);
  }
}
