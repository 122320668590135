import { createActionGroup, props } from '@ngrx/store';

import { Breakpoints } from '@data/const';

export const layoutActions = createActionGroup({
  source: 'Layout',
  events: {
    'Set Breakpoint': props<{ breakpoint: Breakpoints }>(),
  },
});
