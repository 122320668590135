import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { CommonModule, DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  ElementRef,
  Inject,
  OnInit,
  Renderer2,
  signal,
  Type,
  ViewChild,
} from '@angular/core';

import { I18NextModule } from 'angular-i18next';
import { DeviceDetectorService } from 'ngx-device-detector';

import { CurtainData } from '@core/models';
import { OverlayService, PlatformService } from '@core/services';
import { ModalsNames } from '@data/const';
import { IconComponent } from '@shared/components';
import { RippleDirective } from '@shared/directives';

@Component({
  selector: 'cheelee-curtain',
  standalone: true,
  imports: [ CommonModule, CdkDrag, CdkDragHandle, I18NextModule, IconComponent, RippleDirective ],
  templateUrl: './curtain.component.html',
  styleUrls: [ './curtain.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurtainComponent implements OnInit, AfterViewInit {
  @ViewChild('curtain')
  private readonly curtainRef!: ElementRef<HTMLDivElement>;

  public curtainData = signal(this.overlayService.getModalData<CurtainData<Type<void>>>(ModalsNames.Curtain));

  public withHeightTransition = signal(false);

  public isNoTitle = computed(() => !this.curtainData().title && !this.curtainData().dynamicTitle);

  private modalRef = this.overlayService.getModalInstance(ModalsNames.Curtain);

  private overlay!: HTMLDivElement;

  private closeTriggerHeight = 0;

  private fullscreenTriggerHeight = 0;

  private initialHeight = 0;

  private isDragging = false;

  private startDragY = 0;

  private startHeight = 0;

  private isFullscreen = signal(false);

  private scrollableContent!: HTMLDivElement;

  constructor(
    private readonly overlayService: OverlayService,
    private readonly renderer: Renderer2,
    private readonly destroyRef: DestroyRef,
    private readonly deviceDetectoreService: DeviceDetectorService,
    private platformService: PlatformService,
    @Inject(DOCUMENT)
    private readonly document: Document,
  ) {
  }

  public ngOnInit(): void {
    if (!this.platformService.isBrowser()) {
      return;
    }

    const content = this.document.querySelector('main .container .content') as HTMLDivElement;

    if (content) {
      this.scrollableContent = content;
      this.renderer.setStyle(this.scrollableContent, 'overflow', 'hidden');
    }
  }

  public ngAfterViewInit(): void {
    this.setupDragCurtain();
  }

  public onDragStart(event: MouseEvent | TouchEvent): void {
    this.setClosableBackdrop(false);
    this.isDragging = true;
    this.startDragY = this.getPageYCoordinate(event);
    this.startHeight = this.getCurtainHeightInDvh();
  }

  public onDragMove(event: MouseEvent | TouchEvent): void {
    if (!this.isDragging) {
      return;
    }

    const delta = this.startDragY - this.getPageYCoordinate(event);
    const newHeight = this.startHeight + (delta / window.innerHeight) * 100;

    if (this.curtainData().isStretchDisabled && newHeight > this.initialHeight) {
      return;
    } else {
      this.updateCurtainHeight(newHeight);
    }
  }

  public onDragEnd(): void {
    this.stopDragging();
    const { height } = this.curtainRef.nativeElement.style;
    const heightValue = height ? +height.replace('dvh', '') : this.getCurtainHeightInDvh();

    if (this.isFullscreen() && heightValue <= 80 && heightValue > this.closeTriggerHeight) {
      this.restoreDefaultPosition();
    } else if (heightValue <= this.closeTriggerHeight) {
      this.closeCurtain();
    } else if (heightValue >= this.fullscreenTriggerHeight) {
      this.setFullscreen();
    } else {
      this.restoreDefaultPosition();
    }
  }

  public onTransitionEnd(): void {
    this.withHeightTransition.set(false);
  }

  public setClosableBackdrop(isClosable = true): void {
    if (this.modalRef) {
      this.modalRef.dismissable = isClosable;
    }
  }

  private setupDragCurtain(): void {
    this.overlay = (this.modalRef?.elementRef.nativeElement as HTMLElement).querySelector('.overlay') as HTMLDivElement;
    const unlistenFn = this.renderer.listen(
      this.overlay,
      this.deviceDetectoreService.isDesktop() ? 'mouseup' : 'touchend',
      () => this.stopDragging(),
    );

    const { initHeight, isStretchDisabled } = this.curtainData();
    this.initialHeight = initHeight ?? this.getCurtainHeightInDvh();

    if (initHeight) {
      this.updateCurtainHeight(initHeight);
    }

    if (isStretchDisabled) {
      this.setCurtainMaxHeight();
    }

    if (this.initialHeight >= 100) {
      this.initialHeight = 60;
      this.closeTriggerHeight = 40;
      this.fullscreenTriggerHeight = 80;
      this.isFullscreen.set(true);
    } else {
      this.closeTriggerHeight = this.initialHeight <= 50 ? this.initialHeight / 2 : this.initialHeight - 20;
      this.fullscreenTriggerHeight =
        this.initialHeight <= 50 ? this.initialHeight + this.initialHeight / 2 : this.initialHeight + 20;

      if (this.fullscreenTriggerHeight > 100) {
        this.fullscreenTriggerHeight = 100;
      }
    }

    this.destroyRef.onDestroy(() => {
      unlistenFn();
      this.renderer.setStyle(this.scrollableContent, 'overflow', 'auto');
    });
  }

  private updateCurtainHeight(newHeight: number): void {
    this.renderer.setStyle(this.curtainRef.nativeElement, 'height', `${ newHeight }dvh`);
  }

  private setCurtainMaxHeight(): void {
    this.renderer.setStyle(
      this.curtainRef.nativeElement,
      'max-height',
      `${ this.curtainRef.nativeElement.offsetHeight }px`,
    );
  }

  private restoreDefaultPosition(): void {
    this.isFullscreen.set(false);
    this.withHeightTransition.set(true);
    this.updateCurtainHeight(this.initialHeight);
  }

  private closeCurtain(): void {
    this.overlayService.close(ModalsNames.Curtain);
  }

  private setFullscreen(): void {
    this.withHeightTransition.set(true);
    this.isFullscreen.set(true);
    this.updateCurtainHeight(100);
  }

  private getCurtainHeightInDvh(): number {
    return Math.round(this.curtainRef.nativeElement.offsetHeight * (100 / this.document.documentElement.clientHeight));
  }

  private getPageYCoordinate(event: MouseEvent | TouchEvent): number {
    return (event as MouseEvent).pageY ?? (event as TouchEvent).touches[0].pageY;
  }

  private stopDragging(): void {
    this.isDragging = false;
    setTimeout(() => this.setClosableBackdrop());
  }
}
