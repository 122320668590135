import { createFeature, createReducer, on } from '@ngrx/store';

import { layoutActions } from './layout.actions';

export interface LayoutState {
  breakpoint: string | undefined;
}

export const initialLayoutState: LayoutState = {
  breakpoint: undefined,
};

export const layoutFeature = createFeature({
  name: 'layout',
  reducer: createReducer(
    initialLayoutState,
    on(
      layoutActions.setBreakpoint,
      (state, { breakpoint }): LayoutState => ({ ...state, breakpoint })),
  ),
});
