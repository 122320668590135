import { inject, Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import {
  DefaultService,
  EmptyResponse, MediaEndpointsService,
  MediaPageResponse,
  MediaResponse,
  MediaService,
  SnippetResponse,
  UserFeedType, ViewReq, ViewResp,
} from '@api/backend';
import { MediaRepository } from '@core/repositories/media.repository';
import { Media } from '@core/models';

@Injectable()
export class MediaWebRepository extends MediaRepository {

  private mediaService = inject(MediaService);

  private mediaEndpointsService = inject(MediaEndpointsService);

  private defaultService = inject(DefaultService);

  public getRecommendedMedia(page_token?: string, country_code?: string): Observable<MediaPageResponse> {
    return this.mediaEndpointsService.v3FeedGetFeedV1Post({
      page_token: page_token ?? null,
      similarity_group: null,
    }, country_code).pipe(
      map(resp => ({
        data: {
          media: (resp.data.items ?? [])
            .filter(item => item.object && ('media_id' in item.object))
            .map(item => ({ ...item.object } as Media)),
          next_page_token: resp.data.next_page_token,
        },
      })),
    );
  }

  public getMediaById(media_id: string): Observable<MediaResponse> {
    return this.mediaService.getMediaMediaMediaIdGet(media_id);
  }

  public getUserMedia(
    user_id: string,
    feed_type: UserFeedType,
    page_token?: string | undefined,
  ): Observable<MediaPageResponse> {
    return this.mediaService.getUserMediaMediaUsersUserIdFeedTypeGet(user_id, feed_type, 20, page_token);
  }

  public getMediaSnippet(media_id: string): Observable<SnippetResponse> {
    return this.mediaService.getMediaSnippetMediaMediaIdSnippetGet(media_id);
  }

  public shareAsync(media_id: string): Observable<EmptyResponse> {
    return this.mediaService.v2MediaMediaIdShareAsyncPost(media_id);
  }

  public postMediaView(body: ViewReq): Observable<ViewResp> {
    return this.defaultService.v2MediaViewPost(body);
  }

}
