import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[cheeleeSingleDoubleClick]',
  standalone: true,
})
export class SingleDoubleClickDirective {
  @Output()
  public singleClick = new EventEmitter<void>();

  @Output()
  public doubleClick = new EventEmitter<void>();

  private timer: number | null = null;

  @HostListener('click')
  private onClick(): void {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
      this.doubleClick.emit();
    } else {
      this.timer = window.setTimeout(() => {
        this.singleClick.emit();
        this.timer = null;
      }, 250);
    }
  }
}
