import { NgModule, Optional, Self } from '@angular/core';
import { Router } from '@angular/router';

import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';

import { RouterEffects } from '@store/router/router.effects';

import { MergedRouterStateSerializer } from './merged-route-serialzer';

export const routerStateConfig = {
  stateKey: 'router', // state-slice name for routing state
  serializer: MergedRouterStateSerializer,
};

@NgModule({
  imports: [
    StoreModule.forFeature(routerStateConfig.stateKey, routerReducer),
    StoreRouterConnectingModule.forRoot(routerStateConfig),
    EffectsModule.forFeature([RouterEffects]),
  ],
  exports: [StoreModule, StoreRouterConnectingModule],
})
export class NgrxRouterStoreModule {
  constructor(@Self() @Optional() router: Router) {
    if (!router) {
      // eslint-disable-next-line no-console
      console.error('NgrxRouterStoreModule must be imported in the same same level as RouterModule');
    }
  }
}
