import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { mediaActions, mediaSelectors } from '@store/media';
import { SharedData } from '@core/models';
import { MediaViewModel } from '@core/services';

@Injectable({ providedIn: 'root' })
export class FeedFacade {

  public readonly feed$ = this.store.select(mediaSelectors.selectFeed);

  public readonly feed = this.store.selectSignal(mediaSelectors.selectFeed);

  public readonly feedIsLoading = this.store.selectSignal(mediaSelectors.selectFeedIsLoading);

  public readonly nextPageToken = this.store.selectSignal(mediaSelectors.selectFeedNextPageToken);

  public readonly isMediaPlaying$ = this.store.select(mediaSelectors.selectIsPlaying);

  constructor(
    private store: Store,
  ) {
  }

  public loadFeedMedia(isNextPart?: boolean): void {
    this.store.dispatch(mediaActions.loadFeedMedia({ isNextPart }));
  }

  public clear(needToReloadFeedMedia?: boolean): void {
    this.store.dispatch(mediaActions.clearFeed(needToReloadFeedMedia));
  }

  public getNextFeedMedia(): void {
    this.store.dispatch(mediaActions.loadFeedMedia({ isNextPart: true }));
  }

  public playMedia(): void {
    this.store.dispatch(mediaActions.playMedia());
  }

  public shareMedia(media_id: string): void {
    this.store.dispatch(mediaActions.shareMedia({ media_id }));
  }

  public addMediaViewInfo(data: MediaViewModel, force?: boolean): void {
    this.store.dispatch(mediaActions.addMediaViewData({ data, force }));
  }

  public updateShareCounter(sharedItemId: string, itemType: SharedData['type']): void {
    switch (itemType) {
      case 'media':
        this.shareMedia(sharedItemId);
        break;

      default:
        break;
    }
  }

}
