/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 */
export type ViewSource = 'unknown' | 'recommendations' | 'followings' | 'user_profile' | 'media_search' | 'challenge' | 'direct_link' | 'discovery' | 'push';

export const ViewSource = {
    Unknown: 'unknown' as ViewSource,
    Recommendations: 'recommendations' as ViewSource,
    Followings: 'followings' as ViewSource,
    UserProfile: 'user_profile' as ViewSource,
    MediaSearch: 'media_search' as ViewSource,
    Challenge: 'challenge' as ViewSource,
    DirectLink: 'direct_link' as ViewSource,
    Discovery: 'discovery' as ViewSource,
    Push: 'push' as ViewSource
};

