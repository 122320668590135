import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SessionEffects } from './session.effects';
import { sessionFeatureKey, sessionReducer } from './session.reducer';

@NgModule({
  imports: [StoreModule.forFeature(sessionFeatureKey, sessionReducer), EffectsModule.forFeature([SessionEffects])],
  exports: [StoreModule],
})
export class SessionFeatureModule {}
