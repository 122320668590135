import { NavigationExtras } from '@angular/router';

export type RouterNavigateConfig = {
  path: string | string[];
  extras?: NavigationExtras;
};

export enum RouterPathParams {
  UserId = 'userId',
}
