import { inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Pipe({
  standalone: true,
  name: 'mentionLink',
})
export class MentionLinkPipe implements PipeTransform {
  private readonly sanitizer = inject(DomSanitizer);

  public transform(text: string): SafeHtml {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    // Detect links
    text = text.replace(urlRegex, (url) => `<a href="${url}" target="_blank">${url}</a>`);

    // Detect hashtags #
    const words = text.split(/\s+/);
    const mentions = words.filter((el) => el[0] === '#');

    words.forEach((word, index) => {
      mentions.forEach((mention) => {
        if (word === mention || word === mention + '.') {
          words.splice(index, 1, `<a style="color: #ababab">${word}</a>`);
        }
      });
    });

    return this.sanitizer.bypassSecurityTrustHtml(words.join(' '));
  }
}
