import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  Input,
  signal,
  ViewChild,
} from '@angular/core';
import { RouterLink } from '@angular/router';

import { RouterFacade } from '@core/facades';
import { isTextEllipsisActive } from '@core/helpers';
import { Media } from '@core/models';
import { IconComponent } from '@shared/components';
import { ShowMoreTextComponent } from '@shared/components/show-more-text/show-more-text.component';
import { RepeatAnimationDirective } from '@shared/directives';
import { ShortNumberPipe } from '@shared/pipes';

@Component({
  selector: 'cheelee-about-media',
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    ShortNumberPipe,
    NgOptimizedImage,
    ShowMoreTextComponent,
    RouterLink,
    RepeatAnimationDirective,
  ],
  templateUrl: './about-media.component.html',
  styleUrls: ['./about-media.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutMediaComponent implements AfterViewInit {
  @Input({ required: true })
  public mediaItem!: Media;

  @ViewChild('audioName')
  private readonly audioName!: ElementRef<HTMLSpanElement>;

  public canPlayRunningText = signal(false);

  public audioTrackName = computed(() =>
    this.mediaItem.audio ? `${this.mediaItem.audio.artist} - ${this.mediaItem.audio.title}` : '',
  );

  constructor(private routerFacade: RouterFacade) {}

  public ngAfterViewInit(): void {
    if (this.audioName) {
      this.canPlayRunningText.set(isTextEllipsisActive(this.audioName.nativeElement));
    }
  }

  public goToUserPage(): void {
    this.routerFacade.navigate({ path: `/${this.mediaItem.author.user_id}` });
  }
}
