/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GenderType } from './genderType';


export interface RequestUserInfo { 
    user_name?: string;
    person_name?: string;
    user_bio?: string;
    gender?: GenderType;
    birthday_date?: number;
    jurisdiction?: string;
    instagram?: string;
    youtube?: string;
    tiktok?: string;
}

