import { createReducer, on } from '@ngrx/store';

import { getCookie } from '@core/helpers/cookie.helper';
import { SessionToken } from '@core/models';
import { TOKEN_NAME } from '@data/config';

import { sessionActions } from './session.actions';

export const sessionFeatureKey = 'session';

function getToken(): SessionToken | undefined {
  let token: SessionToken | undefined;
  const TOKEN_COOKIE = getCookie(TOKEN_NAME);

  if (TOKEN_COOKIE) {
    try {
      token = JSON.parse(TOKEN_COOKIE) as SessionToken | undefined;
    } catch {
      token = undefined;
    }
  }

  return token;
}

export interface SessionState {
  user_id?: string;
  access_token?: string;
  refresh_token?: string;
}

export const initialState: SessionState = {
  user_id: getToken()?.user_id,
  access_token: getToken()?.access_token,
  refresh_token: getToken()?.refresh_token,
};

export const sessionReducer = createReducer(
  initialState,
  on(
    sessionActions.getAuthTokenSuccess,
    (state, { token }): SessionState => ({
      ...state,
      refresh_token: token.refresh_token,
      access_token: token.access_token,
      user_id: !token.required_2fa ? token.user_id : undefined,
    }),
  ),
  on(
    sessionActions.removeAuthSessionSuccess,
    (state): SessionState => ({
      ...state,
      user_id: undefined,
      access_token: undefined,
      refresh_token: undefined,
    }),
  ),
);
