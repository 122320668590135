import {
  Email,
  EmailResponse,
  EmailVerification,
  NewSessionRequest,
  OAuthTokens,
  OAuthTokensResponse,
  PhoneAuthRequest,
  PhoneNumberResponse,
  PhoneNumberVerification,
  TokensViaEmail,
  TokensViaEmailResponse,
  TokensViaPhoneNumber,
  TokensViaPhoneNumberResponse,
  UpdateDeviceInfo,
} from '@api/backend';

export type SessionToken = TokensViaPhoneNumber | TokensViaEmail | OAuthTokens;
export type SessionTokenData = TokensViaPhoneNumberResponse | TokensViaEmailResponse | OAuthTokensResponse;
export type SessionBodyRequest = NewSessionRequest;
export type SessionUpdateBodyRequest = UpdateDeviceInfo;
export type SessionPhoneBody = PhoneAuthRequest;
export type SessionEmailBody = Email;
export type SessionPhoneVerifyBody = PhoneNumberVerification;
export type SessionEmailVerifyBody = EmailVerification;
export type SessionPhoneData = PhoneNumberResponse;
export type SessionEmailData = EmailResponse;
