<nav class="tab-bar">
  <div
    class="tab-bar__item"
    *ngFor="let item of tabBarItems()"
  >
    <a
      style="display: none"
      routerLinkActive="jopa"
      #sl="routerLinkActive"
      [routerLink]="item.secondOrderLink()"
    ></a>
    <a
      #fl="routerLinkActive"
      [class.active]="sl.isActive || fl.isActive"
      class="tab-bar__item-link"
      [routerLink]="item.link()"
      routerLinkActive
      [routerLinkActiveOptions]="{ exact: true }"
      throttleTime="1000"
      (cheeleeThrottleClick)="onItemClick(item, $event)"
      [analytics]="item.eventName"
    >
      <span
        class="tab-bar__item-wrapper"
        [isDisabledRipple]="(sl.isActive || fl.isActive) && item.link() !== ''"
        cheeleeRipple
        isCenteredRipple
        isSmallRipple
      >
        <cheelee-icon
          class="tab-bar__item-icon"
          [class.hidden]="sl.isActive || fl.isActive"
          [path]="item.icon.defaultName"
          size="28"
        />
        <cheelee-icon
          class="tab-bar__item-icon"
          [class.hidden]="!sl.isActive && !fl.isActive"
          [path]="item.icon.activeName"
          size="28"
        />
        <label class="tab-bar__item-label">{{ item.label | i18nextEager }}</label>
      </span>
    </a>
  </div>
</nav>
