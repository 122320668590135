<div class="status">
  <div
    class="status__icon status__icon_{{ icon }}"
    *ngIf="icon"
  ></div>
  <span class="status__title-text bold uppercase">{{ titleText | i18nextEager }}</span>
  <p
    class="status__subtitle-text"
    *ngIf="subtitleText"
  >
    {{ subtitleText | i18nextEager }}
  </p>
  <cheelee-button
    class="status__action-btn"
    *ngIf="actionBtnText"
    isSmall
    variant="outlined"
    (clicked)="action.emit()"
  >
    {{ actionBtnText | i18nextEager }}
  </cheelee-button>
</div>
