import { Injectable } from '@angular/core';

import { DeviceDetectorService as ExternalService, DeviceInfo } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root',
})
export class DeviceDetectorService {
  constructor(private deviceDetectorService: ExternalService) {}

  public getDeviceInfo(): DeviceInfo {
    return this.deviceDetectorService.getDeviceInfo();
  }
}
