<div
  #curtain
  class="curtain"
  *ngIf="curtainData() as data"
  [class.transition-height]="withHeightTransition()"
  (transitionend)="onTransitionEnd()"
>
  <header
    class="curtain__header"
    [class.no-title]="isNoTitle()"
    (mousedown)="onDragStart($event)"
    (mousemove)="onDragMove($event)"
    (mouseup)="onDragEnd()"
    (touchend)="onDragEnd()"
    (touchmove)="onDragMove($event)"
    (touchstart)="onDragStart($event)"
  >
    <span
      class="curtain__header-title uppercase bold"
      *ngIf="!data.dynamicTitle && data.title as title"
    >
      {{ title | i18nextEager }}
    </span>
    <span
      class="curtain__header-title uppercase bold"
      *ngIf="!data.title && data.dynamicTitle as title"
    >
      {{ title() }}
    </span>
  </header>
  <div
    class="curtain__content"
    [style.height]="isNoTitle() ? '100%' : 'calc(100% - 68px)'"
  >
    <ng-container *ngComponentOutlet="data.component; inputs: data.componentInputs ?? {}" />
  </div>
</div>
