import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  signal,
  ViewChild,
} from '@angular/core';

import { RouterFacade } from '@core/facades';
import { RouterPathParams, User, ViewSources } from '@core/models';
import { ComponentHelperService, OverlayService } from '@core/services';
import { IconComponent } from '@shared/components';
import { SpinnerComponent } from '@shared/components/spinner/spinner.component';

const EMPTY_USER_AVATAR_PATH = './assets/img/avatar-empty.svg';
const DELETED_USER_AVATAR_PATH = './assets/img/avatar-deleted.svg';

type AvatarSize = 's' | 'm' | 'l' | 'xl' | 'xxl';

export type AvatarUserData = Pick<User, 'user_avatar_url' | 'is_deleted' | 'user_id' | 'is_identity_confirmed'>;

@Component({
  selector: 'cheelee-avatar',
  standalone: true,
  imports: [ CommonModule, NgOptimizedImage, SpinnerComponent, IconComponent ],
  templateUrl: './avatar.component.html',
  styleUrls: [ './avatar.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent implements AfterViewInit {
  @Input({ transform: booleanAttribute })
  public withBorder = false;

  @Input()
  public size: AvatarSize = 'm';

  @Input({ transform: booleanAttribute })
  public withConfirmedIcon = false;

  @Output()
  public badgeClick = new EventEmitter<void>();

  @ViewChild('img', { static: true })
  private imgRef!: ElementRef<HTMLImageElement>;

  @Input()
  public set withBadge(canShow: boolean) {
    this.canShowBadge.set(canShow);
  }

  @Input()
  public set user(userData: AvatarUserData) {
    if (userData) {
      this.userData.set(userData);

      this.imgSrc.set(
        userData.is_deleted ? DELETED_USER_AVATAR_PATH : userData.user_avatar_url || EMPTY_USER_AVATAR_PATH,
      );
    }
  }

  public imgSrc = signal(EMPTY_USER_AVATAR_PATH);

  public isPending = signal(true);

  public canShowBadge = signal(false);

  public userData = signal<AvatarUserData | null>(null);

  constructor(
    private readonly routerFacade: RouterFacade,
    private readonly overlayService: OverlayService,
    private readonly componentHelper: ComponentHelperService,
  ) {
  }

  public ngAfterViewInit(): void {
    this.imgRef.nativeElement.onload = () => this.isPending.set(false);

    this.imgRef.nativeElement.onerror = () => {
      this.imgRef.nativeElement.src = EMPTY_USER_AVATAR_PATH;
    };
  }

  public navigateToUser(): void {
    const userData = this.userData();
    const routerState = this.routerFacade.routerState();
    const navigateUrl = `/users/${ userData?.user_id }`;

    if (routerState.data['type'] === ViewSources.UserProfile && routerState.params[RouterPathParams.UserId]) {
      this.overlayService.closeAll();
      this.componentHelper.reloadComponent(false, navigateUrl);
    } else {
      this.routerFacade.navigate({ path: navigateUrl });
    }
  }

  public onBadgeClick(event: MouseEvent | TouchEvent): void {
    event.stopPropagation();

    if (this.isPending()) {
      return;
    }

    this.canShowBadge.set(false);
    this.isPending.set(true);
    this.badgeClick.emit();
  }
}
