import { ActionReducer, MetaReducer } from '@ngrx/store';

import { environment } from '@environments/environment';
import { MediaFeatureModule } from '@store/media';
import { NgrxRouterStoreModule } from '@store/router';
import { SessionFeatureModule } from '@store/session';
import { UserFeatureModule } from '@store/user';
import { LayoutFeatureModule } from '@store/layout';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface State {}

export function debug(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    if (!environment.production) {
      // eslint-disable-next-line no-console
      console.log('state', state);
      // eslint-disable-next-line no-console
      console.log('action', action);
    }

    return reducer(state, action);
  };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const metaReducers: MetaReducer<State>[] = [
  // debug,
];

// eslint-disable-next-line @typescript-eslint/naming-convention
export const APP_STATE_FEATURE_MODULES = [
  SessionFeatureModule,
  MediaFeatureModule,
  UserFeatureModule,
  NgrxRouterStoreModule,
  LayoutFeatureModule,
  // SearchFeatureModule,
  // CommentsFeatureModule,
  // ComplaintsFeatureModule,
];
