<div
  class="earn-button"
  cheeleeRipple
  isCenteredRipple
  (click)="clicked.emit()"
  [analytics]="analyticsEvents.FeedEasyTap"
>
  <div class="earn-button__earning">
    <button #buttonRef
      class="earn-button__btn"
      cheeleeRipple
      [style.background-image]="'url(assets/img/' + icon()"
    ></button>

    <span class="earn-button__details bold">
      <ng-content></ng-content>
    </span>
  </div>
</div>
@if (earn()) {
  <div [@flyAndFade]="coin1()" class="coin"></div>
  <div [@flyAndFade]="coin2()" class="coin"></div>
}
