import { Injectable, Type, ViewContainerRef } from '@angular/core';

import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';
import { take } from 'rxjs';
import { filter } from 'rxjs/operators';

import { BreakpointFacade } from '@core/facades';
import { DIALOG_ANIMATION_CLASSES, ModalsNames } from '@data/const';
import {
  GetMobileAppDialogComponent,
} from '@shared/components/dialogs/get-mobile-app-dialog/get-mobile-app-dialog.component';
import { BannerPictureType, CurtainData } from '@core/models';
import { CurtainComponent } from '@shared/components';
import { QrDialogComponent } from '@shared/components/dialogs/qr-dialog/qr-dialog.component';

@Injectable({ providedIn: 'root' })
export class OverlayService {
  private viewContainerRef!: ViewContainerRef;

  constructor(
    private readonly ngxSmartModalService: NgxSmartModalService,
    private readonly breakpointFacade: BreakpointFacade,
  ) {
    breakpointFacade.isDesktop$
      .pipe(
        filter(Boolean),
      )
      .subscribe(() => {
        this.close(ModalsNames.Curtain);
      });
  }

  public openDialog<T = null>(
    dialogName: ModalsNames,
    component: Type<any>,
    data?: Partial<T>,
    customClasses?: string,
  ): NgxSmartModalComponent {
    const animationClass = customClasses ?? this.getAnimationClass();
    let dialog: NgxSmartModalComponent;

    try {
      dialog = this.ngxSmartModalService.get(dialogName);
      dialog.removeCustomClass(Object.values(DIALOG_ANIMATION_CLASSES).join(' '));
      dialog.addCustomClass(animationClass);
    } catch (e) {
      dialog = this.ngxSmartModalService.create(dialogName, component, this.viewContainerRef, {
        closable: false,
        customClass: 'cheelee-dialog ' + animationClass,
      });
    }

    if (data) {
      dialog.setData(data, true);
    }

    this.subscribeOnModalClose(dialog);

    return dialog.visible ? dialog : dialog.open();
  }

  public openGetAppDialog(pictureData?: BannerPictureType): void {
    this.openDialog(ModalsNames.GetApp, GetMobileAppDialogComponent, { pictureData }, 'fade-animation');
  }

  public openQrDialog(): void {
    this.openDialog(ModalsNames.Qr, QrDialogComponent, null, 'fade-animation');
  }

  public close(modalName: ModalsNames): void {
    try {
      if (this.getModalInstance(modalName)) {
        this.ngxSmartModalService.close(modalName);
      }
    } catch {}
  }

  public closeAll(): void {
    Object.values(ModalsNames).forEach((modalName) => this.close(modalName));
  }

  public getModalInstance(modalName: ModalsNames): NgxSmartModalComponent | null {
    let modalInstance!: NgxSmartModalComponent | null;

    try {
      modalInstance = this.ngxSmartModalService.get(modalName);
    } catch {}

    return modalInstance;
  }

  public getModalData<T = unknown>(modalName: ModalsNames): T {
    return this.ngxSmartModalService.getModalData(modalName) as T;
  }

  public removeModalInstance(modalName: ModalsNames): void {
    this.ngxSmartModalService.removeModal(modalName);
  }

  public setDialogContainer(viewContainerRef: ViewContainerRef): void {
    if (this.viewContainerRef) {
      return;
    }

    this.viewContainerRef = viewContainerRef;
  }

  public resetModalData(modalName: ModalsNames): void {
    try {
      const modalInstance = this.getModalInstance(modalName);

      if (modalInstance?.hasData()) {
        this.ngxSmartModalService.resetModalData(modalName);
      }
    } catch (e) {}
  }

  private getAnimationClass(): string {
    return ' ' + this.breakpointFacade.isMobile()
      ? DIALOG_ANIMATION_CLASSES.leftToRight
      : DIALOG_ANIMATION_CLASSES.fade;
  }

  private subscribeOnModalClose(modalInstance: NgxSmartModalComponent): void {
    modalInstance.onAnyCloseEventFinished.pipe(take(1)).subscribe(() => {
      const modalName = modalInstance.identifier as ModalsNames;
      this.resetModalData(modalName);
      this.removeModalInstance(modalName);
    });
  }

  public openCurtain<T>(data: CurtainData<T>): NgxSmartModalComponent {
    let curtain: NgxSmartModalComponent;

    try {
      curtain = this.ngxSmartModalService.get(ModalsNames.Curtain);
    } catch (e) {
      curtain = this.ngxSmartModalService.create(ModalsNames.Curtain, CurtainComponent, this.viewContainerRef, {
        closable: false,
        customClass: 'cheelee-curtain',
      });
    }

    curtain.setData(data, true);
    this.subscribeOnModalClose(curtain);
    return curtain.visible ? curtain : curtain.open(true);
  }
}
