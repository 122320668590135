import { booleanAttribute, ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { RouterLink, RouterOutlet } from '@angular/router';
import { BreakpointFacade } from '@core/facades';
import { ButtonComponent, HeaderComponent } from '@shared/components';
import { TabBarComponent } from '@shared/components/tab-bar/tab-bar.component';
import { OverlayService } from '@core/services';
import { I18NextModule } from 'angular-i18next';

@Component({
  selector: 'cheelee-responsive-layout',
  standalone: true,
  templateUrl: './responsive-layout.component.html',
  styleUrls: [ './responsive-layout.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterOutlet,
    HeaderComponent,
    NgOptimizedImage,
    RouterLink,
    ButtonComponent,
    TabBarComponent,
    I18NextModule,
  ],
})
export class ResponsiveLayoutComponent {

  public isMobile = inject(BreakpointFacade).isMobile;

  public tabbar = input(false, { transform: booleanAttribute });

  public dummyBanner = true;

  constructor(
    private overlayService: OverlayService,
  ) {
  }

  public openGetAppDialog(): void {
    this.overlayService.openQrDialog();
  }

}
