import { createFeatureSelector, createSelector } from '@ngrx/store';

import { userFeatureKey, UserState } from '@store/user';
import { mediaFeedAdapter } from '@store/media/entity-adapters';

const {
  selectAll: userMediaSelector,
  selectEntities: userMediaEntitiesSelector,
  selectIds: userMediaIdsSelector,
} = mediaFeedAdapter.getSelectors();

const selectUserFeature = createFeatureSelector<UserState>(userFeatureKey);

const selectSnippet = createSelector(selectUserFeature, (state) => state.snippet);

const selectUserData = createSelector(selectUserFeature, (state) => state.user);

const selectBrowsingUserData = createSelector(selectUserFeature, selectUserData, (state) => state.browsingUser);

const selectIsBrowsingUserAuth = createSelector(
  selectUserFeature,
  selectUserData,
  selectBrowsingUserData,
  (_, userData, browsingUserData) => userData?.user_id === browsingUserData?.user_id,
);

const selectIsLoading = createSelector(selectUserFeature, (state: UserState) => state.isLoading);

const selectUserMediaState = createSelector(selectUserFeature, (state) => state.userMedia);

const selectUserMedia = createSelector(selectUserMediaState, userMediaSelector);

const selectUserMediaIsLoading = createSelector(selectUserMediaState, (feed) => feed.isLoading);

const selectUserMediaEntities = createSelector(selectUserMediaState, userMediaEntitiesSelector);

const selectUserMediaIds = createSelector(selectUserMediaState, userMediaIdsSelector);

const selectUserMediaLength = createSelector(selectUserMediaIds, (ids) => ids.length);

const selectUserMediaNextPageToken = createSelector(selectUserMediaState, (feed) => feed.nextPageToken);

export const userSelectors = {
  selectSnippet,
  selectUserData,
  selectBrowsingUserData,

  selectUserMedia,
  selectUserMediaIsLoading,
  selectUserMediaEntities,
  selectUserMediaIds,
  selectUserMediaLength,
  selectUserMediaNextPageToken,


  // TODO remove
  selectIsBrowsingUserAuth,
  selectIsLoading,
};
