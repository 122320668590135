<section class="content__bio">
  <div class="content__counters">
    @for (counter of counters(); track counter.property) {
      <div
        class="content__counters-item"
        [style.cursor]="counter.action ? 'pointer' : 'default'"
        (click)="counter.action && counter.action()"
      >
      <span class="content__counters-number bold">
        {{ !user()?.is_deleted ? user()?.counters?.[counter.property] : 0 }}
      </span>
        <span class="content__counters-text text-1">
        {{ counter.name | i18nextEager }}
      </span>
      </div>
    }
  </div>

  @if (user()?.user_bio) {
    <div class="content__description">
      @if (showEllipsis) {
        <p
          #ell="ellipsis"
          [style.height.px]="42"
          [ellipsis]="'... ' + ('common:more' | i18nextEager)"
          [ellipsis-content]="user()?.user_bio ?? ''"
          (ellipsis-click-more)="showEllipsis = false"
        ></p>
      } @else {
        <p>
          {{ user()?.user_bio ?? '' }}
          <button
            type="button"
            style="display: inline-block"
            (click)="$event.preventDefault(); showEllipsis = true"
          >
            {{ 'common:hide' | i18nextEager }}
          </button>
        </p>
      }
    </div>
  }


</section>

@if (!userMedia().length) {
  <section class="content__status">
    @switch (userStatus) {
      @case ('deleted') {
        <cheelee-status [titleText]="'common:deleted user'"/>
      }
      @case ('blocked') {
        <cheelee-status [titleText]="'common:no media browsing user'"/>
      }
      @default {
        <cheelee-status [titleText]="'common:no media browsing user'"/>
      }
    }
  </section>
}
<section
  class="feed"
  cheeleeInfiniteScroll
  (scrolled)="loadNextUserMediaFeed()"
>
  @if (isMediaLoading() && !userMedia().length) {
    @for (_ of [].constructor(9); track $index) {
      <div class="feed__item skeleton-shine"></div>
    }
  } @else {
    @if (!user()?.is_blocked && !user()?.is_deleted) {
      @for (media of userMedia(); track media.media_id) {
        <div
          class="feed__item"
          cheeleeRipple
          [style.background-image]="'url(' + media.preview_url + ')'"
          (click)="openMedia(media.media_id)"
        >
          <div class="feed__views">
            <cheelee-icon
              class="feed__views-icon"
              iconId="play-video-icon"
              size="16"
            />
            <p class="feed__views-count">
              {{ media.counters.views | shortNumber }}
            </p>
          </div>
        </div>
      }
    }
  }
</section>

@if (isMediaLoading() && userMedia().length > 0 && !!nextPageToken()) {
  <cheelee-loader  size="m"/>
}
