import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { I18NextModule } from 'angular-i18next';

import { I18NextString } from '@core/models';
import { ButtonComponent } from '@shared/components';

type StatusIcon = 'error' | 'empty';

@Component({
  selector: 'cheelee-status',
  standalone: true,
  imports: [NgIf, ButtonComponent, I18NextModule],
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusComponent {
  @Input({ required: true })
  public titleText!: I18NextString;

  @Input()
  public subtitleText?: I18NextString;

  @Input()
  public icon?: StatusIcon;

  @Input()
  public actionBtnText?: I18NextString;

  @Output()
  public action = new EventEmitter<void>();
}
