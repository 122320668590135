import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Media } from '@api/backend';

export const mediaFeedAdapter: EntityAdapter<Media> = createEntityAdapter<Media>({
  selectId: (media) => media.media_id,
});


export interface MediaFeedState extends EntityState<Media> {
  nextPageToken: string | null | undefined;
  isLoading: boolean;
}

export const initialMediaFeedState = mediaFeedAdapter.getInitialState({
  nextPageToken: undefined,
  isLoading: false,
});
