/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ViewSource } from './viewSource';


export interface MediaShowViewRequest { 
    media_id?: string;
    duration?: number;
    view_source?: ViewSource | null;
    recommendation_id?: string;
    opened_at?: number;
    showed_at?: number;
}

