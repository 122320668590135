import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import FingerprintJS, { GetResult as GetFpResult } from '@fingerprintjs/fingerprintjs-pro';
import { environment } from '@environments/environment';

import { from, map, Observable, of } from 'rxjs';

interface Visitor {
  visitorId: string;
  requestId: string;
}

@Injectable({
  providedIn: 'root',
})
export class FingerprintService {
  private fpPromise = FingerprintJS
    .load({ apiKey: environment.fingerprintApiKey })
    .then(fp => fp.get());

  constructor(
    @Inject(PLATFORM_ID)
    private readonly platformId: Object,
  ) {
  }

  public getFingerPrint(): Observable<GetFpResult> {
    return from(this.fpPromise);
  }

  public getVisitorData(): Observable<Visitor> {
    return isPlatformBrowser(this.platformId) ? this.getDataForBrowser() : this.getDataForSSR();
  }

  private getDataForBrowser(): Observable<Visitor> {
    return from(this.getFingerPrint()).pipe(
      map(({ visitorId, requestId }) => ({ visitorId, requestId })),
    );
  }

  private getDataForSSR(): Observable<Visitor> {
    return of<Visitor>({
      visitorId: '',
      requestId: '',
    });
  }
}
