import { AppType as ApiAppType, ViewSource as ViewSourceApi } from '@api/backend';

export type AppTypes = ApiAppType;

export const ViewSources = {
  ...ViewSourceApi,
  UserEdit: 'user_edit',
  UserSettings: 'user_settings',
};

export type I18NextString = `${string}:${string}`;

/* Typing for ngOnChanges Hook */
type MarkFunctionProperties<Component> = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [Key in keyof Component]: Component[Key] extends Function ? never : Key;
};

type ExcludeFunctionPropertyNames<T> = MarkFunctionProperties<T>[keyof T];

type ExcludeFunctions<T> = Pick<T, ExcludeFunctionPropertyNames<T>>;

export type NgChanges<Component, Props = ExcludeFunctions<Component>> = {
  [Key in keyof Props]: {
    previousValue: Props[Key];
    currentValue: Props[Key];
    firstChange: boolean;
    isFirstChange(): boolean;
  };
};
