import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { AfterViewInit, DestroyRef, Directive, EventEmitter, inject, Input, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  standalone: true,
  selector: '[cheeleeInfiniteScroll]',
})
export class InfiniteScrollDirective implements AfterViewInit {
  @Input()
  public targetToFire = 0.1;

  @Output()
  public scrolled = new EventEmitter<void>();

  private isFired = false;

  private destroyRef = inject(DestroyRef);

  constructor(private sd: ScrollDispatcher) {}

  public ngAfterViewInit(): void {
    this.sd
      .scrolled()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((cs: CdkScrollable | void) => this.handleScrollEvent(cs));
  }

  private handleScrollEvent(cs?: CdkScrollable | void): void {
    if (!cs) {
      return;
    }

    const contentHeight = cs.getElementRef().nativeElement.scrollHeight;
    const scrolledUntilNow = cs.measureScrollOffset('bottom');
    const scrolledPercent = scrolledUntilNow / contentHeight;

    if (scrolledPercent <= this.targetToFire) {
      if (!this.isFired) {
        this.isFired = true;
        this.scrolled.emit();
      }
    } else {
      this.isFired = false;
    }
  }
}
