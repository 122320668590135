import { booleanAttribute, Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

import { PlatformService } from '@core/services';

@Directive({
  standalone: true,
  selector: '[cheeleeRipple]',
})
export class RippleDirective implements OnInit {
  @Input({ transform: booleanAttribute })
  public isCenteredRipple = false;

  @Input({ transform: booleanAttribute })
  public isDisabledRipple = false;

  @Input({ transform: booleanAttribute })
  public withOverflowRipple = false;

  @Input({ transform: booleanAttribute })
  public set isSmallRipple(isSmallEffect: boolean) {
    if (isSmallEffect) {
      this.effectName = 'ripple-effect-small';
    }
  }

  private readonly element = this.elementRef.nativeElement;

  private effectName = 'ripple-effect';

  constructor(
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly platformService: PlatformService,
  ) {}

  public ngOnInit(): void {
    if (this.platformService.isBrowser() && !this.isDisabledRipple) {
      this.renderer.setStyle(this.element, 'position', 'relative');

      if (!this.withOverflowRipple) {
        this.renderer.setStyle(this.element, 'overflow', 'hidden');
      }
    }
  }

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {
    if (this.isDisabledRipple) {
      return;
    }

    this.createRipple(event);
  }

  private createRipple(event: MouseEvent): void {
    const circle = this.renderer.createElement('div') as HTMLDivElement;
    const unlistenAnimationEndFn = this.renderer.listen(circle, 'animationend', () => {
      this.renderer.removeChild(circle.parentNode, circle);
      unlistenAnimationEndFn();
    });
    this.renderer.appendChild(this.element, circle);
    const diameter = Math.max(this.element.clientWidth, this.element.clientHeight);

    this.renderer.setStyle(circle, 'width', `${diameter}px`);
    this.renderer.setStyle(circle, 'height', `${diameter}px`);

    const rect = this.element.getBoundingClientRect();

    const leftOffset = this.isCenteredRipple
      ? (rect.right - rect.left) / 2 - diameter / 2
      : event.clientX - rect.left - diameter / 2;

    const topOffset = this.isCenteredRipple
      ? (rect.bottom - rect.top) / 2 - diameter / 2
      : event.clientY - rect.top - diameter / 2;

    this.renderer.setStyle(circle, 'left', `${leftOffset}px`);
    this.renderer.setStyle(circle, 'top', `${topOffset}px`);
    this.renderer.addClass(circle, this.effectName);
  }
}
