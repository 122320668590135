import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { EMPTY_STRING } from '@data/const';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  private infoStyle = 'color: white; background: black';

  private infoPlusStyle = 'color: black; background: #81ff8a';

  private userMessageStyle = 'color: white; background: #d00bba';

  private warnStyle = 'color: white; background: #fd892b';

  private errorStyle = 'color: white; background: #d84e47';

  private dictionaryStyle = 'color: white; background: #4690cd';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  dict(dictionaryName: string, message?: any) {
    this.log(message, dictionaryName, this.dictionaryStyle);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  info(message: any, topic?: string) {
    this.log(message, topic, this.infoStyle);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  params(message: any, topic = 'Входные параметры') {
    this.log(message, topic, this.infoPlusStyle);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  userMessage(message: any, topic = 'Сообщение пользователю') {
    this.log(message, topic, this.userMessageStyle);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  warn(message: any, topic?: string) {
    this.log(message, topic, this.warnStyle);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  error(message: any, topic?: string) {
    this.log(message, topic, this.errorStyle);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  private log(message: any = EMPTY_STRING, topic?: string, style?: string) {
    if (environment.production) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    topic
      // eslint-disable-next-line no-console
      ? console.info(`%c ${topic.toUpperCase()} `, style, message)
      : (typeof message === 'string' || message instanceof String) &&
      style !== this.infoStyle
        // eslint-disable-next-line no-console
        ? console.info(`%c ${message} `, style)
        // eslint-disable-next-line no-console
        : console.info(message);
  }
}
