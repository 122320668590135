import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

import { Snippet } from '@api/backend';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(
    private title: Title,
    private meta: Meta,
    @Inject(DOCUMENT) public document: Document,
  ) {}

  public setSeoTags(snippet?: Snippet): void {
    this.title.setTitle(snippet?.snippet_title ?? '');
    this.meta.updateTag({ property: 'og:title', content: snippet?.snippet_title ?? '' });
    this.meta.updateTag({ property: 'og:url', content: this.document.location.href });
    this.meta.updateTag({ property: 'og:description', content: snippet?.snippet_description ?? '' });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:site_name', content: snippet?.snippet_title ?? '' });
    this.meta.updateTag({ property: 'og:image', content: snippet?.snippet_preview ?? '' });
    this.meta.updateTag({ property: 'og:image:secure_url', content: snippet?.snippet_preview ?? '' });
    this.meta.updateTag({ property: 'og:image:width', content: '576' });
    this.meta.updateTag({ property: 'og:image:height', content: '1024' });
    this.meta.updateTag({ property: 'twitter:image:alt', content: snippet?.snippet_description ?? '' });
    this.meta.updateTag({ property: 'twitter:image', content: snippet?.snippet_preview ?? '' });
    this.meta.updateTag({ property: 'twitter:title', content: snippet?.snippet_title ?? '' });
    this.meta.updateTag({ property: 'twitter:description', content: snippet?.snippet_description ?? '' });
    this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
  }
}
