import { Directive, HostListener, inject, input } from '@angular/core';

import { OverlayService } from '@core/services';
import { BannerPictureType } from '@core/models';

@Directive({
  selector: '[cheeleeOpenGetApp]',
  standalone: true,
})
export class OpenGetAppDirective {
  private readonly overlayService = inject(OverlayService);

  public preventDefault = input<boolean>(true);

  public picture = input<BannerPictureType>();

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent | TouchEvent): void {
    event.stopPropagation();
    if (this.preventDefault()) {
      event.preventDefault();
    }
    this.overlayService.openGetAppDialog(this.picture());
  }
}
