import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { I18NextModule } from 'angular-i18next';

import { MediaRepository } from '@core/repositories/media.repository';
import { MediaWebRepository } from '@data/repositories/media-web.repository';
import { MediaRoutingModule } from '@modules/media/media-routing.module';
import { AvatarComponent, ButtonComponent, HeaderComponent, SpinnerComponent } from '@shared/components';
import { FeedNavComponent } from '@shared/components/media-swiper/components/feed-nav/feed-nav.component';
import { MediaSwiperComponent } from '@shared/components/media-swiper/media-swiper.component';
import { RippleDirective } from '@shared/directives';
import { InfiniteScrollDirective } from '@shared/directives/infinite-scroll.directive';
import { MentionLinkPipe, ShortNumberPipe } from '@shared/pipes';
import { MediaFeatureModule } from '@store/media';

import { FeedComponent } from '@modules/media/feed/feed.component';
import { MediaItemComponent } from './media-item/media-item.component';
import { MediaActionsComponent } from '@shared/components/media-actions/media-actions.component';
import { LayoutsModule } from '@shared/layouts/layouts.module';
import { MediaService } from '@api/backend';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MediaRoutingModule,
    MediaFeatureModule,
    ScrollingModule,
    I18NextModule,

    // LoaderComponent,
    // IconComponent,
    MediaItemComponent,
    FeedComponent,
    HeaderComponent,
    MediaSwiperComponent,
    FeedNavComponent,
    ButtonComponent,
    SpinnerComponent,
    AvatarComponent,

    InfiniteScrollDirective,
    RippleDirective,

    ShortNumberPipe,
    MentionLinkPipe,
    MediaActionsComponent,

    LayoutsModule,
    // UserComponent,
  ],
  exports: [ MediaItemComponent, FeedComponent ],
  providers: [ { provide: MediaRepository, useClass: MediaWebRepository } ],
})
export class MediaModule {
  constructor(private mediaService: MediaService) {}
}
