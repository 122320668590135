import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
  BaseResponse,
  CategoriesList,
  CheckUserInfoResponse,
  ComplaintReasonResponse,
  ComplaintRequest,
  ComplaintTicketResponse,
  CreatePassword2FARequest,
  DeletePassword2FARequest,
  Email,
  EmailAddressMaskedResponse,
  EmailVerification,
  EmailWithEmailMaskedResponse,
  PhoneNumberExistResponse,
  PhoneNumberMaskedResponse,
  PhoneNumberVerification,
  PhoneNumberWithPhoneNumberMaskedResponse,
  PutUserPhoneNumber,
  RequestUserInfo,
  SetPubkey2FARequest,
  SetPubkey2FAResponse,
  UpdatePassword2FARequest,
  UserFollowersResponse,
  UserFollowingsResponse,
  UserResponse,
  UserSearchResponse,
  UserService,
  UsersService,
  VerificationCode2FA,
  VerificationCode2FATransport,
  VerifyPassword2FARequest,
  VerifyPassword2FAResponse,
} from '@api/backend';
import { UsersRepository } from '@core/repositories/users.repository';

@Injectable()
export class UsersWebRepository extends UsersRepository {
  constructor(
    private usersService: UsersService,
    private userService: UserService,
  ) {
    super();
  }

  public postUsers2faVerify(body: VerifyPassword2FARequest): Observable<VerifyPassword2FAResponse> {
    return this.usersService.postUser2faVerifyUsers2faVerifyPost(body);
  }

  public searchUser(query: string, page_token?: string | undefined): Observable<UserSearchResponse> {
    return this.usersService.userSearchUsersSearchGet(query, 20, page_token);
  }

  public discoverUser(page_token?: string | undefined): Observable<UserSearchResponse> {
    return this.usersService.discoverUsersUsersDiscoverGet(20, page_token);
  }

  public getUserComplaintsReasons(): Observable<ComplaintReasonResponse> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return this.usersService.getUserComplaintReasons();
  }

  public createUserComplaint(user_id: string, body: ComplaintRequest): Observable<ComplaintTicketResponse> {
    return this.usersService.postUserComplaintUsersUserIdComplaintPost(user_id, body);
  }

  public checkUsername(user_name: string): Observable<CheckUserInfoResponse> {
    return this.usersService.checkUserNamesUsersCheckUsernameGet(user_name);
  }

  public getUserById(user_id: string): Observable<UserResponse> {
    return this.usersService.getUserByIdUsersUserIdGet(user_id);
  }

  public deleteUser(user_id: string): Observable<BaseResponse> {
    return this.usersService.deleteUserUsersUserIdDelete(user_id);
  }

  public patchUser(user_id: string, body: RequestUserInfo): Observable<UserResponse> {
    return this.usersService.patchUserByIdUsersUserIdPatch(user_id, body);
  }

  public getFollowings(user_id: string, page_token?: string): Observable<UserFollowingsResponse> {
    return this.usersService.getUserFollowingsUsersUserIdFollowingsGet(user_id, 20, page_token);
  }

  public getFollowers(user_id: string, page_token?: string): Observable<UserFollowersResponse> {
    return this.usersService.getUserFollowersUsersUserIdFollowersGet(user_id, 20, page_token);
  }

  public putAvatar(user_id: string, avatar: File): Observable<UserResponse> {
    return this.usersService.putUserAvatarByIdUsersUserIdAvatarPut(user_id, avatar);
  }

  public putBackgroundAvatar(user_id: string, avatar: File, background: File): Observable<UserResponse> {
    return this.usersService.putUserAvatarAndBackgroundByIdUsersUserIdAvatarBackgroundPut(user_id, background, avatar);
  }

  public followUser(user_id: string): Observable<BaseResponse> {
    return this.usersService.subscribeUserUsersUserIdSubscribePost(user_id);
  }

  public unfollowUser(user_id: string): Observable<BaseResponse> {
    return this.usersService.unsubscribeUserUsersUserIdUnsubscribePost(user_id);
  }

  public putUsers2faPassword(body: UpdatePassword2FARequest): Observable<BaseResponse> {
    return this.usersService.putUser2faPasswordUsers2faPasswordPut(body);
  }

  public postUsers2faPassword(body: CreatePassword2FARequest): Observable<BaseResponse> {
    return this.usersService.postUser2faPasswordUsers2faPasswordPost(body);
  }

  public deleteUsers2faPassword(body: DeletePassword2FARequest): Observable<BaseResponse> {
    return this.usersService.deleteUser2faPasswordUsers2faPasswordDelete(body);
  }

  public postUsers2faPinCodeKey(body: SetPubkey2FARequest): Observable<SetPubkey2FAResponse> {
    return this.usersService.postUser2faPincodeKeyUsers2faPincodeKeyPost(body);
  }

  public postUsers2faPhone(body: VerificationCode2FATransport): Observable<PhoneNumberMaskedResponse> {
    return this.usersService.sendPhoneVerificationCodeUsers2faPhonePost(body);
  }

  public postUsers2faPhoneVerify(body: VerificationCode2FA): Observable<BaseResponse> {
    return this.usersService.verifyPhoneUsers2faPhoneVerifyPost(body);
  }

  public postUsers2faEmail(): Observable<EmailAddressMaskedResponse> {
    return this.usersService.sendEmailVerificationCodeUsers2faEmailPost();
  }

  public postUsers2faEmailVerify(body: VerificationCode2FA): Observable<BaseResponse> {
    return this.usersService.verifyEmailUsers2faEmailVerifyPost(body);
  }

  public putUsersPhone(
    user_id: string,
    body: PutUserPhoneNumber,
  ): Observable<PhoneNumberWithPhoneNumberMaskedResponse> {
    return this.usersService.putUserPhoneNumberUsersUserIdPhonePut(user_id, body);
  }

  public postUsersPhoneVerify(
    user_id: string,
    body: PhoneNumberVerification,
  ): Observable<PhoneNumberWithPhoneNumberMaskedResponse> {
    return this.usersService.verifyCodeViaPhoneNumberUsersUserIdPhoneVerifyPost(user_id, body);
  }

  public putUsersEmail(user_id: string, body: Email): Observable<EmailWithEmailMaskedResponse> {
    return this.usersService.putUserEmailUsersUserIdEmailPut(user_id, body);
  }

  public postUsersEmailVerify(user_id: string, body: EmailVerification): Observable<EmailWithEmailMaskedResponse> {
    return this.usersService.verifyCodeViaEmailUsersUserIdEmailVerifyPost(user_id, body);
  }

  public getUsersPhoneExist(user_id: string): Observable<PhoneNumberExistResponse> {
    return this.usersService.getUserPhoneNumberExistUsersUserIdPhoneExistGet(user_id);
  }

  public postUsersBlock(user_id: string): Observable<UserResponse> {
    return this.usersService.blockUserUsersUserIdBlockPost(user_id);
  }

  public postUsersUnblock(user_id: string): Observable<UserResponse> {
    return this.usersService.unblockUserUsersUserIdUnblockPost(user_id);
  }

  public postUsersInterests(body: CategoriesList): Observable<BaseResponse> {
    return this.usersService.userInterestsUsersInterestsPost(body);
  }

  public getUsersSnippet(user_id: string): Observable<BaseResponse> {
    return this.userService.getSnippetUserUsersUserIdSnippetGet(user_id);
  }
}
