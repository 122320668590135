import { BehaviorSubject, Observable } from 'rxjs';

export function isTextEllipsisActive(element: HTMLElement): boolean {
  if (element.clientWidth < element.scrollWidth) {
    const elementStyle = window.getComputedStyle(element);
    return elementStyle.textOverflow === 'ellipsis';
  }

  return false;
}

export function isImageLoaded$(src: string | undefined): Observable<boolean> {
  const isLoadedSubject = new BehaviorSubject<boolean>(false);

  if (src) {
    const image = new Image();
    image.src = src;

    if (image.complete) {
      isLoadedSubject.next(true);
    } else {
      image.onload = () => isLoadedSubject.next(true);
      image.onerror = () => isLoadedSubject.next(true);
    }
  } else {
    isLoadedSubject.next(true);
  }

  return isLoadedSubject.asObservable();
}
