import { createReducer, on } from '@ngrx/store';

import { ErrorResponse, User } from '@core/models';
import { Snippet } from '@api/backend';
import { initialMediaFeedState, mediaFeedAdapter, MediaFeedState } from '@store/media/entity-adapters';
import { userActions } from '@store/user/user.actions';

export const userFeatureKey = 'user';

export interface UserState {
  user?: User;
  snippet?: Snippet;
  error?: ErrorResponse;
  browsingUser?: User;
  userMedia: MediaFeedState;
  isLoading: boolean;
}

export const initialState: UserState = {
  isLoading: false,
  userMedia: initialMediaFeedState,
};

export const userReducer = createReducer(
  initialState,
  on(
    userActions.getUser,
    (state): UserState => {
      return ({ ...state });
    },
  ),
  on(
    userActions.getUserSuccess,
    (state, { user }): UserState => {
      return ({ ...state, user });
    },
  ),
  on(
    userActions.getUserFailure,
    (state, { error }): UserState => {
      return ({ ...state, error });
    },
  ),
  on(
    userActions.clearUser,
    (state): UserState => {
      return ({ ...state, user: undefined });
    },
  ),
  on(userActions.getUserFailure,
    (state, { error }): UserState => {
      return ({ ...state, error });
    },
  ),
  on(
    userActions.getBrowsingUser,
    (state): UserState => {
      return ({ ...state, browsingUser: undefined });
    },
  ),
  on(
    userActions.getBrowsingUserSuccess,
    (state, { browsingUser }): UserState => {
      return ({ ...state, browsingUser });
    },
  ),
  on(
    userActions.getBrowsingUserFailure,
    (state): UserState => {
      return ({ ...state, isLoading: false });
    },
  ),
  /**
   * User Media
   */
  on(
    userActions.loadFeedMedia,
    (state, { isNextPart }): UserState => ({
      ...state,
      userMedia: {
        ...state.userMedia,
        nextPageToken: isNextPart ? state.userMedia.nextPageToken : undefined,
      },
    }),
  ),
  on(
    userActions.loadFeedMediaInit,
    (state): UserState => ({
      ...state,
      userMedia: {
        ...state.userMedia,
        isLoading: true,
      },
    }),
  ),
  on(
    userActions.loadFeedMediaSuccess,
    (state, { page, isNextPart }): UserState => ({
      ...state,
      userMedia: isNextPart
        ? mediaFeedAdapter.addMany(page.media, {
          ...state.userMedia,
          nextPageToken: page.next_page_token as string,
          recommendation_id: page.recommendation_id,
          isLoading: false,
        })
        : mediaFeedAdapter.setAll(page.media, {
          ...state.userMedia,
          nextPageToken: page.next_page_token as string,
          recommendation_id: page.recommendation_id,
          isLoading: false,
        }),
    }),
  ),
  on(
    userActions.loadFeedMediaFailure,
    (state): UserState => ({
      ...state,
      userMedia: {
        ...state.userMedia,
        isLoading: false,
      },
    }),
  ),
  on(
    userActions.loadFeedMediaComplete,
    (state): UserState => ({
      ...state,
      userMedia: {
        ...state.userMedia,
        isLoading: false,
      },
    }),
  ),
  on(
    userActions.clearFeed,
    (state): UserState => ({
      ...state,
      userMedia: mediaFeedAdapter.getInitialState({
        recommendation_id: undefined,
        nextPageToken: undefined,
        isLoading: false,
      }),
    }),
  ),
);
