import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, signal } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PlatformService {
  public readonly isBrowser = signal(isPlatformBrowser(this.platformId));

  public readonly isServer = signal(isPlatformServer(this.platformId));

  constructor(
    @Inject(PLATFORM_ID)
    private readonly platformId: Object,
  ) {}
}
