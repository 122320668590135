import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ViewSource } from '@core/models';
import { FeedComponent } from '@modules/media/feed/feed.component';
import { MediaItemComponent } from '@modules/media/media-item/media-item.component';

const ROUTES: Routes = [
  { path: '', component: FeedComponent, data: { type: ViewSource.Recommendations } },
  { path: 'media/:mediaId', component: MediaItemComponent },
];

@NgModule({
  imports: [ RouterModule.forChild(ROUTES) ],
  exports: [ RouterModule ],
})
export class MediaRoutingModule {
}
