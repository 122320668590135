import { BreakpointObserver } from '@angular/cdk/layout';
import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Store } from '@ngrx/store';
import { distinctUntilChanged, map, tap } from 'rxjs';

import { filterNil } from '@core/helpers';
import { BREAKPOINT_VALUES, Breakpoints } from '@data/const';
import { layoutActions } from '@store/layout';

@Injectable({ providedIn: 'root' })
export class BreakpointService {
  public readonly breakpoint$ = this.breakpointObserver
    .observe(BREAKPOINT_VALUES)
    .pipe(
      map(
        (breakpointsState) =>
          Object.keys(breakpointsState.breakpoints).find((breakpoint) =>
            this.breakpointObserver.isMatched(breakpoint),
          ) as Breakpoints,
      ),
      distinctUntilChanged(),
      filterNil(),
      tap((breakpoint) => {
        this.store.dispatch(layoutActions.setBreakpoint({ breakpoint }));
      }),
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private store: Store,
    private readonly destroyRef: DestroyRef,
  ) {}

  public init(): void {
    this.breakpoint$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }
}
