import { CommonModule, Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  DestroyRef,
  ElementRef,
  inject,
  input,
  OnDestroy,
  OnInit,
  Renderer2,
  Signal,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { asyncScheduler, combineLatest, observeOn, take } from 'rxjs';
import { filter } from 'rxjs/operators';
import Swiper from 'swiper';
import { register, SwiperContainer } from 'swiper/element/bundle';

import { BreakpointFacade, FeedFacade, RouterFacade } from '@core/facades';
import { isImageLoaded$ } from '@core/helpers';
import { Media, MediaActionsMode } from '@core/models/media.model';
import { LogService, MediaViewService, OverlayService } from '@core/services';
import { HeaderComponent, SpinnerComponent } from '@shared/components';
import { IconComponent } from '@shared/components/icon/icon.component';
import { AboutMediaComponent } from '@shared/components/media-swiper/components/about-media/about-media.component';
import { MediaActionsComponent } from '@shared/components/media-actions/media-actions.component';
import { MediaSwiperFacade } from '@shared/components/media-swiper/media-swiper.facade';
import { SWIPER_CONFIG } from '@shared/components/media-swiper/swiper-config.const';
import { RippleDirective, SingleDoubleClickDirective } from '@shared/directives';
import { FeedNavComponent } from '@shared/components/media-swiper/components/feed-nav/feed-nav.component';
import { DonationComponent } from '@shared/components/media-swiper/components/donation/donation.component';
import { BannerPictureType } from '@core/models';
import { GoBackDirective } from '@shared/directives/go-back.directive';
import { TopButtonComponent } from '@shared/components/media-swiper/components/top-button/top-button.component';
import { AnalyticsEvents } from '@core/models/analytics.model';
import { AnalyticsDirective } from '@shared/directives/analytics.directive';

register();

type SwiperEvent = CustomEvent<[ swiper: Swiper ]>;

@Component({
  selector: 'cheelee-media-swiper',
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    AboutMediaComponent,
    MediaActionsComponent,
    SingleDoubleClickDirective,
    SpinnerComponent,
    FeedNavComponent,
    DonationComponent,
    GoBackDirective,
    HeaderComponent,
    RippleDirective,
    TopButtonComponent,
    AnalyticsDirective,
  ],
  templateUrl: './media-swiper.component.html',
  styleUrls: [ './media-swiper.component.scss' ],
  providers: [ MediaSwiperFacade, MediaViewService ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaSwiperComponent implements OnInit, AfterViewInit, OnDestroy {

  public isMobile = inject(BreakpointFacade).isMobile;

  public mediaList = input<Media[]>([]);

  public mode = input<MediaActionsMode>(MediaActionsMode.RECOMMENDATIONS);

  public swiperRef = viewChild.required<ElementRef<SwiperContainer>>('swiper');

  public readonly isLoading = this.mediaSwiperFacade.isLoading;

  public readonly isMuted = this.mediaSwiperFacade.isMuted;

  public readonly isLoading$ = this.mediaSwiperFacade.isLoading$;

  public feedLength!: Signal<number>;

  public readonly feedLength$ = this.mediaSwiperFacade.feedLength$;

  public readonly currentIndex = signal(0);

  public readonly activeVideo = signal<HTMLVideoElement | undefined>(undefined);

  public readonly canShowPauseEffect = signal(false);

  public readonly canShowLikeEffect = signal(false);

  public readonly canShowVideoSpinner = signal(false);

  public readonly isActiveVideoPosterLoaded = signal(false);

  public readonly withTransparentActions = signal(false);

  private readonly isMediaPlaying$ = this.feedFacade.isMediaPlaying$;

  constructor(
    private readonly mediaSwiperFacade: MediaSwiperFacade,
    private readonly feedFacade: FeedFacade,
    private readonly renderer: Renderer2,
    private readonly destroyRef: DestroyRef,
    private readonly routerFacade: RouterFacade,
    private readonly overlayService: OverlayService,
    private readonly location: Location,
    private readonly mediaViewService: MediaViewService,
    private readonly log: LogService,
  ) {
  }

  public ngOnInit(): void {
    // Инициализируем feedLength в зависимости от текущего режима
    this.feedLength = this.mode() === 'single' ? signal(1) : this.mediaSwiperFacade.feedLength;

    // Подписываемся на состояние воспроизведения медиа
    this.isMediaPlaying$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isPlaying) => {
        const video = this.activeVideo();
        if (video) {
          if (isPlaying && video.paused) {
            void this.handlePlay(video);
          } else if (!isPlaying && !video.paused) {
            this.handlePause(video);
          }
        }
      });

    // Устанавливаем текущий индекс слайда
    this.setCurrentSlideIndex();

    // Запуск воспроизведения медиа
    this.feedFacade.playMedia();
  }

  public ngAfterViewInit(): void {
    this.initSwiper();
    this.swiperRef().nativeElement.swiper.activeIndex = this.currentIndex();
    this.swiperRef().nativeElement.swiper.update();
    this.listenSwiperEvents();
    this.swiperRef().nativeElement.swiper.mousewheel.enable();
    this.playActiveVideo();

    combineLatest([ this.feedLength$, this.isLoading$ ])
      .pipe(
        filter(([ , isLoading ]) => !isLoading),
        observeOn(asyncScheduler),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(([ feedLength ]) => {
        if (feedLength > 0) {
          this.swiperRef().nativeElement.swiper.update();
          this.swiperRef().nativeElement.swiper.updateSlides();
          this.mediaViewService.init(this.currentMedia);
          this.playActiveVideo();
        }
      });
  }

  ngOnDestroy() {
    this.mediaSwiperFacade.muteMedia(true);
    this.mediaViewService.sendData((data) => this.feedFacade.addMediaViewInfo(data, true));
  }

  public getMediaByIndex(index: number): Signal<Media> {
    return this.mode() === 'single' ? signal(this.mediaList()[index]) : this.mediaSwiperFacade.selectMediaByIndex(index);
  }

  public get currentMediaId(): string {
    return this.getMediaByIndex(this.currentIndex())()?.media_id ?? '';
  }

  public get currentMedia(): Media {
    return this.getMediaByIndex(this.currentIndex())();
  }

  private handlePlay(video: HTMLVideoElement): void {
    if (!video.paused && !video.ended) {
      this.log.warn('Video is already playing.');
      return;
    }

    video.play().then(() => {
      this.log.info('Video started playing successfully.');
      this.mediaViewService.playVideo();
    }).catch(error => {
      if (error.name === 'AbortError') {
        this.log.warn('Playback was interrupted, attempting again...');
        video.play().then(() => {
          this.log.info('Video started playing successfully on retry.');
          this.mediaViewService.playVideo();
        }).catch(err => {
          this.log.error('An error occurred on retrying to play the video: ' + err);
        });
      } else {
        this.log.error('An error occurred while trying to play the video: ' + error);
      }
    });
  }

  private handlePause(video: HTMLVideoElement): void {
    this.mediaViewService.pauseVideo();
    video.pause();
  }

  public onVideoClick(): void {
    const video = this.activeVideo() as HTMLVideoElement;
    this.canShowPauseEffect.set(true);

    if (video.paused) {
      this.handlePlay(video);
    } else {
      this.handlePause(video);
    }
  }

  public goBack(): void {
    this.activeVideo()?.pause();

    const locationState = this.location.getState();

    const isFirstNavigation =
      typeof locationState === 'object'
      && locationState !== null
      && 'navigationId' in locationState
      && locationState.navigationId === 1;

    switch (this.mode()) {
      case 'single':
        if (isFirstNavigation) {
          this.routerFacade.navigate({ path: '/' });
        } else {
          this.location.back();
        }
        break;

      case 'userMedia':
        this.location.back();
        break;

      default:
        break;
    }
  }

  private initSwiper(): void {
    Object.assign(this.swiperRef().nativeElement, SWIPER_CONFIG);
    this.swiperRef().nativeElement.initialize();
  }

  private onSlideChange(): void {
    if (this.currentIndex() >= this.mediaList().length - 3 && !this.isLoading() && this.mode() !== 'single') {
      this.getNextFeedMedia();
    }
  }

  private onSlideChangeTransitionEnd(swiper: SwiperEvent): void {
    if (this.mode() === 'single') {
      this.goBack();
      return;
    }

    this.canShowPauseEffect.set(false);
    const [ swiperDetails ] = swiper.detail;
    this.currentIndex.set(swiperDetails.activeIndex);
    this.mediaViewService.sendData((data) => this.feedFacade.addMediaViewInfo(data));
    this.mediaViewService.init(this.currentMedia);
    this.playActiveVideo();
    const mediaList = this.mediaList();

    if (this.mode() === 'recommendations' && swiperDetails.activeIndex <= mediaList.length - 1) {
      this.mediaSwiperFacade.setCurrentMediaIndex(this.mediaList()[swiperDetails.activeIndex].media_id);
    }
  }

  private getNextFeedMedia(): void {
    this.mediaSwiperFacade.getNextFeedMedia();
  }

  private listenSwiperEvents(): void {
    const { nativeElement: swiperElement } = this.swiperRef();

    const unlistenSlideChange = this.renderer.listen(swiperElement, 'swiperslidechange', () => this.onSlideChange());

    const unlistenTouchMove = this.renderer.listen(swiperElement, 'swipertouchmove', () => {
      if (!this.withTransparentActions()) {
        this.withTransparentActions.set(true);
      }
    });

    const unlistenTouchEnd = this.renderer.listen(swiperElement, 'swipertouchend', () => {
      if (this.withTransparentActions()) {
        this.withTransparentActions.set(false);
      }
    });

    const unlistenslideChangeTransitionStart = this.renderer.listen(
      swiperElement,
      'swiperslidechangetransitionstart',
      () => {
        this.canShowPauseEffect.set(false);
        this.canShowLikeEffect.set(false);
      },
    );

    const unlistenslideChangeTransitionEnd = this.renderer.listen(
      swiperElement,
      'swiperslidechangetransitionend',
      (event: SwiperEvent) => {
        this.onSlideChangeTransitionEnd(event);
      },
    );

    this.destroyRef.onDestroy(() => {
      unlistenSlideChange();
      unlistenslideChangeTransitionStart();
      unlistenslideChangeTransitionEnd();
      unlistenTouchMove();
      unlistenTouchEnd();
    });
  }

  private playActiveVideo(): void {
    this.mediaViewService.update({
      showed_at: new Date().getTime(),
    });
    const visibleVideos = Array.from<HTMLVideoElement>(
      this.swiperRef().nativeElement.querySelectorAll('video.swiper__video'),
    );

    visibleVideos.forEach((video) => {
      video.pause();
      video.muted = true;
      video.currentTime = 0;
    });

    asyncScheduler.schedule(() => {
      if (!this.swiperRef().nativeElement.swiper.slides?.length) {
        return;
      }

      const activeSlide = this.swiperRef().nativeElement.swiper.slides[this.currentIndex()];
      const activeVideo = activeSlide.querySelector('.swiper__video') as HTMLVideoElement;

      if (activeVideo) {
        this.isActiveVideoPosterLoaded.set(false);
        this.activeVideo.set(activeVideo);
        this.handlePlay(activeVideo);

        isImageLoaded$(activeVideo.poster)
          .pipe(take(1), takeUntilDestroyed(this.destroyRef))
          .subscribe(() => this.isActiveVideoPosterLoaded.set(true));
      }
    }, 100);
  }

  private setCurrentSlideIndex(): void {
    switch (this.mode()) {
      case 'single':
        this.currentIndex.set(0);
        break;

      case 'userMedia':
        this.currentIndex.set(this.mediaSwiperFacade.currentMediaIndex() ?? 0);
        break;

      case 'recommendations': {
        const index = 0;
        this.currentIndex.set(index);
        break;
      }
    }
  }

  public openGetAppDialog(picture?: BannerPictureType): void {
    this.overlayService.openGetAppDialog(picture);
  }

  public toggleMute() {
    this.mediaSwiperFacade.muteMedia();
  }

  protected readonly MediaActionsMode = MediaActionsMode;

  protected readonly analyticsEvents = AnalyticsEvents;
}
