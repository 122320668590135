import { APP_INITIALIZER, Provider } from '@angular/core';

import { InternetConnectionService } from '@core/services';

export const INTERNET_CONNECTION_PROVIDER: Provider = {
  provide: APP_INITIALIZER,
  useFactory:
    (internetConnectionService: InternetConnectionService) => () => internetConnectionService.startMonitoring(),
  deps: [InternetConnectionService],
  multi: true,
};
