<cheelee-responsive-layout>
  <ng-container>
    @if (user()) {
      <div
        #userRef
        class="user"
        (animationend)="userRef.style.animation = 'unset'"
      >
        <cheelee-header
          [isFixed]="true"
          [background]="isColorizedHeader() ? '#140132' : null"
        >
          <div class="header__container">
            <div class="header__go-back">
              <button
                class="header__go-back-btn"
                cheeleeGoBack
                cheeleeRipple
                isCenteredRipple
              >
                <cheelee-icon
                  class="header__go-back-icon"
                  iconId="arrow-left-icon"
                />
              </button>
            </div>

            <p
              class="header__username"
              [style.opacity]="isColorizedHeader() ? 1 : 0"
            >
              {{ user()?.user_name }}
            </p>
            @if (user()) {
              <div class="header__right">
                <button
                  class="header__share-btn"
                  cheeleeRipple
                  isCenteredRipple
                  (click)="openShareCurtain()"
                >
                  <cheelee-icon
                    class="header__share-icon"
                    iconId="share-filled-icon"
                  />
                </button>

                <button
                  class="header__settings-btn"
                  cheeleeRipple
                  isCenteredRipple
                  (click)="openGetAppDialog('lee')"
                >
                  <cheelee-icon
                    class="header__settings-icon"
                    iconId="menu-icon"
                  />
                </button>
              </div>
            }
          </div>
        </cheelee-header>

        <div class="user__main">
          <div
            #background
            class="user__background"
            [ngStyle]="{
              height: background.offsetWidth + 'px',
            }"
          >
            <div
              #cover
              class="user__background-img"
              [ngStyle]="{
                'background-image': userBackground(),
                height: background.offsetWidth + 'px',
             }"
              [class.no-shadow]="!isBackgroundReady()"
            ></div>
            @if (!isBackgroundReady()) {
              <cheelee-spinner
                class="user__background-spinner"
                size="50"
              />
            }
            <section class="user__background-info">
              <div class="user__names">
                <div class="user__username-wrapper">
                  <p class="user__username headline">
                    {{ user()?.user_name }}
                  </p>
                  @if (user()?.is_identity_confirmed) {
                    <cheelee-icon
                      class="user__confirmed-icon"
                      iconId="confirmed-user-big-icon"
                      size="20"
                    />
                  }
                </div>
                @if (user()?.person_name) {
                  <p class="user__person-name text-1">
                    {{ user()?.person_name }}
                  </p>
                }
              </div>

              @if (!user()?.is_deleted) {
                @if (user()?.is_blocked) {
                  <cheelee-button
                    [isDisabled]="isLoading()"
                    customBackgroundColor="#140132"
                    isSmall
                    variant="outlined"
                    (clicked)="openGetAppDialog('lee')"
                  >
                    @if (isLoading()) {
                      <cheelee-spinner/>
                    } @else {
                      <span>
                    {{ 'actions:unblock' | i18nextEager }}
                  </span>
                    }
                  </cheelee-button>
                } @else {
                  @if (!user()?.subscriptions?.is_following) {
                    <div [style.min-width.px]="112">
                      <cheelee-button
                        [isDisabled]="isLoading()"
                        customBackgroundColor="#140132"
                        customColor="#01FFC2"
                        isSmall
                        variant="outlined"
                        withFullWidth
                        (clicked)="openGetAppDialog('lee')"
                      >
                        @if (isLoading()) {
                          <cheelee-spinner/>
                        } @else {
                          <span>
                      {{ 'actions:follow' | i18nextEager }}
                    </span>
                        }
                      </cheelee-button>
                    </div>
                  }
                }
              }
            </section>
          </div>

          <div class="user__content">
            @if (user()) {
              <cheelee-user-content [user]="user()"/>
            }
          </div>
        </div>
      </div>
    } @else {
      <cheelee-loader class="user__page-loader"/>
    }
  </ng-container>
</cheelee-responsive-layout>
