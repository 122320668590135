<cheelee-dialog-wrapper class="get-app">
  <div
    class="get-app__header"
    ngProjectAs="header"
  >
    <div class="get-app__image-wrapper">
      <img
        class="get-app__image"
        alt="Earn in Cheelee"
        [ngSrc]="'./assets/img/banner_' + (picture() ?? pictureData ?? getRandomPicture) + '_x2.png'"
        width="192"
        height="120"
      />
    </div>

    <div class="get-app__close-btn">
      <button
        cheeleeRipple
        isCenteredRipple
        (click)="close()"
      >
        <cheelee-icon
          iconId="cross-icon"
          color="#AB9BFD"
        />
      </button>
    </div>
  </div>

  <div class="get-app__content">
    <p class="get-app__download-text">{{ 'common:join the cheelee' | i18nextEager }}</p>
    <p class="get-app__download-text">{{ 'common:watch video and earn' | i18nextEager }}</p>
  </div>

  <div class="get-app__action-button">
    <cheelee-button
      withFullWidth
      (clicked)="onButtonClick()"
      [analytics]="analyticsEvents.ModalGoToCheeleeTap"
    >
      {{ 'common:to go to cheelee' | i18nextEager }}
    </cheelee-button>
  </div>

</cheelee-dialog-wrapper>
