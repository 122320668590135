/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PrevPageToken } from './prevPageToken';
import { NextPageToken } from './nextPageToken';
import { Media } from './media';
import { PageToken } from './pageToken';


export interface RecommendationsPage { 
    media: Array<Media>;
    page_token?: PageToken;
    next_page_token?: NextPageToken;
    prev_page_token?: PrevPageToken;
    recommendation_id?: string;
}

