/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 */
export type OAuthOptions = 'google' | 'vk' | 'facebook' | 'apple';

export const OAuthOptions = {
    Google: 'google' as OAuthOptions,
    Vk: 'vk' as OAuthOptions,
    Facebook: 'facebook' as OAuthOptions,
    Apple: 'apple' as OAuthOptions
};

