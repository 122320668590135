import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';

import { I18NextService } from 'angular-i18next';
import { DeviceDetectorService } from 'ngx-device-detector';

import { FeedFacade } from '@core/facades';
import { SharedData } from '@core/models';
import { OverlayService } from '@core/services';
import { ShareComponent } from '@shared/components';
import { DIALOG_ANIMATION_CLASSES, ModalsNames } from '@data/const';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class ShareLinkService {
  private readonly overlayService = inject(OverlayService);

  private readonly deviceDetectorService = inject(DeviceDetectorService);

  private readonly document: Document = inject(DOCUMENT);

  private readonly i18NextService = inject(I18NextService);

  private readonly feedFacade = inject(FeedFacade);

  private get isMobileDevice(): boolean {
    return this.deviceDetectorService.isMobile() || this.deviceDetectorService.isTablet();
  }

  public share(sharedItemId: string, title: string, itemType: SharedData['type']): void {
    const isMedia = itemType === 'media';
    const shareBaseUrl = environment.redirectServiceUrl || this.document.defaultView?.location.origin || '';
    if (this.isMobileDevice && this.document.defaultView?.navigator.share) {
      this.document.defaultView.navigator
        .share({
          title: title ?? 'Watch Сheelee - get cash | Cheelee',
          text:
          // isMedia
          //   ? (this.i18NextService.t('common:you must see this') as string)
          //   : 'Watch cheelee - get cash | Cheelee',
            'Watch Cheelee - get cash | Cheelee',
          url:
            isMedia
              ? `${shareBaseUrl}/media/${sharedItemId}`
              : `${shareBaseUrl}/users/${sharedItemId}`,
        })
        .then(() => this.feedFacade.updateShareCounter(sharedItemId, itemType))
        // eslint-disable-next-line no-console
        .catch(console.error);
    } else {
      this.overlayService.openDialog<ShareComponent>(
        isMedia ? ModalsNames.ShareMedia : ModalsNames.ShareUser,
        ShareComponent,
        {
          sharedData: {
            id: sharedItemId,
            type: isMedia ? 'media' : 'user',
            view: 'dialog',
            title,
          },
        },
        `${DIALOG_ANIMATION_CLASSES.mobileFullscreen} ${DIALOG_ANIMATION_CLASSES.leftToRight}`,
      );
    }
  }

  public shareLink(link: string): void {
    if (this.isMobileDevice && this.document.defaultView?.navigator.share) {
      void this.document.defaultView.navigator
        .share({
          title: 'Watch Cheelee - get cash | Cheelee',
          url: link,
        })
        .then();
    } else {
      this.overlayService.openCurtain<ShareComponent>({
        title: 'common:share link',
        component: ShareComponent,
        componentInputs: {
          sharedData: { link },
        },
        isStretchDisabled: true,
      });
    }
  }
}
