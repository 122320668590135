import { Media as MediaApi, MediaLikesPage } from '@api/backend';

export type Media = MediaApi;

export enum MediaActionsMode {
  RECOMMENDATIONS = 'recommendations',
  SINGLE_FEED = 'single-feed',
  SINGLE = 'single',
  USER_MEDIA = 'userMedia',
}

export type MediaLikes = MediaLikesPage;
