function safeDecodeURIComponent(encodedURIComponent: string) {
  try {
    return decodeURIComponent(encodedURIComponent);
  } catch {
    // probably it is not uri encoded. return as is
    return encodedURIComponent;
  }
}

function getCookieRegExp(name: string) {
  return new RegExp('(?:^' + name + '|;\\s*' + name + ')=(.*?)(?:;|$)', 'g');
}

export function getCookie(name: string) {
  try {
    name = encodeURIComponent(name);
    const regExp = getCookieRegExp(name);
    const result = regExp.exec(document.cookie);
    return result?.[1] ? safeDecodeURIComponent(result[1]) : '';
  } catch {
    return '';
  }
}
