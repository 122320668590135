import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { layoutFeature } from './layout.reducer';


@NgModule({
  imports: [StoreModule.forFeature(layoutFeature), EffectsModule.forFeature([])],
  exports: [StoreModule],
})
export class LayoutFeatureModule {}
