/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { BaseResponse } from '../model/baseResponse';
// @ts-ignore
import { CategoriesList } from '../model/categoriesList';
// @ts-ignore
import { CheckUserInfoResponse } from '../model/checkUserInfoResponse';
// @ts-ignore
import { ComplaintReasonResponse } from '../model/complaintReasonResponse';
// @ts-ignore
import { ComplaintRequest } from '../model/complaintRequest';
// @ts-ignore
import { ComplaintTicketResponse } from '../model/complaintTicketResponse';
// @ts-ignore
import { CreatePassword2FARequest } from '../model/createPassword2FARequest';
// @ts-ignore
import { DeletePassword2FARequest } from '../model/deletePassword2FARequest';
// @ts-ignore
import { Email } from '../model/email';
// @ts-ignore
import { EmailAddressMaskedResponse } from '../model/emailAddressMaskedResponse';
// @ts-ignore
import { EmailVerification } from '../model/emailVerification';
// @ts-ignore
import { EmailWithEmailMaskedResponse } from '../model/emailWithEmailMaskedResponse';
// @ts-ignore
import { PhoneNumberExistResponse } from '../model/phoneNumberExistResponse';
// @ts-ignore
import { PhoneNumberMaskedResponse } from '../model/phoneNumberMaskedResponse';
// @ts-ignore
import { PhoneNumberVerification } from '../model/phoneNumberVerification';
// @ts-ignore
import { PhoneNumberWithPhoneNumberMaskedResponse } from '../model/phoneNumberWithPhoneNumberMaskedResponse';
// @ts-ignore
import { PutUserPhoneNumber } from '../model/putUserPhoneNumber';
// @ts-ignore
import { RequestUserInfo } from '../model/requestUserInfo';
// @ts-ignore
import { SetPubkey2FARequest } from '../model/setPubkey2FARequest';
// @ts-ignore
import { SetPubkey2FAResponse } from '../model/setPubkey2FAResponse';
// @ts-ignore
import { UpdatePassword2FARequest } from '../model/updatePassword2FARequest';
// @ts-ignore
import { UserFollowersResponse } from '../model/userFollowersResponse';
// @ts-ignore
import { UserFollowingsResponse } from '../model/userFollowingsResponse';
// @ts-ignore
import { UserResponse } from '../model/userResponse';
// @ts-ignore
import { UserSearchResponse } from '../model/userSearchResponse';
// @ts-ignore
import { UserSortType } from '../model/userSortType';
// @ts-ignore
import { VerificationCode2FA } from '../model/verificationCode2FA';
// @ts-ignore
import { VerificationCode2FATransport } from '../model/verificationCode2FATransport';
// @ts-ignore
import { VerifyPassword2FARequest } from '../model/verifyPassword2FARequest';
// @ts-ignore
import { VerifyPassword2FAResponse } from '../model/verifyPassword2FAResponse';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class UsersService {

    protected basePath = 'https://api.talala.la/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Block User
     * @param userId 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public blockUserUsersUserIdBlockPost(userId: string, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<UserResponse>;
    public blockUserUsersUserIdBlockPost(userId: string, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<UserResponse>>;
    public blockUserUsersUserIdBlockPost(userId: string, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<UserResponse>>;
    public blockUserUsersUserIdBlockPost(userId: string, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling blockUserUsersUserIdBlockPost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<UserResponse>(`${this.configuration.basePath}/v2/users/${encodeURIComponent(String(userId))}/block`,
            null,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check User Names
     * @param userName 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkUserNamesUsersCheckUsernameGet(userName: string, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<CheckUserInfoResponse>;
    public checkUserNamesUsersCheckUsernameGet(userName: string, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<CheckUserInfoResponse>>;
    public checkUserNamesUsersCheckUsernameGet(userName: string, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<CheckUserInfoResponse>>;
    public checkUserNamesUsersCheckUsernameGet(userName: string, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (userName === null || userName === undefined) {
            throw new Error('Required parameter userName was null or undefined when calling checkUserNamesUsersCheckUsernameGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (userName !== undefined && userName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userName, 'user_name');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<CheckUserInfoResponse>(`${this.configuration.basePath}/v2/users/check_username`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete User 2Fa Password
     * @param deletePassword2FARequest 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUser2faPasswordUsers2faPasswordDelete(deletePassword2FARequest: DeletePassword2FARequest, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<BaseResponse>;
    public deleteUser2faPasswordUsers2faPasswordDelete(deletePassword2FARequest: DeletePassword2FARequest, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<BaseResponse>>;
    public deleteUser2faPasswordUsers2faPasswordDelete(deletePassword2FARequest: DeletePassword2FARequest, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<BaseResponse>>;
    public deleteUser2faPasswordUsers2faPasswordDelete(deletePassword2FARequest: DeletePassword2FARequest, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (deletePassword2FARequest === null || deletePassword2FARequest === undefined) {
            throw new Error('Required parameter deletePassword2FARequest was null or undefined when calling deleteUser2faPasswordUsers2faPasswordDelete.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.delete<BaseResponse>(`${this.configuration.basePath}/v2/users/2fa/password`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete User
     * @param userId 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUserUsersUserIdDelete(userId: string, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<BaseResponse>;
    public deleteUserUsersUserIdDelete(userId: string, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<BaseResponse>>;
    public deleteUserUsersUserIdDelete(userId: string, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<BaseResponse>>;
    public deleteUserUsersUserIdDelete(userId: string, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling deleteUserUsersUserIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.delete<BaseResponse>(`${this.configuration.basePath}/v2/users/${encodeURIComponent(String(userId))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Discover Users
     * @param pageSize 
     * @param pageToken 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public discoverUsersUsersDiscoverGet(pageSize?: number, pageToken?: string, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<UserSearchResponse>;
    public discoverUsersUsersDiscoverGet(pageSize?: number, pageToken?: string, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<UserSearchResponse>>;
    public discoverUsersUsersDiscoverGet(pageSize?: number, pageToken?: string, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<UserSearchResponse>>;
    public discoverUsersUsersDiscoverGet(pageSize?: number, pageToken?: string, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'page_size');
        }
        if (pageToken !== undefined && pageToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageToken, 'page_token');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<UserSearchResponse>(`${this.configuration.basePath}/v2/users/discover`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get User By Id
     * @param userId 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserByIdUsersUserIdGet(userId: string, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<UserResponse>;
    public getUserByIdUsersUserIdGet(userId: string, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<UserResponse>>;
    public getUserByIdUsersUserIdGet(userId: string, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<UserResponse>>;
    public getUserByIdUsersUserIdGet(userId: string, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getUserByIdUsersUserIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<UserResponse>(`${this.configuration.basePath}/v2/users/${encodeURIComponent(String(userId))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get User Complaint Reasons
     * @param acceptLanguage 
     * @param xNutsRequestId Request id
     * @param userAgent User-Agent header
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserComplaintReasons(acceptLanguage?: string, xNutsRequestId?: string, userAgent?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ComplaintReasonResponse>;
    public getUserComplaintReasons(acceptLanguage?: string, xNutsRequestId?: string, userAgent?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ComplaintReasonResponse>>;
    public getUserComplaintReasons(acceptLanguage?: string, xNutsRequestId?: string, userAgent?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ComplaintReasonResponse>>;
    public getUserComplaintReasons(acceptLanguage?: string, xNutsRequestId?: string, userAgent?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            localVarHeaders = localVarHeaders.set('accept-language', String(acceptLanguage));
        }
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }
        if (userAgent !== undefined && userAgent !== null) {
            localVarHeaders = localVarHeaders.set('User-Agent', String(userAgent));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<ComplaintReasonResponse>(`${this.configuration.basePath}/v2/settings/complaint-options/user`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get User Followers
     * @param userId 
     * @param pageSize 
     * @param pageToken 
     * @param sort 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserFollowersUsersUserIdFollowersGet(userId: string, pageSize?: number, pageToken?: string, sort?: UserSortType, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<UserFollowersResponse>;
    public getUserFollowersUsersUserIdFollowersGet(userId: string, pageSize?: number, pageToken?: string, sort?: UserSortType, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<UserFollowersResponse>>;
    public getUserFollowersUsersUserIdFollowersGet(userId: string, pageSize?: number, pageToken?: string, sort?: UserSortType, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<UserFollowersResponse>>;
    public getUserFollowersUsersUserIdFollowersGet(userId: string, pageSize?: number, pageToken?: string, sort?: UserSortType, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getUserFollowersUsersUserIdFollowersGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'page_size');
        }
        if (pageToken !== undefined && pageToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageToken, 'page_token');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<UserFollowersResponse>(`${this.configuration.basePath}/v2/users/${encodeURIComponent(String(userId))}/followers`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get User Followings
     * @param userId 
     * @param pageSize 
     * @param pageToken 
     * @param sort 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserFollowingsUsersUserIdFollowingsGet(userId: string, pageSize?: number, pageToken?: string, sort?: UserSortType, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<UserFollowingsResponse>;
    public getUserFollowingsUsersUserIdFollowingsGet(userId: string, pageSize?: number, pageToken?: string, sort?: UserSortType, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<UserFollowingsResponse>>;
    public getUserFollowingsUsersUserIdFollowingsGet(userId: string, pageSize?: number, pageToken?: string, sort?: UserSortType, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<UserFollowingsResponse>>;
    public getUserFollowingsUsersUserIdFollowingsGet(userId: string, pageSize?: number, pageToken?: string, sort?: UserSortType, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getUserFollowingsUsersUserIdFollowingsGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'page_size');
        }
        if (pageToken !== undefined && pageToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageToken, 'page_token');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<UserFollowingsResponse>(`${this.configuration.basePath}/v2/users/${encodeURIComponent(String(userId))}/followings`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get User Phone Number Exist
     * @param userId 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserPhoneNumberExistUsersUserIdPhoneExistGet(userId: string, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PhoneNumberExistResponse>;
    public getUserPhoneNumberExistUsersUserIdPhoneExistGet(userId: string, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PhoneNumberExistResponse>>;
    public getUserPhoneNumberExistUsersUserIdPhoneExistGet(userId: string, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PhoneNumberExistResponse>>;
    public getUserPhoneNumberExistUsersUserIdPhoneExistGet(userId: string, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getUserPhoneNumberExistUsersUserIdPhoneExistGet.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<PhoneNumberExistResponse>(`${this.configuration.basePath}/v2/users/${encodeURIComponent(String(userId))}/phone/exist`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Patch User By Id
     * @param userId 
     * @param requestUserInfo 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchUserByIdUsersUserIdPatch(userId: string, requestUserInfo: RequestUserInfo, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<UserResponse>;
    public patchUserByIdUsersUserIdPatch(userId: string, requestUserInfo: RequestUserInfo, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<UserResponse>>;
    public patchUserByIdUsersUserIdPatch(userId: string, requestUserInfo: RequestUserInfo, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<UserResponse>>;
    public patchUserByIdUsersUserIdPatch(userId: string, requestUserInfo: RequestUserInfo, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling patchUserByIdUsersUserIdPatch.');
        }
        if (requestUserInfo === null || requestUserInfo === undefined) {
            throw new Error('Required parameter requestUserInfo was null or undefined when calling patchUserByIdUsersUserIdPatch.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.patch<UserResponse>(`${this.configuration.basePath}/v2/users/${encodeURIComponent(String(userId))}`,
            requestUserInfo,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post User 2Fa Password
     * @param createPassword2FARequest 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postUser2faPasswordUsers2faPasswordPost(createPassword2FARequest: CreatePassword2FARequest, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<BaseResponse>;
    public postUser2faPasswordUsers2faPasswordPost(createPassword2FARequest: CreatePassword2FARequest, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<BaseResponse>>;
    public postUser2faPasswordUsers2faPasswordPost(createPassword2FARequest: CreatePassword2FARequest, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<BaseResponse>>;
    public postUser2faPasswordUsers2faPasswordPost(createPassword2FARequest: CreatePassword2FARequest, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (createPassword2FARequest === null || createPassword2FARequest === undefined) {
            throw new Error('Required parameter createPassword2FARequest was null or undefined when calling postUser2faPasswordUsers2faPasswordPost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<BaseResponse>(`${this.configuration.basePath}/v2/users/2fa/password`,
            createPassword2FARequest,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post User 2Fa Pincode Key
     * @param setPubkey2FARequest 
     * @param xNuts2FAId 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postUser2faPincodeKeyUsers2faPincodeKeyPost(setPubkey2FARequest: SetPubkey2FARequest, xNuts2FAId?: string, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SetPubkey2FAResponse>;
    public postUser2faPincodeKeyUsers2faPincodeKeyPost(setPubkey2FARequest: SetPubkey2FARequest, xNuts2FAId?: string, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SetPubkey2FAResponse>>;
    public postUser2faPincodeKeyUsers2faPincodeKeyPost(setPubkey2FARequest: SetPubkey2FARequest, xNuts2FAId?: string, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SetPubkey2FAResponse>>;
    public postUser2faPincodeKeyUsers2faPincodeKeyPost(setPubkey2FARequest: SetPubkey2FARequest, xNuts2FAId?: string, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (setPubkey2FARequest === null || setPubkey2FARequest === undefined) {
            throw new Error('Required parameter setPubkey2FARequest was null or undefined when calling postUser2faPincodeKeyUsers2faPincodeKeyPost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNuts2FAId !== undefined && xNuts2FAId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-2FA-Id', String(xNuts2FAId));
        }
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<SetPubkey2FAResponse>(`${this.configuration.basePath}/v2/users/2fa/pincode-key`,
            setPubkey2FARequest,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post User 2Fa Verify
     * @param verifyPassword2FARequest 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postUser2faVerifyUsers2faVerifyPost(verifyPassword2FARequest: VerifyPassword2FARequest, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<VerifyPassword2FAResponse>;
    public postUser2faVerifyUsers2faVerifyPost(verifyPassword2FARequest: VerifyPassword2FARequest, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<VerifyPassword2FAResponse>>;
    public postUser2faVerifyUsers2faVerifyPost(verifyPassword2FARequest: VerifyPassword2FARequest, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<VerifyPassword2FAResponse>>;
    public postUser2faVerifyUsers2faVerifyPost(verifyPassword2FARequest: VerifyPassword2FARequest, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (verifyPassword2FARequest === null || verifyPassword2FARequest === undefined) {
            throw new Error('Required parameter verifyPassword2FARequest was null or undefined when calling postUser2faVerifyUsers2faVerifyPost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<VerifyPassword2FAResponse>(`${this.configuration.basePath}/v2/users/2fa/verify`,
            verifyPassword2FARequest,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post User Complaint
     * @param userId 
     * @param complaintRequest 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postUserComplaintUsersUserIdComplaintPost(userId: string, complaintRequest: ComplaintRequest, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ComplaintTicketResponse>;
    public postUserComplaintUsersUserIdComplaintPost(userId: string, complaintRequest: ComplaintRequest, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ComplaintTicketResponse>>;
    public postUserComplaintUsersUserIdComplaintPost(userId: string, complaintRequest: ComplaintRequest, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ComplaintTicketResponse>>;
    public postUserComplaintUsersUserIdComplaintPost(userId: string, complaintRequest: ComplaintRequest, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling postUserComplaintUsersUserIdComplaintPost.');
        }
        if (complaintRequest === null || complaintRequest === undefined) {
            throw new Error('Required parameter complaintRequest was null or undefined when calling postUserComplaintUsersUserIdComplaintPost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<ComplaintTicketResponse>(`${this.configuration.basePath}/v2/users/${encodeURIComponent(String(userId))}/complaint`,
            complaintRequest,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Put User 2Fa Password
     * @param updatePassword2FARequest 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putUser2faPasswordUsers2faPasswordPut(updatePassword2FARequest: UpdatePassword2FARequest, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<BaseResponse>;
    public putUser2faPasswordUsers2faPasswordPut(updatePassword2FARequest: UpdatePassword2FARequest, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<BaseResponse>>;
    public putUser2faPasswordUsers2faPasswordPut(updatePassword2FARequest: UpdatePassword2FARequest, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<BaseResponse>>;
    public putUser2faPasswordUsers2faPasswordPut(updatePassword2FARequest: UpdatePassword2FARequest, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (updatePassword2FARequest === null || updatePassword2FARequest === undefined) {
            throw new Error('Required parameter updatePassword2FARequest was null or undefined when calling putUser2faPasswordUsers2faPasswordPut.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.put<BaseResponse>(`${this.configuration.basePath}/v2/users/2fa/password`,
            updatePassword2FARequest,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Put User Avatar And Background By Id
     * @param userId 
     * @param background 
     * @param avatar 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putUserAvatarAndBackgroundByIdUsersUserIdAvatarBackgroundPut(userId: string, background: Blob, avatar: Blob, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<UserResponse>;
    public putUserAvatarAndBackgroundByIdUsersUserIdAvatarBackgroundPut(userId: string, background: Blob, avatar: Blob, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<UserResponse>>;
    public putUserAvatarAndBackgroundByIdUsersUserIdAvatarBackgroundPut(userId: string, background: Blob, avatar: Blob, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<UserResponse>>;
    public putUserAvatarAndBackgroundByIdUsersUserIdAvatarBackgroundPut(userId: string, background: Blob, avatar: Blob, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling putUserAvatarAndBackgroundByIdUsersUserIdAvatarBackgroundPut.');
        }
        if (background === null || background === undefined) {
            throw new Error('Required parameter background was null or undefined when calling putUserAvatarAndBackgroundByIdUsersUserIdAvatarBackgroundPut.');
        }
        if (avatar === null || avatar === undefined) {
            throw new Error('Required parameter avatar was null or undefined when calling putUserAvatarAndBackgroundByIdUsersUserIdAvatarBackgroundPut.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (background !== undefined) {
            localVarFormParams = localVarFormParams.append('background', <any>background) as any || localVarFormParams;
        }
        if (avatar !== undefined) {
            localVarFormParams = localVarFormParams.append('avatar', <any>avatar) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.put<UserResponse>(`${this.configuration.basePath}/v2/users/${encodeURIComponent(String(userId))}/avatar_background`,
            localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Put User Avatar By Id
     * @param userId 
     * @param file 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putUserAvatarByIdUsersUserIdAvatarPut(userId: string, file: Blob, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<UserResponse>;
    public putUserAvatarByIdUsersUserIdAvatarPut(userId: string, file: Blob, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<UserResponse>>;
    public putUserAvatarByIdUsersUserIdAvatarPut(userId: string, file: Blob, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<UserResponse>>;
    public putUserAvatarByIdUsersUserIdAvatarPut(userId: string, file: Blob, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling putUserAvatarByIdUsersUserIdAvatarPut.');
        }
        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling putUserAvatarByIdUsersUserIdAvatarPut.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (file !== undefined) {
            localVarFormParams = localVarFormParams.append('file', <any>file) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.put<UserResponse>(`${this.configuration.basePath}/v2/users/${encodeURIComponent(String(userId))}/avatar`,
            localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Put User Email
     * @param userId 
     * @param email 
     * @param xNutsRequestId Request id
     * @param acceptLanguage 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putUserEmailUsersUserIdEmailPut(userId: string, email: Email, xNutsRequestId?: string, acceptLanguage?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<EmailWithEmailMaskedResponse>;
    public putUserEmailUsersUserIdEmailPut(userId: string, email: Email, xNutsRequestId?: string, acceptLanguage?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<EmailWithEmailMaskedResponse>>;
    public putUserEmailUsersUserIdEmailPut(userId: string, email: Email, xNutsRequestId?: string, acceptLanguage?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<EmailWithEmailMaskedResponse>>;
    public putUserEmailUsersUserIdEmailPut(userId: string, email: Email, xNutsRequestId?: string, acceptLanguage?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling putUserEmailUsersUserIdEmailPut.');
        }
        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling putUserEmailUsersUserIdEmailPut.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            localVarHeaders = localVarHeaders.set('accept-language', String(acceptLanguage));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.put<EmailWithEmailMaskedResponse>(`${this.configuration.basePath}/v2/users/${encodeURIComponent(String(userId))}/email`,
            email,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Put User Phone Number
     * @param userId 
     * @param putUserPhoneNumber 
     * @param xNutsRequestId Request id
     * @param acceptLanguage 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putUserPhoneNumberUsersUserIdPhonePut(userId: string, putUserPhoneNumber: PutUserPhoneNumber, xNutsRequestId?: string, acceptLanguage?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PhoneNumberWithPhoneNumberMaskedResponse>;
    public putUserPhoneNumberUsersUserIdPhonePut(userId: string, putUserPhoneNumber: PutUserPhoneNumber, xNutsRequestId?: string, acceptLanguage?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PhoneNumberWithPhoneNumberMaskedResponse>>;
    public putUserPhoneNumberUsersUserIdPhonePut(userId: string, putUserPhoneNumber: PutUserPhoneNumber, xNutsRequestId?: string, acceptLanguage?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PhoneNumberWithPhoneNumberMaskedResponse>>;
    public putUserPhoneNumberUsersUserIdPhonePut(userId: string, putUserPhoneNumber: PutUserPhoneNumber, xNutsRequestId?: string, acceptLanguage?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling putUserPhoneNumberUsersUserIdPhonePut.');
        }
        if (putUserPhoneNumber === null || putUserPhoneNumber === undefined) {
            throw new Error('Required parameter putUserPhoneNumber was null or undefined when calling putUserPhoneNumberUsersUserIdPhonePut.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            localVarHeaders = localVarHeaders.set('accept-language', String(acceptLanguage));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.put<PhoneNumberWithPhoneNumberMaskedResponse>(`${this.configuration.basePath}/v2/users/${encodeURIComponent(String(userId))}/phone`,
            putUserPhoneNumber,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Send Email Verification Code
     * @param acceptLanguage 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendEmailVerificationCodeUsers2faEmailPost(acceptLanguage?: string, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<EmailAddressMaskedResponse>;
    public sendEmailVerificationCodeUsers2faEmailPost(acceptLanguage?: string, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<EmailAddressMaskedResponse>>;
    public sendEmailVerificationCodeUsers2faEmailPost(acceptLanguage?: string, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<EmailAddressMaskedResponse>>;
    public sendEmailVerificationCodeUsers2faEmailPost(acceptLanguage?: string, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            localVarHeaders = localVarHeaders.set('accept-language', String(acceptLanguage));
        }
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<EmailAddressMaskedResponse>(`${this.configuration.basePath}/v2/users/2fa/email`,
            null,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Send Phone Verification Code
     * @param verificationCode2FATransport 
     * @param acceptLanguage 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendPhoneVerificationCodeUsers2faPhonePost(verificationCode2FATransport: VerificationCode2FATransport, acceptLanguage?: string, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PhoneNumberMaskedResponse>;
    public sendPhoneVerificationCodeUsers2faPhonePost(verificationCode2FATransport: VerificationCode2FATransport, acceptLanguage?: string, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PhoneNumberMaskedResponse>>;
    public sendPhoneVerificationCodeUsers2faPhonePost(verificationCode2FATransport: VerificationCode2FATransport, acceptLanguage?: string, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PhoneNumberMaskedResponse>>;
    public sendPhoneVerificationCodeUsers2faPhonePost(verificationCode2FATransport: VerificationCode2FATransport, acceptLanguage?: string, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (verificationCode2FATransport === null || verificationCode2FATransport === undefined) {
            throw new Error('Required parameter verificationCode2FATransport was null or undefined when calling sendPhoneVerificationCodeUsers2faPhonePost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            localVarHeaders = localVarHeaders.set('accept-language', String(acceptLanguage));
        }
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<PhoneNumberMaskedResponse>(`${this.configuration.basePath}/v2/users/2fa/phone`,
            verificationCode2FATransport,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Subscribe User
     * @param userId 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public subscribeUserUsersUserIdSubscribePost(userId: string, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<UserResponse>;
    public subscribeUserUsersUserIdSubscribePost(userId: string, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<UserResponse>>;
    public subscribeUserUsersUserIdSubscribePost(userId: string, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<UserResponse>>;
    public subscribeUserUsersUserIdSubscribePost(userId: string, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling subscribeUserUsersUserIdSubscribePost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<UserResponse>(`${this.configuration.basePath}/v2/users/${encodeURIComponent(String(userId))}/subscribe`,
            null,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Unblock User
     * @param userId 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unblockUserUsersUserIdUnblockPost(userId: string, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<UserResponse>;
    public unblockUserUsersUserIdUnblockPost(userId: string, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<UserResponse>>;
    public unblockUserUsersUserIdUnblockPost(userId: string, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<UserResponse>>;
    public unblockUserUsersUserIdUnblockPost(userId: string, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling unblockUserUsersUserIdUnblockPost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<UserResponse>(`${this.configuration.basePath}/v2/users/${encodeURIComponent(String(userId))}/unblock`,
            null,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Unsubscribe User
     * @param userId 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unsubscribeUserUsersUserIdUnsubscribePost(userId: string, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<UserResponse>;
    public unsubscribeUserUsersUserIdUnsubscribePost(userId: string, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<UserResponse>>;
    public unsubscribeUserUsersUserIdUnsubscribePost(userId: string, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<UserResponse>>;
    public unsubscribeUserUsersUserIdUnsubscribePost(userId: string, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling unsubscribeUserUsersUserIdUnsubscribePost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<UserResponse>(`${this.configuration.basePath}/v2/users/${encodeURIComponent(String(userId))}/unsubscribe`,
            null,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User Interests
     * @param categoriesList 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userInterestsUsersInterestsPost(categoriesList: CategoriesList, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<BaseResponse>;
    public userInterestsUsersInterestsPost(categoriesList: CategoriesList, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<BaseResponse>>;
    public userInterestsUsersInterestsPost(categoriesList: CategoriesList, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<BaseResponse>>;
    public userInterestsUsersInterestsPost(categoriesList: CategoriesList, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (categoriesList === null || categoriesList === undefined) {
            throw new Error('Required parameter categoriesList was null or undefined when calling userInterestsUsersInterestsPost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<BaseResponse>(`${this.configuration.basePath}/v2/users/interests`,
            categoriesList,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User Search
     * @param query 
     * @param pageSize 
     * @param pageToken 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userSearchUsersSearchGet(query: string, pageSize?: number, pageToken?: string, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<UserSearchResponse>;
    public userSearchUsersSearchGet(query: string, pageSize?: number, pageToken?: string, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<UserSearchResponse>>;
    public userSearchUsersSearchGet(query: string, pageSize?: number, pageToken?: string, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<UserSearchResponse>>;
    public userSearchUsersSearchGet(query: string, pageSize?: number, pageToken?: string, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling userSearchUsersSearchGet.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'page_size');
        }
        if (pageToken !== undefined && pageToken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageToken, 'page_token');
        }
        if (query !== undefined && query !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>query, 'query');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<UserSearchResponse>(`${this.configuration.basePath}/v2/users/search`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Verify Code Via Email
     * @param userId 
     * @param emailVerification 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verifyCodeViaEmailUsersUserIdEmailVerifyPost(userId: string, emailVerification: EmailVerification, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<EmailWithEmailMaskedResponse>;
    public verifyCodeViaEmailUsersUserIdEmailVerifyPost(userId: string, emailVerification: EmailVerification, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<EmailWithEmailMaskedResponse>>;
    public verifyCodeViaEmailUsersUserIdEmailVerifyPost(userId: string, emailVerification: EmailVerification, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<EmailWithEmailMaskedResponse>>;
    public verifyCodeViaEmailUsersUserIdEmailVerifyPost(userId: string, emailVerification: EmailVerification, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling verifyCodeViaEmailUsersUserIdEmailVerifyPost.');
        }
        if (emailVerification === null || emailVerification === undefined) {
            throw new Error('Required parameter emailVerification was null or undefined when calling verifyCodeViaEmailUsersUserIdEmailVerifyPost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<EmailWithEmailMaskedResponse>(`${this.configuration.basePath}/v2/users/${encodeURIComponent(String(userId))}/email/verify`,
            emailVerification,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Verify Code Via Phone Number
     * @param userId 
     * @param phoneNumberVerification 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verifyCodeViaPhoneNumberUsersUserIdPhoneVerifyPost(userId: string, phoneNumberVerification: PhoneNumberVerification, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PhoneNumberWithPhoneNumberMaskedResponse>;
    public verifyCodeViaPhoneNumberUsersUserIdPhoneVerifyPost(userId: string, phoneNumberVerification: PhoneNumberVerification, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PhoneNumberWithPhoneNumberMaskedResponse>>;
    public verifyCodeViaPhoneNumberUsersUserIdPhoneVerifyPost(userId: string, phoneNumberVerification: PhoneNumberVerification, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PhoneNumberWithPhoneNumberMaskedResponse>>;
    public verifyCodeViaPhoneNumberUsersUserIdPhoneVerifyPost(userId: string, phoneNumberVerification: PhoneNumberVerification, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling verifyCodeViaPhoneNumberUsersUserIdPhoneVerifyPost.');
        }
        if (phoneNumberVerification === null || phoneNumberVerification === undefined) {
            throw new Error('Required parameter phoneNumberVerification was null or undefined when calling verifyCodeViaPhoneNumberUsersUserIdPhoneVerifyPost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<PhoneNumberWithPhoneNumberMaskedResponse>(`${this.configuration.basePath}/v2/users/${encodeURIComponent(String(userId))}/phone/verify`,
            phoneNumberVerification,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Verify Email
     * @param verificationCode2FA 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verifyEmailUsers2faEmailVerifyPost(verificationCode2FA: VerificationCode2FA, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<BaseResponse>;
    public verifyEmailUsers2faEmailVerifyPost(verificationCode2FA: VerificationCode2FA, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<BaseResponse>>;
    public verifyEmailUsers2faEmailVerifyPost(verificationCode2FA: VerificationCode2FA, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<BaseResponse>>;
    public verifyEmailUsers2faEmailVerifyPost(verificationCode2FA: VerificationCode2FA, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (verificationCode2FA === null || verificationCode2FA === undefined) {
            throw new Error('Required parameter verificationCode2FA was null or undefined when calling verifyEmailUsers2faEmailVerifyPost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<BaseResponse>(`${this.configuration.basePath}/v2/users/2fa/email/verify`,
            verificationCode2FA,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Verify Phone
     * @param verificationCode2FA 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verifyPhoneUsers2faPhoneVerifyPost(verificationCode2FA: VerificationCode2FA, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<BaseResponse>;
    public verifyPhoneUsers2faPhoneVerifyPost(verificationCode2FA: VerificationCode2FA, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<BaseResponse>>;
    public verifyPhoneUsers2faPhoneVerifyPost(verificationCode2FA: VerificationCode2FA, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<BaseResponse>>;
    public verifyPhoneUsers2faPhoneVerifyPost(verificationCode2FA: VerificationCode2FA, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (verificationCode2FA === null || verificationCode2FA === undefined) {
            throw new Error('Required parameter verificationCode2FA was null or undefined when calling verifyPhoneUsers2faPhoneVerifyPost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<BaseResponse>(`${this.configuration.basePath}/v2/users/2fa/phone/verify`,
            verificationCode2FA,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
