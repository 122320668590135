import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { OpenGetAppDirective } from '@shared/directives';
import { AnalyticsEvents } from '@core/models/analytics.model';
import { AnalyticsDirective } from '@shared/directives/analytics.directive';

@Component({
  selector: 'cheelee-donation',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    OpenGetAppDirective,
    AnalyticsDirective,
  ],
  templateUrl: './donation.component.html',
  styleUrl: './donation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DonationComponent {

  public control = new FormControl<number>(0);

  public donateItems = [
    { amount: 0.1, currency: 'LEE', icon: '🔥' },
    { amount: 0.5, currency: 'LEE', icon: '❤️' },
    { amount: 1, currency: 'LEE', icon: '✨' },
    { amount: 5, currency: 'LEE', icon: '🥰' },
    { amount: 10, currency: 'LEE', icon: '😍' },
  ];

  protected readonly analyticsEvents = AnalyticsEvents;
}
