<svg
  class="spinner"
  [attr.height]="size + 'px'"
  [attr.width]="size + 'px'"
  viewBox="0 0 66 66"
  xmlns="http://www.w3.org/2000/svg"
>
  <circle
    class="path"
    cx="33"
    cy="33"
    fill="none"
    r="30"
    stroke-linecap="round"
    stroke-width="6"
  ></circle>
</svg>
