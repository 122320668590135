import { interval, Observable, OperatorFunction, tap } from 'rxjs';
import { debounce, filter } from 'rxjs/operators';

export const logger = <T>(message = ''): OperatorFunction<T, T> => {
  // eslint-disable-next-line no-console
  console.error('REMOVE LOG OPERATOR');
  // eslint-disable-next-line no-console
  return tap((e) => console.log(message, typeof e, typeof e === 'string' && e === '' ? 'empty_string' : e));
};

export const debug = <T>(): OperatorFunction<T, T> => {
  // eslint-disable-next-line no-console
  console.error('REMOVE DEBUG OPERATOR');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return tap((value) => {
    // eslint-disable-next-line no-debugger
    debugger;
  });
};

export const filterNil =
  <T>() =>
    (source$: Observable<T>): Observable<NonNullable<T>> =>
      source$.pipe(
        filter<T, NonNullable<T>>((value: T): value is NonNullable<T> => value !== null && value !== undefined),
      );

export const debounceLoader =
  <T>() =>
    (source$: Observable<T>): Observable<T> =>
      source$.pipe(debounce<T>((value) => interval(+!value * 500)));
