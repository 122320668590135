import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { browserTracingIntegration, init, replayIntegration } from '@sentry/angular';



import { AppModule } from '@app/app.module';
import { environment } from '@environments/environment';

init({
  dsn: environment.sentryClientDsn,
  environment: environment.name,
  release: environment.app_version,
  integrations: [
    browserTracingIntegration(),
    replayIntegration(),
  ],
  tracePropagationTargets: ['localhost'],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

if (environment.production) {
  enableProdMode();
}

// eslint-disable-next-line space-before-function-paren
(function (): void {
  const translations: { [key: string]: { [key2: string]: string } } = {
    'ru-RU': {
      line1: 'Переходи в Cheelee',
      line2: 'Смотри видео<br>и зарабатывай',
      line3: 'Отсканируй QR-код на своем<br>смартфоне и скачивай приложение',
    },
    'en-US': {
      line1: 'Go to Cheelee',
      line2: 'Watch videos<br>and earn',
      line3: 'Scan the QR code on your<br>smartphone and download the app',
    },
  };

  // Чтение языка из Local Storage
  const language = localStorage.getItem('i18nextLng') || 'en-US';

  // Получение элементов для отображения текста
  const line1Div = document.getElementById('line1');
  const line2Div = document.getElementById('line2');
  const line3Div = document.getElementById('line3');

  if (!line1Div || !line2Div || !line3Div) {
    return;
  }

  // Установка текста на соответствующем языке
  if (translations[language]) {
    line1Div.innerHTML = translations[language]['line1'];
    line2Div.innerHTML = translations[language]['line2'];
    line3Div.innerHTML = translations[language]['line3'];
  } else {
    // Если язык не найден, используется английский
    line1Div.innerHTML = translations['en-US']['line1'];
    line2Div.innerHTML = translations['en-US']['line2'];
    line3Div.innerHTML = translations['en-US']['line3'];
  }
})();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));
