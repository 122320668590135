import { createSelector } from '@ngrx/store';

import { Breakpoints } from '@data/const';
import { layoutFeature } from './layout.reducer';

export const { selectBreakpoint } = layoutFeature;

const selectIsMobile = createSelector(selectBreakpoint, (breakpoint) => breakpoint === Breakpoints.Mobile || breakpoint === Breakpoints.Tablet);

const selectIsDesktop = createSelector(selectBreakpoint, (breakpoint) => breakpoint === Breakpoints.Desktop);

export const layoutSelectors = {
  selectIsMobile,
  selectIsDesktop,
};
