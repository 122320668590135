import { inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { layoutSelectors } from '@store/layout';

@Injectable({ providedIn: 'root' })
export class BreakpointFacade {

  private readonly store = inject(Store);

  public readonly isMobile = this.store.selectSignal(layoutSelectors.selectIsMobile);

  public readonly isDesktop = this.store.selectSignal(layoutSelectors.selectIsDesktop);

  public readonly isDesktop$ = this.store.select(layoutSelectors.selectIsDesktop);

}
