import {
  AfterViewInit,
  booleanAttribute,
  DestroyRef,
  Directive,
  ElementRef,
  Input,
  numberAttribute,
} from '@angular/core';

import { PlatformService } from '@core/services';

@Directive({
  standalone: true,
  selector: '[cheeleeRepeatAnimation]',
})
export class RepeatAnimationDirective implements AfterViewInit {
  @Input({ transform: numberAttribute })
  public repeatAnimationAfter = 1000; // seconds

  @Input({ transform: booleanAttribute })
  public needPlayAnimationImmediately = false;

  private timer: number | null = null;

  private animationStyle?: string;

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly platformService: PlatformService,
    private readonly destroyRef: DestroyRef,
  ) {}

  public ngAfterViewInit(): void {
    if (!this.platformService.isBrowser) {
      return;
    }

    setTimeout(() => this.init());
  }

  public repeatAnimation(): void {
    this.resetAnimation();
    this.timer = window.setTimeout(() => {
      this.playAnimation();
      this.elementRef.nativeElement.onanimationend = () => this.repeatAnimation();
    }, this.repeatAnimationAfter);
  }

  private init(): void {
    const { nativeElement: element } = this.elementRef;
    this.animationStyle = window.getComputedStyle(element).animation;

    if (!this.animationStyle) {
      return;
    }

    if (this.needPlayAnimationImmediately) {
      this.elementRef.nativeElement.onanimationend = () => this.repeatAnimation();
    } else {
      this.repeatAnimation();
    }

    this.destroyRef.onDestroy(() => {
      if (this.timer) {
        window.clearTimeout(this.timer);
        this.timer = null;
      }
    });
  }

  private resetAnimation(): void {
    this.elementRef.nativeElement.style.animation = 'none';
  }

  private playAnimation(): void {
    if (this.animationStyle) {
      this.elementRef.nativeElement.style.animation = this.animationStyle;
    }
  }
}
