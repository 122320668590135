<ng-container *ngIf="useSprite; else objectSvg">
  <svg
    class="svg-icon"
    [attr.color]="color"
    [attr.fill]="color"
    [attr.height]="height"
    [attr.width]="width"
  >
    <use [attr.href]="defaultPath + fullPath"></use>
  </svg>
</ng-container>

<ng-template #objectSvg>
  <object
    class="object-icon"
    type="image/svg+xml"
    [attr.height]="height"
    [attr.width]="width"
    [data]="defaultPath + fullPath | safeUrl"
  ></object>
</ng-template>
