import { NgModule } from '@angular/core';
import { ResponsiveLayoutComponent } from './responsive-layout/responsive-layout.component';


@NgModule({
  imports: [
    ResponsiveLayoutComponent,
  ],
  exports: [
    ResponsiveLayoutComponent,
  ],
})
export class LayoutsModule { }
