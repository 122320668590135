import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  Input,
  signal,
  ViewChild,
} from '@angular/core';

import { I18NextModule } from 'angular-i18next';

import { isTextEllipsisActive } from '@core/helpers';
import { RippleDirective } from '@shared/directives';
import { MentionLinkPipe } from '@shared/pipes';

@Component({
  selector: 'cheelee-show-more-text',
  standalone: true,
  imports: [ CommonModule, I18NextModule, MentionLinkPipe, RippleDirective ],
  templateUrl: './show-more-text.component.html',
  styleUrls: [ './show-more-text.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowMoreTextComponent implements AfterViewInit {
  @Input({ required: true })
  public text!: string;

  @Input()
  public mode: 'user' | 'media' = 'media';

  @ViewChild('textContent')
  private readonly textContent!: ElementRef<HTMLSpanElement>;

  public canShowFullText = signal(true);

  public isFullText = signal(false);

  public canShowButton = signal(false);

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly destroyRef: DestroyRef,
  ) {
  }

  public ngAfterViewInit(): void {
    setTimeout(() => this.checkTextWidth());

    const resizeObserver = new ResizeObserver(() => {
      if (this.canShowButton()) {
        return;
      }

      this.checkTextWidth();
    });

    resizeObserver.observe(this.textContent.nativeElement);
    this.destroyRef.onDestroy(() => resizeObserver.disconnect());
  }

  public toggle(): void {
    if (!this.canShowButton()) {
      return;
    }

    this.canShowFullText.set(!this.canShowFullText());

    if (!this.canShowFullText()) {
      this.isFullText.set(true);
    }
  }

  public onHeightTransitionEnd(): void {
    if (this.canShowFullText()) {
      this.isFullText.set(false);
    }
  }

  private checkTextWidth(): void {
    const canShowBtn = isTextEllipsisActive(this.textContent.nativeElement);
    this.canShowButton.set(canShowBtn);
    this.cdr.detectChanges();
  }
}
