/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 */
export type ChallengeState = 'author_payment_pending' | 'author_payment_failed' | 'pending' | 'created' | 'started' | 'acceptance_is_complete' | 'voting_is_complete' | 'waiting_for_approval' | 'winner_payment_pending' | 'winner_payment_failed' | 'finished' | 'cancelled' | 'declined' | 'author_refund_pending' | 'author_refund_failed';

export const ChallengeState = {
    AuthorPaymentPending: 'author_payment_pending' as ChallengeState,
    AuthorPaymentFailed: 'author_payment_failed' as ChallengeState,
    Pending: 'pending' as ChallengeState,
    Created: 'created' as ChallengeState,
    Started: 'started' as ChallengeState,
    AcceptanceIsComplete: 'acceptance_is_complete' as ChallengeState,
    VotingIsComplete: 'voting_is_complete' as ChallengeState,
    WaitingForApproval: 'waiting_for_approval' as ChallengeState,
    WinnerPaymentPending: 'winner_payment_pending' as ChallengeState,
    WinnerPaymentFailed: 'winner_payment_failed' as ChallengeState,
    Finished: 'finished' as ChallengeState,
    Cancelled: 'cancelled' as ChallengeState,
    Declined: 'declined' as ChallengeState,
    AuthorRefundPending: 'author_refund_pending' as ChallengeState,
    AuthorRefundFailed: 'author_refund_failed' as ChallengeState
};

