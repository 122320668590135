/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Subscriptions1 } from './subscriptions1';
import { SocialLinks } from './socialLinks';
import { UserCounters } from './userCounters';


export interface User { 
    user_name: string;
    person_name?: string;
    user_bio?: string;
    user_id: string;
    user_avatar_url?: string;
    counters: UserCounters;
    subscriptions?: Subscriptions1;
    is_blocked?: boolean;
    is_blockable?: boolean;
    is_reportable?: boolean;
    is_identity_confirmed?: boolean;
    is_followable?: boolean;
    is_deleted?: boolean;
    gender: string;
    birthday_date: number;
    user_background_url?: string;
    social_links: Array<SocialLinks>;
}

