import { APP_INITIALIZER, LOCALE_ID, Provider } from '@angular/core';

import {
  defaultInterpolationFormat,
  I18NEXT_NAMESPACE,
  I18NEXT_SERVICE,
  I18NextLoadResult,
  I18NextModule,
  ITranslationService,
} from 'angular-i18next';
import { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import 'dayjs/locale/ru';

import { LanguageLocales, resources } from '@core/i18n/locales';
import { environment } from '@environments/environment';

// eslint-disable-next-line import/order
import dayjs from 'dayjs';

export const DEFAULT_LANGUAGE = LanguageLocales.EN;

const i18nextOptions: InitOptions = {
  // lng: 'ru',
  // we init with resources
  resources,
  fallbackLng: {
    'en-US': [LanguageLocales.EN],
    'ru-RU': [LanguageLocales.RU],
    default: [DEFAULT_LANGUAGE],
  },
  supportedLngs: Object.values(LanguageLocales),
  returnEmptyString: false,
  debug: !environment.production,

  // have a common namespace used around the full app
  defaultNS: 'translations',
  load: 'currentOnly',
  keySeparator: '.', // we use content as keys
  nsSeparator: ':',
  interpolation: {
    format: I18NextModule.interpolationFormat(defaultInterpolationFormat),
  },

  // lang detection plugin options
  detection: {
    // order and from where user language should be detected
    order: ['localStorage', 'querystring', 'htmlTag', 'navigator'],
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,
    caches: ['localStorage'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
    cookieMinutes: 0,
    cookieDomain: '/',
  },
};

function setupDayJsLocale(i18next: ITranslationService): void {
  switch (i18next.language) {
    case LanguageLocales.RU:
      dayjs.locale('ru');
      break;

    // English by default
    default:
      break;
  }
}

//  Инициализация i18next
export function appInit(i18next: ITranslationService): () => Promise<I18NextLoadResult | void> {
  return () =>
    i18next.isInitialized
      ? Promise.resolve()
      : i18next
        .use(LanguageDetector)
        .init(i18nextOptions)
        .then(() => {
          setupDayJsLocale(i18next);
        });
}

export function localeIdFactory(i18next: ITranslationService): string {
  return i18next.language;
}

export const I18N_PROVIDERS: Provider[] = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE],
    multi: true,
  },
  {
    provide: I18NEXT_NAMESPACE,
    useValue: ['translations', 'validationErrors'],
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory,
  },
];
