import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { fromEvent, map, merge } from 'rxjs';

import { PlatformService, SnackbarService } from '@core/services';

@Injectable({ providedIn: 'root' })
export class InternetConnectionService {
  private isMonitoring = false;

  constructor(
    private readonly snackbarService: SnackbarService,
    private readonly platformService: PlatformService,
    private readonly destroyRef: DestroyRef,
  ) {}

  public startMonitoring(): void {
    if (this.platformService.isServer()) {
      return;
    }

    if (this.isMonitoring) {
      throw new Error('The InternetConnectionService is already monitoring the Internet connection');
    }

    this.isMonitoring = true;

    merge(fromEvent(window, 'online'), fromEvent(window, 'offline'))
      .pipe(
        map((event) => event.type === 'online'),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((isOnline) => {
        this.snackbarService.show(
          isOnline ? 'common:connection restored' : 'common:no connection',
          isOnline ? 'success' : 'error',
        );
      });
  }
}
