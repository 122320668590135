import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { map, of, switchMap } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';

import { ErrorResponse, RouterPathParams } from '@core/models';
import { MediaRepository, UsersRepository } from '@core/repositories';
import { EMPTY_STRING } from '@data/const';
import { routerSelectors } from '@store/router';
import { sessionActions, sessionSelectors } from '@store/session';

import { userActions } from './user.actions';
import { userSelectors } from './user.selectors';
import { MediaPageResponse, UserFeedType } from '@api/backend';

@Injectable({ providedIn: 'root' })
export class UserEffects {

  constructor(
    private store: Store,
    private actions: Actions,
    private usersRepository: UsersRepository,
    private mediaRepository: MediaRepository,
  ) {}

  public getSnippet$ = createEffect(() => {
    return this.actions.pipe(
      ofType(userActions.getSnippet),
      concatLatestFrom(() => this.store.select(routerSelectors.selectRouterParam(RouterPathParams.UserId))),
      filter(([, user_id]) => !!user_id),
      switchMap(([, user_id]: [unknown, string]) =>
        this.usersRepository.getUsersSnippet(user_id).pipe(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          map((snippet) => userActions.getSnippetSuccess({ snippet: snippet.data })),
          catchError((error: unknown) => of(userActions.getSnippetFailure({ error: error as ErrorResponse }))),
        ),
      ),
    );
  },
  );

  public getUser$ = createEffect(() => {
    return this.actions.pipe(
      ofType(userActions.getUser, sessionActions.getAuthTokenSuccess),
      concatLatestFrom(() => [
        this.store.select(userSelectors.selectUserData),
        this.store.select(sessionSelectors.selectSessionToken),
      ]),
      filter(([, user, token]) => !user && !!token.user_id),
      switchMap(([, , data]) =>
        this.usersRepository.getUserById(data?.user_id ?? EMPTY_STRING).pipe(
          map((userData) => userActions.getUserSuccess({ user: userData.data })),
          catchError((error: unknown) => of(userActions.getUserFailure({ error: error as ErrorResponse }))),
        ),
      ),
    );
  },
  );

  public getBrowsingUser$ = createEffect(() => {
    return this.actions.pipe(
      ofType(userActions.getBrowsingUser),
      concatLatestFrom(() => [this.store.select(routerSelectors.selectRouterParam(RouterPathParams.UserId))]),
      filter(([, user_id]) => !!user_id),
      switchMap(([, user_id]) =>
        this.usersRepository.getUserById(String(user_id)).pipe(
          map((response) => userActions.getBrowsingUserSuccess({ browsingUser: response.data })),
          catchError((error: unknown) => of(userActions.getBrowsingUserFailure({ error: error as ErrorResponse }))),
        ),
      ),
    );
  },
  );

  public loadFeedMediaInit$ = createEffect(() => {
    return this.actions.pipe(
      ofType(userActions.loadFeedMedia),
      concatLatestFrom(() => this.store.select(userSelectors.selectUserMediaNextPageToken)),
      map(([ { isNextPart, isNavigateNext }, page_token ]) =>
        page_token === null
          ? userActions.loadFeedMediaComplete()
          : userActions.loadFeedMediaInit({ page_token, isNextPart, isNavigateNext }),
      ),
    );
  });

  public loadFeedMedia$ = createEffect(() => {
    return this.actions.pipe(
      ofType(userActions.loadFeedMediaInit),
      concatLatestFrom(() => this.store.select(routerSelectors.selectRouterParam(RouterPathParams.UserId))),
      filter(([ , user_id]) => !!user_id),
      switchMap(([ { isNextPart, isNavigateNext, page_token }, user_id ]) =>
        this.mediaRepository.getUserMedia(user_id, UserFeedType.All, page_token ?? undefined)
          .pipe(
            map((media: MediaPageResponse) =>
              userActions.loadFeedMediaSuccess({ page: {
                ...media.data,
                media: [
                  ...media.data.media,
                  // ...media.data.media.map(it => ({ ...it, media_id: it.media_id + '1' })),
                  // ...media.data.media.map(it => ({ ...it, media_id: it.media_id + '2' })),
                ],
              }, isNextPart, isNavigateNext }),
            ),
            catchError((error: unknown) => of(userActions.loadFeedMediaFailure({ error: error as ErrorResponse }))),
          ),
      ),
    );
  });

}
