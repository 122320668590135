/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Subscriptions1 } from './subscriptions1';
import { User } from './user';
import { Amount } from './amount';
import { DurationResponse } from './durationResponse';
import { ChallengeCounters } from './challengeCounters';
import { ModerationStatus } from './moderationStatus';
import { ChallengeState } from './challengeState';
import { Location } from './location';


export interface Challenge { 
    challenge_id: string;
    challenge_title: string;
    challenge_description?: string;
    categories: Array<string>;
    location?: Location;
    duration: DurationResponse;
    location_description?: string;
    author: User;
    created_at: number;
    counters: ChallengeCounters;
    required_likes_count: number;
    max_show_count?: number;
    card_id?: string;
    reward?: Amount;
    is_accepted?: boolean;
    subscriptions?: Subscriptions1;
    is_followable?: boolean;
    is_reportable?: boolean;
    is_acceptable?: boolean;
    is_liked?: boolean;
    is_disliked?: boolean;
    is_deleted?: boolean;
    deleted_at?: number;
    challenge_state?: ChallengeState | null;
    moderation_status?: ModerationStatus | null;
    is_blocked_by_moderator?: boolean;
    is_deletable?: boolean;
    is_challenge_winner?: boolean;
    is_paid_through_support?: boolean;
}

