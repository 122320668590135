import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import {
  AuthService,
  AvailableAuthResponse,
  BaseResponse,
  OAuthLoginResponse,
  ScryptChallengeResponse,
} from '@api/backend';
import {
  SessionBodyRequest,
  SessionEmailBody,
  SessionEmailData,
  SessionEmailVerifyBody,
  SessionPhoneBody,
  SessionPhoneData,
  SessionPhoneVerifyBody,
  SessionTokenData,
  SessionUpdateBodyRequest,
} from '@core/models';
import { AuthRepository } from '@core/repositories';

@Injectable()
export class AuthWebRepository extends AuthRepository {
  constructor(private authService: AuthService) {
    super();
  }

  deleteAuthSession(): Observable<BaseResponse> {
    return this.authService.logoutAuthSessionDelete();
  }

  getAuthAvailable(): Observable<AvailableAuthResponse> {
    return this.authService.availableAuthAuthAvailableGet();
  }

  getAuthChallenge(): Observable<ScryptChallengeResponse> {
    return this.authService.getScryptChallengeAuthChallengeGet();
  }

  getAuthOAuthAppleCallback(code: string, id_2fa?: string): Observable<SessionTokenData> {
    return this.authService.appleCallbackAuthOauthAppleCallbackGet(code, id_2fa);
  }

  getAuthOAuthAppleLogin(): Observable<OAuthLoginResponse> {
    return this.authService.appleAuthorizationAuthOauthAppleLoginGet();
  }

  getAuthOAuthFacebookCallback(code: string, id_2fa?: string): Observable<SessionTokenData> {
    return this.authService.facebookCallbackAuthOauthFacebookCallbackGet(code, id_2fa);
  }

  getAuthOAuthFacebookLogin(): Observable<OAuthLoginResponse> {
    return this.authService.facebookAuthorizationAuthOauthFacebookLoginGet();
  }

  getAuthOAuthGoogleCallback(code: string, id_2fa?: string): Observable<SessionTokenData> {
    return this.authService.googleCallbackAuthOauthGoogleCallbackGet(code, id_2fa);
  }

  getAuthOAuthGoogleLogin(): Observable<OAuthLoginResponse> {
    return this.authService.googleAuthorizationAuthOauthGoogleLoginGet();
  }

  getAuthOAuthVkCallback(code: string, id_2fa?: string): Observable<SessionTokenData> {
    return this.authService.vkCallbackAuthOauthVkCallbackGet(code, id_2fa);
  }

  getAuthOAuthVkLogin(): Observable<OAuthLoginResponse> {
    return this.authService.vkAuthorizationAuthOauthVkLoginGet();
  }

  postAuthEmail(body: SessionEmailBody): Observable<SessionEmailData> {
    return this.authService.emailAuthAuthEmailPost(body);
  }

  postAuthEmailVerify(body: SessionEmailVerifyBody, id_2fa?: string): Observable<SessionTokenData> {
    return this.authService.verifyCodeAuthEmailVerifyPost(body, id_2fa);
  }

  postAuthPhone(body: SessionPhoneBody): Observable<SessionPhoneData> {
    return this.authService.phoneAuthAuthPhonePost(body);
  }

  postAuthPhoneVerify(body: SessionPhoneVerifyBody, id_2fa?: string): Observable<SessionTokenData> {
    return this.authService.verifyCodeAuthPhoneVerifyPost(body, id_2fa);
  }

  postAuthSession(body: SessionBodyRequest): Observable<SessionTokenData> {
    return this.authService.newSessionAuthSessionPost(body);
  }

  postAuthSessionToken(): Observable<SessionTokenData> {
    return this.authService.refreshAccessTokenAuthSessionTokenPost();
  }

  putAuthSessionDevice(body: SessionUpdateBodyRequest): Observable<BaseResponse> {
    return this.authService.updateDeviceInfoAuthSessionDevicePut(body);
  }
}
