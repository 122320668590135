import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Snippet } from '@api/backend';
import { mediaActions, mediaSelectors } from '@store/media';

@Injectable()
export class MediaItemFacade {
  public readonly mediaItem = this.store.selectSignal(mediaSelectors.selectMedia);

  public readonly mediaIsLoading = this.store.selectSignal(mediaSelectors.selectMediaIsLoading);

  constructor(private store: Store) {}

  public getSnippet(media_id: string): Observable<Snippet | undefined> {
    this.store.dispatch(mediaActions.getSnippet({ media_id }));
    return this.store.select(mediaSelectors.selectSnippet);
  }

  public loadMediaItem(media_id: string): void {
    this.store.dispatch(mediaActions.loadMedia({ media_id }));
  }

  public clearMediaItem(): void {
    this.store.dispatch(mediaActions.clearMedia());
  }

}
