import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, inject, Renderer2 } from '@angular/core';

import { Toast } from 'ngx-toastr';

import { I18NextString, InfoBlockVariant, SnackbarPayload } from '@core/models';
import { InfoBlockComponent } from '@shared/components';

@Component({
  selector: 'cheelee-snackbar',
  standalone: true,
  imports: [InfoBlockComponent],
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackbarComponent extends Toast implements AfterViewInit {
  private renderer = inject(Renderer2);

  private hostRef = inject(ElementRef);

  public get messageText(): I18NextString {
    return this.message as I18NextString;
  }

  public get actionFn(): (() => void) | undefined {
    return (this.options?.payload as SnackbarPayload)?.action;
  }

  public get variant(): InfoBlockVariant {
    return (this.options.payload as SnackbarPayload).variant ?? 'success';
  }

  public get actionBtnText(): I18NextString {
    return (this.options.payload as SnackbarPayload).actionBtnText as I18NextString;
  }

  public ngAfterViewInit(): void {
    setTimeout(() => this.animateSnackbar(), 50);
    const unlistenFn = this.renderer.listen(this.hostRef.nativeElement, 'click', () => {
      this.animateSnackbar(true);
      unlistenFn();
    });
  }

  private animateSnackbar(isClosed = false): void {
    this.renderer[isClosed ? 'removeClass' : 'addClass'](this.hostRef.nativeElement, 'active');
  }
}
