import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Injector, LOCALE_ID } from '@angular/core';

import { JwtHelperService } from '@auth0/angular-jwt';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, zip } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Interceptor } from '@core/interceptors/interceptor';
import { GetSessionActionTypes } from '@core/models';
import { FingerprintService } from '@core/services';
import { sessionSelectors } from '@store/session';

@Injectable()
export class JwtInterceptor extends Interceptor implements HttpInterceptor {
  constructor(
    protected override store: Store,
    protected override actions: Actions,
    protected override injector: Injector,
    protected override fingerprintService: FingerprintService,
    private jwtHelper: JwtHelperService,
    @Inject(LOCALE_ID)
    protected override locale: string,
  ) {
    super(store, actions, injector, fingerprintService, locale);
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isDeleteMethod = request.method === 'DELETE';
    const isNotMatchSession = !request.url.match('auth/session');
    const isNotMatchAmazon = !request.url.match('amazon');
    const isResolvedRequest = (isNotMatchAmazon && isNotMatchSession) || isDeleteMethod;

    const matchSessionToken = request.url.match('auth/session/token');

    return zip(
      this.fingerprintService.getVisitorData(),
      this.store.select(sessionSelectors.selectSessionToken),
    ).pipe(
      switchMap(([{ visitorId, requestId }, { access_token, refresh_token }]) => {
        const accessToken = access_token;
        const refreshToken = refresh_token;

        const token =
          matchSessionToken && !!refreshToken
            ? refresh_token
            : isResolvedRequest && !!accessToken
              ? accessToken
              : undefined;

        if (!token && isNotMatchSession) {
          return this.getSession(GetSessionActionTypes.NEW, request, next);
        }

        if (token && this.jwtHelper.isTokenExpired(accessToken) && !matchSessionToken) {
          return this.getSession(GetSessionActionTypes.REFRESH, request, next);
        }

        return next.handle(
          request.clone({
            setHeaders: this.getHeaders(visitorId, token, requestId),
            withCredentials: false,
          }),
        );
      }),
    );
  }
}
