import { Observable } from 'rxjs';

import {
  BaseResponse,
  CategoriesList,
  CheckUserInfoResponse,
  ComplaintReasonResponse,
  ComplaintRequest,
  ComplaintTicketResponse,
  CreatePassword2FARequest,
  DeletePassword2FARequest,
  Email,
  EmailAddressMaskedResponse,
  EmailVerification,
  EmailWithEmailMaskedResponse,
  PhoneNumberExistResponse,
  PhoneNumberMaskedResponse,
  PhoneNumberVerification,
  PhoneNumberWithPhoneNumberMaskedResponse,
  PutUserPhoneNumber,
  RequestUserInfo,
  SetPubkey2FARequest,
  SetPubkey2FAResponse,
  UpdatePassword2FARequest,
  UserFollowersResponse,
  UserFollowingsResponse,
  UserResponse,
  UserSearchResponse,
  VerificationCode2FA,
  VerificationCode2FATransport,
  VerifyPassword2FARequest,
  VerifyPassword2FAResponse,
} from '@api/backend';

export abstract class UsersRepository {
  public abstract searchUser(query: string, page_token?: string | null): Observable<UserSearchResponse>;
  public abstract discoverUser(page_token?: string): Observable<UserSearchResponse>;
  public abstract createUserComplaint(user_id: string, body: ComplaintRequest): Observable<ComplaintTicketResponse>;
  public abstract getUserComplaintsReasons(): Observable<ComplaintReasonResponse>;
  public abstract checkUsername(user_name: string): Observable<CheckUserInfoResponse>;
  public abstract getUserById(user_id: string): Observable<UserResponse>;
  public abstract deleteUser(user_id: string): Observable<BaseResponse>;
  public abstract patchUser(user_id: string, body: RequestUserInfo): Observable<UserResponse>;
  public abstract getFollowings(user_id: string, page_token?: string | null): Observable<UserFollowingsResponse>;
  public abstract getFollowers(user_id: string, page_token?: string | null): Observable<UserFollowersResponse>;
  public abstract putAvatar(user_id: string, avatar: File): Observable<UserResponse>;
  public abstract putBackgroundAvatar(user_id: string, avatar: File, background: File): Observable<UserResponse>;
  public abstract followUser(user_id: string): Observable<BaseResponse>;
  public abstract unfollowUser(user_id: string): Observable<BaseResponse>;
  public abstract putUsers2faPassword(body: UpdatePassword2FARequest): Observable<BaseResponse>;
  public abstract postUsers2faPassword(body: CreatePassword2FARequest): Observable<BaseResponse>;
  public abstract deleteUsers2faPassword(body: DeletePassword2FARequest): Observable<BaseResponse>;
  public abstract postUsers2faPinCodeKey(body: SetPubkey2FARequest): Observable<SetPubkey2FAResponse>;
  public abstract postUsers2faPhone(body: VerificationCode2FATransport): Observable<PhoneNumberMaskedResponse>;
  public abstract postUsers2faPhoneVerify(body: VerificationCode2FA): Observable<BaseResponse>;
  public abstract postUsers2faEmail(): Observable<EmailAddressMaskedResponse>;
  public abstract postUsers2faEmailVerify(body: VerificationCode2FA): Observable<BaseResponse>;
  public abstract postUsers2faVerify(body: VerifyPassword2FARequest): Observable<VerifyPassword2FAResponse>;
  public abstract putUsersPhone(
    user_id: string,
    body: PutUserPhoneNumber,
  ): Observable<PhoneNumberWithPhoneNumberMaskedResponse>;
  public abstract postUsersPhoneVerify(
    user_id: string,
    body: PhoneNumberVerification,
  ): Observable<PhoneNumberWithPhoneNumberMaskedResponse>;
  public abstract putUsersEmail(user_id: string, body: Email): Observable<EmailWithEmailMaskedResponse>;
  public abstract postUsersEmailVerify(
    user_id: string,
    body: EmailVerification,
  ): Observable<EmailWithEmailMaskedResponse>;
  public abstract getUsersPhoneExist(user_id: string): Observable<PhoneNumberExistResponse>;
  public abstract postUsersBlock(user_id: string): Observable<UserResponse>;
  public abstract postUsersUnblock(user_id: string): Observable<UserResponse>;
  public abstract postUsersInterests(body: CategoriesList): Observable<BaseResponse>;
  public abstract getUsersSnippet(user_id: string): Observable<BaseResponse>;
}
