/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Device } from './device';
import { Application } from './application';


export interface UpdateDeviceInfo { 
    installation_id?: string;
    installation_token?: string;
    device?: Device;
    application?: Application;
    fcm_token?: string;
    appsflyer_id?: string;
}

