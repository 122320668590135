<div
  class="share"
  [class.dialog]="sharedData.view === 'dialog'"
>
  <h4 *ngIf="sharedData.title" class="share__title">
    {{ sharedData.title }}
    <button
      cheeleeRipple
      isCenteredRipple
      (click)="closeOverlay()"
    >
      <cheelee-icon
        iconId="cross-icon"
        color="#AB9BFD"
      />
    </button>
  </h4>
  <ul class="share__list">
    <li
      class="share__item share__item--link"
      (click)="copyLink()"
    >
      <button>
        <div class="share__icon">
          <svg>
            <use xlink:href="./assets/img/sprite.svg#icon-share-link"></use>
          </svg>
        </div>
        <span class="share__text">{{ 'common:copy' | i18nextEager }}</span>
      </button>
    </li>

    <li
      class="share__item share__item--vkontakte"
      (click)="shareLink('vkontakte')"
    >
      <button>
        <div class="share__icon">
          <svg>
            <use xlink:href="./assets/img/sprite.svg#icon-share-vkontakte"></use>
          </svg>
        </div>
        <span class="share__text">VK</span>
      </button>
    </li>

    <li
      class="share__item share__item--facebook"
      *ngIf="remoteLocale !== 'RU'"
      (click)="shareLink('facebook')"
    >
      <button>
        <div class="share__icon">
          <svg>
            <use xlink:href="./assets/img/sprite.svg#icon-share-facebook"></use>
          </svg>
        </div>
        <span class="share__text">Facebook</span>
      </button>
    </li>

    <li
      class="share__item share__item--telegram"
      (click)="shareLink('telegram')"
    >
      <button>
        <div class="share__icon">
          <svg>
            <use xlink:href="./assets/img/sprite.svg#icon-share-telegram"></use>
          </svg>
        </div>
        <span class="share__text">Telegram</span>
      </button>
    </li>

    <li
      class="share__item share__item--twitter"
      (click)="shareLink('twitter')"
    >
      <button>
        <div class="share__icon">
          <svg>
            <use xlink:href="./assets/img/sprite.svg#icon-share-twitter"></use>
          </svg>
        </div>
        <span class="share__text">Twitter</span>
      </button>
    </li>

    <li
      class="share__item share__item--viber"
      *ngIf="isMobile()"
      (click)="shareLink('viber')"
    >
      <button>
        <div class="share__icon">
          <svg>
            <use xlink:href="./assets/img/sprite.svg#icon-share-viber"></use>
          </svg>
        </div>
        <span class="share__text">Viber</span>
      </button>
    </li>

    <li
      class="share__item share__item--whatsapp"
      (click)="shareLink('whatsapp')"
    >
      <button>
        <div class="share__icon">
          <svg>
            <use xlink:href="./assets/img/sprite.svg#icon-share-whatsapp"></use>
          </svg>
        </div>
        <span class="share__text">WhatsApp</span>
      </button>
    </li>

    <li
      class="share__item share__item--linkedin"
      (click)="shareLink('linkedin')"
    >
      <button>
        <div class="share__icon">
          <svg>
            <use xlink:href="./assets/img/sprite.svg#icon-share-linkedin"></use>
          </svg>
        </div>
        <span class="share__text">LinkedIn</span>
      </button>
    </li>
  </ul>
</div>
