import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AnimationOptions, LottieComponent } from 'ngx-lottie';

type LoaderSize = 's' | 'm' | 'l';

@Component({
  selector: 'cheelee-loader',
  standalone: true,
  imports: [ CommonModule, LottieComponent ],
  templateUrl: './loader.component.html',
  styleUrls: [ './loader.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  @Input()
  public size: LoaderSize = 'l';

  public options: AnimationOptions = {
    path: './assets/lottie/loader.json',
  };
}
