<div
  class="header flex-column align-content-center"
  [ngStyle]="{
    'background-color': background(),
  }"
>
  <div class="container relative flex align-items-center justify-content-between">
    <ng-content />
  </div>
</div>
