import { Injectable } from '@angular/core';

import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';

import { I18NextString, InfoBlockVariant, SnackbarPayload } from '@core/models';

const DEFAULT_CONFIG: Partial<IndividualConfig<SnackbarPayload>> = {
  positionClass: 'toast-bottom-full-width',
  timeOut: 5000,
};

@Injectable({ providedIn: 'root' })
export class SnackbarService {
  constructor(private readonly toastrService: ToastrService) {}

  public show(
    message: string,
    variant: InfoBlockVariant = 'success',
    action?: () => void,
    actionBtnText?: I18NextString,
  ): ActiveToast<SnackbarPayload> {
    return this.toastrService.show<SnackbarPayload>(message, '', {
      ...DEFAULT_CONFIG,
      payload: {
        variant,
        action,
        actionBtnText,
      },
    }) as ActiveToast<SnackbarPayload>;
  }

  public clear(id?: number): void {
    this.toastrService.clear(id);
  }
}
