/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * You are should use a correlation_id uniq to each COMMENT, but keep it for request retry. While you should retry your failed (for any reason) request - you should keep this parameter as for first attempt. 
 */
export interface CreateCommentReq { 
    correlation_id: string;
    media_id: string;
    text: string;
    parent_id?: string | null;
}

