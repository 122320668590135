import { Observable } from 'rxjs';

import { AvailableAuthResponse, BaseResponse, OAuthLoginResponse, ScryptChallengeResponse } from '@api/backend';
import {
  SessionBodyRequest,
  SessionEmailBody,
  SessionEmailData,
  SessionEmailVerifyBody,
  SessionPhoneBody,
  SessionPhoneData,
  SessionPhoneVerifyBody,
  SessionTokenData,
  SessionUpdateBodyRequest,
} from '@core/models';

export abstract class AuthRepository {
  /**
   * @method POST
   * @param body SessionInfoBodyRequest
   * @return Observable<SessionTokenData>
   */
  public abstract postAuthSession(body: SessionBodyRequest): Observable<SessionTokenData>;

  /**
   * @method DELETE
   * @return Observable<{ }>
   */
  public abstract deleteAuthSession(): Observable<BaseResponse>;

  /**
   * @method POST
   * @param body SessionEmailBody
   * @return Observable<SessionEmailData>
   */
  public abstract postAuthEmail(body: SessionEmailBody): Observable<SessionEmailData>;

  /**
   * @method POST
   * @param body SessionEmailVerifyBody
   * @param id_2fa
   * @return Observable<SessionTokenData>
   */
  public abstract postAuthEmailVerify(body: SessionEmailVerifyBody, id_2fa?: string): Observable<SessionTokenData>;

  /**
   * @method POST
   * @param body SessionPhoneBody
   * @return Observable<SessionPhoneData>
   */
  public abstract postAuthPhone(body: SessionPhoneBody): Observable<SessionPhoneData>;

  /**
   * @method POST
   * @param body SessionVerifyBody2
   * @param id_2fa
   * @return Observable<SessionTokenData>
   */
  public abstract postAuthPhoneVerify(body: SessionPhoneVerifyBody, id_2fa?: string): Observable<SessionTokenData>;

  /**
   * @method POST
   * @return Observable<SessionTokenData>
   */
  public abstract postAuthSessionToken(): Observable<SessionTokenData>;

  /**
   * @method PUT
   * @param body SessionInfoBodyUpdate
   * @return Observable<BaseResponse>
   */
  public abstract putAuthSessionDevice(body: SessionUpdateBodyRequest): Observable<BaseResponse>;

  /**
   * @method GET
   * @return Observable<ScryptChallengeResponse>
   */
  public abstract getAuthChallenge(): Observable<ScryptChallengeResponse>;

  /**
   * @method GET
   * @return Observable<OAuthLoginResponse>
   */
  public abstract getAuthOAuthVkLogin(): Observable<OAuthLoginResponse>;

  /**
   * @method GET
   * @return Observable<OAuthLoginResponse>
   */
  public abstract getAuthOAuthGoogleLogin(): Observable<OAuthLoginResponse>;

  /**
   * @method GET
   * @return Observable<OAuthLoginResponse>
   */
  public abstract getAuthOAuthGoogleLogin(): Observable<OAuthLoginResponse>;

  /**
   * @method GET
   * @return Observable<OAuthLoginResponse>
   */
  public abstract getAuthOAuthFacebookLogin(): Observable<OAuthLoginResponse>;

  /**
   * @method GET
   * @return Observable<OAuthLoginResponse>
   */
  public abstract getAuthOAuthAppleLogin(): Observable<OAuthLoginResponse>;

  /**
   * @method GET
   * @return Observable<SessionTokenData>
   */
  public abstract getAuthOAuthVkCallback(code: string, id_2fa?: string): Observable<SessionTokenData>;

  /**
   * @method GET
   * @return Observable<SessionTokenData>
   */
  public abstract getAuthOAuthGoogleCallback(code: string, id_2fa?: string): Observable<SessionTokenData>;

  /**
   * @method GET
   * @return Observable<SessionTokenData>
   */
  public abstract getAuthOAuthFacebookCallback(code: string, id_2fa?: string): Observable<SessionTokenData>;

  /**
   * @method GET
   * @return Observable<SessionTokenData>
   */
  public abstract getAuthOAuthAppleCallback(code: string, id_2fa?: string): Observable<SessionTokenData>;

  /**
   * @method GET
   * @return Observable<AvailableAuthResponse>
   */
  public abstract getAuthAvailable(): Observable<AvailableAuthResponse>;
}
