import { Injectable } from '@angular/core';

import { CookieService as NgxCookieService } from 'ngx-cookie-service';

import { SessionToken } from '@core/models';
import { COOKIE } from '@data/const';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor(private ngxCookieService: NgxCookieService) {}

  public get(tokenName: string): string {
    return this.ngxCookieService.get(tokenName);
  }

  public set(
    tokenName: string,
    token: SessionToken | string,
    expires: number | Date = COOKIE.EXPIRE,
    path = COOKIE.PATH,
  ): void {
    this.ngxCookieService.set(tokenName, typeof token === 'string' ? token : JSON.stringify(token), expires, path);
  }

  public check(name: string): boolean {
    return this.ngxCookieService.check(name);
  }

  public clear(name?: string): void {
    if (name) {
      this.ngxCookieService.delete(name);
      return;
    }

    this.ngxCookieService.deleteAll(COOKIE.PATH);
  }
}
