/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PhoneAuthType } from './phoneAuthType';


export interface PhoneAuthRequest { 
    phone_number: string;
    auth_type?: PhoneAuthType | null;
    recaptcha_v3_token?: string;
    recaptcha_v2_token?: string;
    hash?: string;
}

