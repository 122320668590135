/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChallengeMediaStage } from './challengeMediaStage';
import { User } from './user';
import { UserLikePreview } from './userLikePreview';
import { ParentResource } from './parentResource';
import { AudioTrack } from './audioTrack';
import { MediaCounters } from './mediaCounters';
import { ModerationStatus } from './moderationStatus';


export interface Media { 
    author: User;
    recommendation_id?: string | null;
    media_id: string;
    media_name?: string;
    media_description?: string;
    created_at: number;
    categories?: Array<string>;
    counters: MediaCounters;
    parent_resource_reference: ParentResource;
    moderation_status: ModerationStatus;
    deleted_at?: number;
    preview_url?: string;
    media_url?: string;
    media_urls?: { [key: string]: string; };
    thumbnail_url?: string;
    is_boosted?: boolean;
    is_liked?: boolean;
    is_deleted?: boolean;
    is_blocked?: boolean;
    is_hidable?: boolean;
    is_challenge_winner?: boolean;
    is_deletable?: boolean;
    is_reportable?: boolean;
    media_duration?: number;
    allow_comments?: boolean;
    boost_coefficient?: number;
    user_likes_preview?: Array<UserLikePreview>;
    show_id?: string;
    votes?: number;
    is_voted?: boolean;
    is_votable?: boolean;
    challenge_stage?: ChallengeMediaStage | null;
    show_views?: number;
    audio?: AudioTrack;
}

