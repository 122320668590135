/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserVideoV2Likes } from './userVideoV2Likes';
import { UserVideoV2Audio } from './userVideoV2Audio';
import { UserVideoV2Shares } from './userVideoV2Shares';
import { FeedVideo } from './feedVideo';
import { UserVideoV2Meta } from './userVideoV2Meta';
import { UserVideoV2Author } from './userVideoV2Author';
import { UserVideoV2Comments } from './userVideoV2Comments';
import { UserVideoV2Cover } from './userVideoV2Cover';
import { UserVideoV2Views } from './userVideoV2Views';


export interface UserVideoV2 { 
    id?: string;
    similarity_group?: string;
    meta?: UserVideoV2Meta;
    cover?: UserVideoV2Cover;
    video?: FeedVideo;
    author?: UserVideoV2Author;
    audio?: UserVideoV2Audio;
    likes?: UserVideoV2Likes;
    views?: UserVideoV2Views;
    shares?: UserVideoV2Shares;
    comments?: UserVideoV2Comments;
}

