import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { MediaEffects } from './media.effects';
import { mediaFeatureKey, mediaReducer } from './media.reducer';

@NgModule({
  imports: [StoreModule.forFeature(mediaFeatureKey, mediaReducer), EffectsModule.forFeature([MediaEffects])],
  exports: [StoreModule],
})
export class MediaFeatureModule {}
