import { DOCUMENT, NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  ElementRef,
  Inject,
  input,
  OnInit,
  Renderer2,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { switchMap, tap, throttleTime } from 'rxjs';
import { filter } from 'rxjs/operators';

import { BreakpointFacade, UserFacade } from '@core/facades';
import { isImageLoaded$ } from '@core/helpers';
import { OverlayService, PlatformService, ShareLinkService } from '@core/services';
import { SeoService } from '@core/services/seo/seo.service';
import { UsersWebRepository } from '@data/repositories/users-web.repository';
import { ButtonComponent, HeaderComponent, IconComponent, LoaderComponent, SpinnerComponent } from '@shared/components';
import { RippleDirective } from '@shared/directives';
import { I18NextModule } from 'angular-i18next';
import { GoBackDirective } from '@shared/directives/go-back.directive';
import { UserContentComponent } from '@modules/user/components/user-content/user-content.component';
import { BannerPictureType, User } from '@core/models';
import { CdkScrollable } from '@angular/cdk/overlay';
import { TabBarComponent } from '@shared/components/tab-bar/tab-bar.component';
import { ResponsiveLayoutComponent } from '@shared/layouts/responsive-layout/responsive-layout.component';

@Component({
  selector: 'cheelee-user',
  standalone: true,
  templateUrl: './user.component.html',
  styleUrls: [ './user.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ UsersWebRepository ],
  imports: [
    HeaderComponent,
    IconComponent,
    RippleDirective,
    NgStyle,
    SpinnerComponent,
    UserContentComponent,
    ButtonComponent,
    I18NextModule,
    LoaderComponent,
    GoBackDirective,
    TabBarComponent,
    ResponsiveLayoutComponent,
  ],
})
export class UserComponent implements OnInit {

  private readonly background = viewChild<ElementRef<HTMLElement>>('background');

  private readonly backgroundCover = viewChild<ElementRef<HTMLElement>>('cover');

  public browsingUser = input<User>();

  public user = computed<User | undefined>(
    () => this.browsingUser() || this.userFacade.browsingUser(),
  );

  public isLoading = this.userFacade.isLoading;

  public isBackgroundReady = signal(false);

  public userBackground = computed(() => {
    if (this.isBackgroundReady() && this.user()?.user_background_url) {
      return `url('${ this.user()?.user_background_url }')`;
    } else {
      return 'url("./assets/img/profile-empty.svg")';
    }
  });

  public isColorizedHeader = signal(false);

  constructor(
    private userFacade: UserFacade,
    private platformService: PlatformService,
    private overlayService: OverlayService,
    private seoService: SeoService,
    private destroyRef: DestroyRef,
    private breakpointFacade: BreakpointFacade,
    private sc: CdkScrollable,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private shareLinkService: ShareLinkService,
  ) {
    // this.sc.elementScrolled().subscribe(console.log)
  }

  public ngOnInit(): void {
    this.userFacade.getBrowsingUser();
    this.userFacade.loadUserMedia();

    this.userFacade.browsingUser$
      .pipe(
        filter((user) => !!user),
        tap(() => setTimeout(() => this.subscribeToScrollContent())),
        switchMap((user) => isImageLoaded$(user?.user_background_url)),
        tap((isLoaded) => {
          if (isLoaded) {
            this.isBackgroundReady.set(true);
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();

    if (this.platformService.isServer()) {
      this.userFacade
        .getSnippet()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((snippet) => this.seoService.setSeoTags(snippet));
    }
  }


  private subscribeToScrollContent(): void {
    if (!this.platformService.isBrowser()) {
      return;
    }



    this.sc.elementScrolled()
      .pipe(throttleTime(0), takeUntilDestroyed(this.destroyRef))
      .subscribe((event) => {
        const background = this.background();
        if (!background) {
          return;
        }
        const { scrollTop } = event.target as HTMLElement;
        const isIntersected = scrollTop >= background.nativeElement.offsetHeight - 80;
        const backgroundCover = this.backgroundCover();
        if (backgroundCover) {
          this.renderer.setStyle(backgroundCover.nativeElement, 'height', `${background.nativeElement.offsetHeight - scrollTop}px`);
        }
        if ((isIntersected && !this.isColorizedHeader()) || (!isIntersected && this.isColorizedHeader())) {
          this.isColorizedHeader.set(isIntersected);
        }
      });
  }

  public openGetAppDialog(picture?: BannerPictureType): void {
    this.overlayService.openGetAppDialog(picture);
  }

  public openShareCurtain(): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion,@typescript-eslint/naming-convention
    const { user_id, user_name } = this.user()!;
    this.shareLinkService.share(user_id, user_name, 'user');
  }
}
