<div
  class="actions"
  *ngIf="media() as mediaItem"
>
  <cheelee-avatar
    class="actions__avatar"
    [user]="mediaItem.author"
    withBorder
    [analytics]="analyticsEvents.MediaUserTap"
  />
  <div class="actions__btn-wrapper">
    <button
      class="actions__btn"
      (cheeleeThrottleClick)="openGetAppDialog('share')"
      [analytics]="analyticsEvents.MediaLikeTap"
    >
      <div
        class="actions__like-icon"
        [ngClass]="{ active: mediaItem.is_liked, 'like-animation': withLikeAnimation() }"
      ></div>
    </button>
    <span class="actions__counter bold">{{ mediaItem.counters.likes | shortNumber }}</span>
  </div>

  <div class="actions__btn-wrapper">
    <button
      class="actions__btn"
      cheeleeRipple
      isCenteredRipple
      (click)="openGetAppDialog()"
      [analytics]="analyticsEvents.MediaCommentTap"
    >
      <cheelee-icon
        iconId="comments-icon"
        size="44"
      />
    </button>
    <span class="actions__counter bold">{{ mediaItem.counters.comments | shortNumber }}</span>
  </div>

  <div class="actions__btn-wrapper">
    <button
      class="actions__btn"
      cheeleeRipple
      isCenteredRipple
      (click)="openShareCurtain()"
      [analytics]="analyticsEvents.MediaShareTap"
    >
      <cheelee-icon
        iconId="share-icon"
        size="44"
      />
    </button>
    <span class="actions__counter bold">{{ mediaItem.counters.reposts | shortNumber }}</span>
  </div>

  <div
    class="actions__btn-wrapper"
    [style.margin-top.px]="10"
  >
    <button
      class="actions__btn"
      cheeleeRipple
      isCenteredRipple
      (click)="openGetAppDialog('share')"
      [analytics]="analyticsEvents.MediaMenuTap"
    >
      <cheelee-icon
        iconId="more-icon"
        size="24"
      />
    </button>
  </div>
</div>
