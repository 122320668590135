/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { BaseResponse } from '../model/baseResponse';
// @ts-ignore
import { CreatePassword2FARequest } from '../model/createPassword2FARequest';
// @ts-ignore
import { DeletePassword2FARequest } from '../model/deletePassword2FARequest';
// @ts-ignore
import { EmailAddressMaskedResponse } from '../model/emailAddressMaskedResponse';
// @ts-ignore
import { PhoneNumberMaskedResponse } from '../model/phoneNumberMaskedResponse';
// @ts-ignore
import { SetPubkey2FARequest } from '../model/setPubkey2FARequest';
// @ts-ignore
import { SetPubkey2FAResponse } from '../model/setPubkey2FAResponse';
// @ts-ignore
import { UpdatePassword2FARequest } from '../model/updatePassword2FARequest';
// @ts-ignore
import { VerificationCode2FA } from '../model/verificationCode2FA';
// @ts-ignore
import { VerificationCode2FATransport } from '../model/verificationCode2FATransport';
// @ts-ignore
import { VerifyPassword2FARequest } from '../model/verifyPassword2FARequest';
// @ts-ignore
import { VerifyPassword2FAResponse } from '../model/verifyPassword2FAResponse';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class Class2FAService {

    protected basePath = 'https://api.talala.la/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Delete User 2Fa Password
     * @param deletePassword2FARequest 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUser2faPasswordUsers2faPasswordDelete(deletePassword2FARequest: DeletePassword2FARequest, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<BaseResponse>;
    public deleteUser2faPasswordUsers2faPasswordDelete(deletePassword2FARequest: DeletePassword2FARequest, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<BaseResponse>>;
    public deleteUser2faPasswordUsers2faPasswordDelete(deletePassword2FARequest: DeletePassword2FARequest, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<BaseResponse>>;
    public deleteUser2faPasswordUsers2faPasswordDelete(deletePassword2FARequest: DeletePassword2FARequest, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (deletePassword2FARequest === null || deletePassword2FARequest === undefined) {
            throw new Error('Required parameter deletePassword2FARequest was null or undefined when calling deleteUser2faPasswordUsers2faPasswordDelete.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.delete<BaseResponse>(`${this.configuration.basePath}/v2/users/2fa/password`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post User 2Fa Password
     * @param createPassword2FARequest 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postUser2faPasswordUsers2faPasswordPost(createPassword2FARequest: CreatePassword2FARequest, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<BaseResponse>;
    public postUser2faPasswordUsers2faPasswordPost(createPassword2FARequest: CreatePassword2FARequest, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<BaseResponse>>;
    public postUser2faPasswordUsers2faPasswordPost(createPassword2FARequest: CreatePassword2FARequest, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<BaseResponse>>;
    public postUser2faPasswordUsers2faPasswordPost(createPassword2FARequest: CreatePassword2FARequest, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (createPassword2FARequest === null || createPassword2FARequest === undefined) {
            throw new Error('Required parameter createPassword2FARequest was null or undefined when calling postUser2faPasswordUsers2faPasswordPost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<BaseResponse>(`${this.configuration.basePath}/v2/users/2fa/password`,
            createPassword2FARequest,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post User 2Fa Pincode Key
     * @param setPubkey2FARequest 
     * @param xNuts2FAId 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postUser2faPincodeKeyUsers2faPincodeKeyPost(setPubkey2FARequest: SetPubkey2FARequest, xNuts2FAId?: string, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SetPubkey2FAResponse>;
    public postUser2faPincodeKeyUsers2faPincodeKeyPost(setPubkey2FARequest: SetPubkey2FARequest, xNuts2FAId?: string, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SetPubkey2FAResponse>>;
    public postUser2faPincodeKeyUsers2faPincodeKeyPost(setPubkey2FARequest: SetPubkey2FARequest, xNuts2FAId?: string, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SetPubkey2FAResponse>>;
    public postUser2faPincodeKeyUsers2faPincodeKeyPost(setPubkey2FARequest: SetPubkey2FARequest, xNuts2FAId?: string, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (setPubkey2FARequest === null || setPubkey2FARequest === undefined) {
            throw new Error('Required parameter setPubkey2FARequest was null or undefined when calling postUser2faPincodeKeyUsers2faPincodeKeyPost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNuts2FAId !== undefined && xNuts2FAId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-2FA-Id', String(xNuts2FAId));
        }
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<SetPubkey2FAResponse>(`${this.configuration.basePath}/v2/users/2fa/pincode-key`,
            setPubkey2FARequest,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post User 2Fa Verify
     * @param verifyPassword2FARequest 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postUser2faVerifyUsers2faVerifyPost(verifyPassword2FARequest: VerifyPassword2FARequest, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<VerifyPassword2FAResponse>;
    public postUser2faVerifyUsers2faVerifyPost(verifyPassword2FARequest: VerifyPassword2FARequest, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<VerifyPassword2FAResponse>>;
    public postUser2faVerifyUsers2faVerifyPost(verifyPassword2FARequest: VerifyPassword2FARequest, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<VerifyPassword2FAResponse>>;
    public postUser2faVerifyUsers2faVerifyPost(verifyPassword2FARequest: VerifyPassword2FARequest, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (verifyPassword2FARequest === null || verifyPassword2FARequest === undefined) {
            throw new Error('Required parameter verifyPassword2FARequest was null or undefined when calling postUser2faVerifyUsers2faVerifyPost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<VerifyPassword2FAResponse>(`${this.configuration.basePath}/v2/users/2fa/verify`,
            verifyPassword2FARequest,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Put User 2Fa Password
     * @param updatePassword2FARequest 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putUser2faPasswordUsers2faPasswordPut(updatePassword2FARequest: UpdatePassword2FARequest, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<BaseResponse>;
    public putUser2faPasswordUsers2faPasswordPut(updatePassword2FARequest: UpdatePassword2FARequest, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<BaseResponse>>;
    public putUser2faPasswordUsers2faPasswordPut(updatePassword2FARequest: UpdatePassword2FARequest, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<BaseResponse>>;
    public putUser2faPasswordUsers2faPasswordPut(updatePassword2FARequest: UpdatePassword2FARequest, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (updatePassword2FARequest === null || updatePassword2FARequest === undefined) {
            throw new Error('Required parameter updatePassword2FARequest was null or undefined when calling putUser2faPasswordUsers2faPasswordPut.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.put<BaseResponse>(`${this.configuration.basePath}/v2/users/2fa/password`,
            updatePassword2FARequest,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Send Email Verification Code
     * @param acceptLanguage 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendEmailVerificationCodeUsers2faEmailPost(acceptLanguage?: string, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<EmailAddressMaskedResponse>;
    public sendEmailVerificationCodeUsers2faEmailPost(acceptLanguage?: string, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<EmailAddressMaskedResponse>>;
    public sendEmailVerificationCodeUsers2faEmailPost(acceptLanguage?: string, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<EmailAddressMaskedResponse>>;
    public sendEmailVerificationCodeUsers2faEmailPost(acceptLanguage?: string, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            localVarHeaders = localVarHeaders.set('accept-language', String(acceptLanguage));
        }
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<EmailAddressMaskedResponse>(`${this.configuration.basePath}/v2/users/2fa/email`,
            null,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Send Phone Verification Code
     * @param verificationCode2FATransport 
     * @param acceptLanguage 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendPhoneVerificationCodeUsers2faPhonePost(verificationCode2FATransport: VerificationCode2FATransport, acceptLanguage?: string, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PhoneNumberMaskedResponse>;
    public sendPhoneVerificationCodeUsers2faPhonePost(verificationCode2FATransport: VerificationCode2FATransport, acceptLanguage?: string, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PhoneNumberMaskedResponse>>;
    public sendPhoneVerificationCodeUsers2faPhonePost(verificationCode2FATransport: VerificationCode2FATransport, acceptLanguage?: string, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PhoneNumberMaskedResponse>>;
    public sendPhoneVerificationCodeUsers2faPhonePost(verificationCode2FATransport: VerificationCode2FATransport, acceptLanguage?: string, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (verificationCode2FATransport === null || verificationCode2FATransport === undefined) {
            throw new Error('Required parameter verificationCode2FATransport was null or undefined when calling sendPhoneVerificationCodeUsers2faPhonePost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (acceptLanguage !== undefined && acceptLanguage !== null) {
            localVarHeaders = localVarHeaders.set('accept-language', String(acceptLanguage));
        }
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<PhoneNumberMaskedResponse>(`${this.configuration.basePath}/v2/users/2fa/phone`,
            verificationCode2FATransport,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Verify Email
     * @param verificationCode2FA 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verifyEmailUsers2faEmailVerifyPost(verificationCode2FA: VerificationCode2FA, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<BaseResponse>;
    public verifyEmailUsers2faEmailVerifyPost(verificationCode2FA: VerificationCode2FA, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<BaseResponse>>;
    public verifyEmailUsers2faEmailVerifyPost(verificationCode2FA: VerificationCode2FA, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<BaseResponse>>;
    public verifyEmailUsers2faEmailVerifyPost(verificationCode2FA: VerificationCode2FA, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (verificationCode2FA === null || verificationCode2FA === undefined) {
            throw new Error('Required parameter verificationCode2FA was null or undefined when calling verifyEmailUsers2faEmailVerifyPost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<BaseResponse>(`${this.configuration.basePath}/v2/users/2fa/email/verify`,
            verificationCode2FA,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Verify Phone
     * @param verificationCode2FA 
     * @param xNutsRequestId Request id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verifyPhoneUsers2faPhoneVerifyPost(verificationCode2FA: VerificationCode2FA, xNutsRequestId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<BaseResponse>;
    public verifyPhoneUsers2faPhoneVerifyPost(verificationCode2FA: VerificationCode2FA, xNutsRequestId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<BaseResponse>>;
    public verifyPhoneUsers2faPhoneVerifyPost(verificationCode2FA: VerificationCode2FA, xNutsRequestId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<BaseResponse>>;
    public verifyPhoneUsers2faPhoneVerifyPost(verificationCode2FA: VerificationCode2FA, xNutsRequestId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (verificationCode2FA === null || verificationCode2FA === undefined) {
            throw new Error('Required parameter verificationCode2FA was null or undefined when calling verifyPhoneUsers2faPhoneVerifyPost.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xNutsRequestId !== undefined && xNutsRequestId !== null) {
            localVarHeaders = localVarHeaders.set('X-Nuts-Request-Id', String(xNutsRequestId));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<BaseResponse>(`${this.configuration.basePath}/v2/users/2fa/phone/verify`,
            verificationCode2FA,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
