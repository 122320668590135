import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

declare const ym: any; // Объявляем глобальную функцию для вызова Яндекс.Метрики

@Injectable({
  providedIn: 'root',
})
export class YandexMetrikaService {
  private counterId = environment.ymConfig.id;

  reachGoal(target: string, params: any = {}): void {
    if (!environment.production) {
      return;
    }
    try {
      if (!target) {
        // eslint-disable-next-line no-console
        console.warn('Yandex.Metrika: Target is not defined');
        return;
      }
      ym(this.counterId, 'reachGoal', target, params);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.trace(error);
    }
  }

  hit(target: string, params: any = {}): void {
    if (!environment.production) {
      return;
    }
    try {
      if (!target) {
        // eslint-disable-next-line no-console
        console.warn('Yandex.Metrika: Target is not defined');
        return;
      }
      ym(this.counterId, 'hit', target, params);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.trace(error);
    }
  }

}
