import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { MediaViewModel } from '@core/services';

export type MediaViewState = EntityState<MediaViewModel>;

export const mediaViewsAdapter: EntityAdapter<MediaViewModel> = createEntityAdapter<MediaViewModel>({
  selectId: (mediaView) => mediaView.media_id,
});

export const initialMediaViewState = mediaViewsAdapter.getInitialState();
