/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EmptyResponseHdr } from './emptyResponseHdr';


/**
 * response
 */
export interface EmptyResponse { 
    hdr?: EmptyResponseHdr;
    resp?: object;
    error?: object;
}

