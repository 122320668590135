<div
  class="avatar size-{{ size }}"
  (click)="navigateToUser()"
>
  <img
    #img
    class="avatar__img"
    alt="user avatar"
    [ngSrc]="imgSrc()"
    fill
  />

  <img
    class="avatar__border"
    alt="avatar border"
    *ngIf="withBorder"
    [ngSrc]="
      canShowBadge() && !isPending()
        ? './assets/img/avatar-border-badge.svg'
        : './assets/img/avatar-border.svg'
    "
    priority
    fill
  />

  <cheelee-icon
    class="avatar__confirmed-icon"
    *ngIf="withConfirmedIcon && userData()?.is_identity_confirmed"
    [iconId]="size === 's' ? 'confirmed-user-icon' : 'confirmed-user-icon-s'"
    [size]="size === 's' ? 14 : 16"
  />

  <ng-container *ngIf="isPending(); else badgeBtn">
    <cheelee-spinner size="18" />
  </ng-container>
  <ng-template #badgeBtn>
    <button
      class="avatar__badge"
      *ngIf="canShowBadge()"
      (click)="onBadgeClick($event)"
    ></button>
  </ng-template>
</div>
