/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 */
export type GenderType = 'male' | 'female' | 'other' | 'unselected';

export const GenderType = {
    Male: 'male' as GenderType,
    Female: 'female' as GenderType,
    Other: 'other' as GenderType,
    Unselected: 'unselected' as GenderType
};

