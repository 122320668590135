<button
  class="button button-{{ variant }} bold"
  [disabled]="isDisabled"
  [isCenteredRipple]="isRoundShape"
  [ngClass]="{
    full: withFullWidth,
    round: isRoundShape,
    small: isSmall,
    'animated-border': withAnimatedBorder,
    'custom-color': !!customColor,
  }"
  [style.backgroundColor]="customBackgroundColor"
  [style.color]="customColor"
  cheeleeRipple
  (click)="onClick($event)"
>
  <ng-content />
</button>
