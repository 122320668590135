/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TokenHashtag } from './tokenHashtag';
import { TokenUserData } from './tokenUserData';
import { Position } from './position';
import { TokenUser } from './tokenUser';


/**
 * @type CommentTokensInner
 * @export
 */
export type CommentTokensInner = TokenHashtag | TokenUser;

