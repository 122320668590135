/**
 * Talala API Docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProfileUser } from './profileUser';
import { ProfileCompany } from './profileCompany';
import { ProfileUserObject } from './profileUserObject';


/**
 * @type SystemVideoV1Profile
 * @export
 */
export type SystemVideoV1Profile = ProfileCompany | ProfileUser;

