import { Injectable, Signal } from '@angular/core';

import { Store } from '@ngrx/store';

import { Media } from '@core/models';
import { mediaActions, mediaSelectors } from '@store/media';

@Injectable()
export class MediaSwiperFacade {
  public readonly isLoading = this.store.selectSignal(mediaSelectors.selectFeedIsLoading);

  public readonly isMuted = this.store.selectSignal(mediaSelectors.selectIsMuted);

  public readonly isLoading$ = this.store.select(mediaSelectors.selectFeedIsLoading);

  public readonly feedLength$ = this.store.select(mediaSelectors.selectFeedLength);

  public readonly feedLength = this.store.selectSignal(mediaSelectors.selectFeedLength);

  public readonly currentMediaIndex = this.store.selectSignal(mediaSelectors.selectCurrentMediaIndex);


  constructor(private readonly store: Store) {}

  public muteMedia(force = false): void {
    this.store.dispatch(mediaActions.muteMedia({ isMuted: force || !this.isMuted() }));
  }

  public getNextFeedMedia(): void {
    this.store.dispatch(mediaActions.loadFeedMedia({ isNextPart: true }));
  }

  public setCurrentMediaIndex(media_id: string): void {
    this.store.dispatch(mediaActions.setCurrentMediaIndex({ media_id }));
  }

  public selectMediaByIndex(index: number): Signal<Media> {
    return this.store.selectSignal(mediaSelectors.selectMediaByIndex(index));
  }
}
