import { Data } from '@angular/router';

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ViewSource, ViewSourceType } from '@core/models';
import { MergedRoute, MergedRouteReducerState } from '@store/router/merged-route';

const selectRouterFeature = createFeatureSelector<MergedRouteReducerState>('router');
const selectRouterState = createSelector(selectRouterFeature, (router: MergedRouteReducerState) => router.state);
const selectRouterNavigationId = createSelector(
  selectRouterFeature,
  (router: MergedRouteReducerState) => router.navigationId,
);
const selectRouterParams = createSelector(selectRouterState, (state: MergedRoute) => state.params);
// eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/explicit-function-return-type
const selectRouterParam = (param: string) => createSelector(selectRouterParams, (params) => params && params[param]);
const selectQueryRouterParams = createSelector(selectRouterState, (state: MergedRoute) => state.queryParams);
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const selectQueryRouterParam = (param: string) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  createSelector(selectQueryRouterParams, (params) => params && params[param]);
const selectFragment = createSelector(selectRouterState, (state: MergedRoute) => state.fragment);
const selectUrl = createSelector(selectRouterState, (state: MergedRoute) => state.url);
const selectData = createSelector(selectRouterState, (state: MergedRoute) => state.data);
const selectFeedType = createSelector(selectData, (data: Data) =>
  data['type'] ? (data['type'] as ViewSourceType) : ViewSource.Recommendations,
);

export const routerSelectors = {
  selectRouterState,
  selectRouterNavigationId,
  selectRouterParams,
  selectRouterParam,
  selectQueryRouterParams,
  selectQueryRouterParam,
  selectFragment,
  selectUrl,
  selectData,
  selectFeedType,
};
