<div
  #swiperContainerRef
  class="swiper"
  (animationend)="swiperContainerRef.style.animation = 'unset'"
>
  @if (isMobile()) {
    @if (mode() === MediaActionsMode.RECOMMENDATIONS) {
      <cheelee-feed-nav
        [isMuted]="isMuted()"
        (toggleMuteClicked)="toggleMute()"
      />
    } @else {
      <cheelee-header
        [isFixed]="true"
        [background]="null"
      >
        <div class="header__container">
          <div class="header__go-back">
            <button
              class="header__go-back-btn"
              cheeleeGoBack
              cheeleeRipple
              isCenteredRipple
            >
              <cheelee-icon
                class="header__go-back-icon"
                iconId="arrow-left-icon"
              />
            </button>
          </div>
          <cheelee-top-button
            class="feed-nav__right-bottom"
            [icon]="!isMuted() ? 'sprite.svg#icon-not-muted' : 'sprite.svg#icon-muted'"
            (clicked)="toggleMute()"
          />
        </div>
      </cheelee-header>
    }
  }
  <swiper-container #swiper class="swiper__container" >
    @for (_ of [].constructor(feedLength()); track index; let index = $index ) {
      <swiper-slide>
        @if (index <= currentIndex() + 2 && index >= currentIndex() - 2 && getMediaByIndex(index)()) {
          <section
            class="swiper__actions-overlay"
            [style.opacity]="withTransparentActions() ? '.4' : '1'"
          >
            <div class="flex flex-column flex-grow-1 flex-shrink-1">
              <cheelee-about-media [mediaItem]="getMediaByIndex(index)()" />
              <cheelee-donation/>
            </div>
            <cheelee-media-actions
              [mediaItem]="getMediaByIndex(index)()"
              [mode]="mode()"
              (closeComments)="openGetAppDialog('share')"
              (like)="canShowLikeEffect.set(true)"
              (openComments)="openGetAppDialog('share')"
            />
          </section>


          <div class="swiper__video-wrapper">
            @if (canShowVideoSpinner() && isActiveVideoPosterLoaded()) {
              <cheelee-spinner class="swiper__spinner" size="32"/>
            }
            <video
              #htmlVideoElement
              class="swiper__video"
              [muted]="isMuted() || index !== currentIndex()"
              [poster]="getMediaByIndex(index)().preview_url"
              [src]="getMediaByIndex(index)().media_url"
              cheeleeSingleDoubleClick
              loop
              playsinline
              preload="auto"
              (canplay)="canShowVideoSpinner.set(false)"
              (loadeddata)="canShowVideoSpinner.set(false)"
              (singleClick)="onVideoClick()"
              (waiting)="canShowVideoSpinner.set(true)"
              [analytics]="htmlVideoElement.paused ? analyticsEvents.MediaPlayTap : analyticsEvents.MediaPauseTap"
            ></video>
          </div>
        }
      </swiper-slide>
    }
  </swiper-container>

  <div class="swiper__effects">
    @if (canShowPauseEffect()) {
      <div
        class="swiper__pause-circle fade-in-out"
        (animationend)="canShowPauseEffect.set(false)"
      >
        @if (activeVideo()?.paused) {
          <cheelee-icon
            iconId="pause-video-icon"
            size="12"
          />
        } @else {
          <cheelee-icon
            iconId="play-video-icon"
            size="12"
          />
        }
      </div>
    }

    @if (canShowLikeEffect()) {
      <div
        class="swiper__like-effect"
        (animationend)="canShowLikeEffect.set(false)"
      ></div>
    }
  </div>

<!--  <cheelee-loader class="swiper__loader" />-->
</div>
