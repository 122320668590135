export enum AnalyticsEvents {
  FeedTap = 'Feed_Tap',

  // FeedAppear = 'Feed_Appear',

  CreateTap = 'Create_Tap',

  EarnTap = 'Earn_Tap',

  MarketTap = 'Market_Tap',

  MyProfileTap = 'MyProfile_Tap',

  MediaPlayTap = 'Media_Play_Tap',
  MediaPauseTap = 'Media_Pause_Tap',
  MediaLikeTap = 'Media_Like_Tap',
  MediaCommentTap = 'Media_Comment_Tap',
  MediaShareTap = 'Media_Share_Tap',
  MediaMenuTap = 'Media_Menu_Tap',
  MediaUserTap = 'Media_User_Tap',

  DonateValueTap = 'Donate_Value_Tap',
  FeedEasyTap = 'Feed_Easy_Tap',

  ModalGoToCheeleeTap = 'Modal_Go_To_Cheelee_Tap',
}