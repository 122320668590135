import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OverlayService } from '@core/services';
import { ModalsNames } from '@data/const';
import { DialogWrapperComponent, IconComponent } from '@shared/components';
import { I18NextModule } from 'angular-i18next';
import { NgOptimizedImage } from '@angular/common';
import { RippleDirective } from '@shared/directives';

@Component({
  selector: 'cheelee-qr-dialog',
  standalone: true,
  imports: [
    DialogWrapperComponent,
    I18NextModule,
    IconComponent,
    NgOptimizedImage,
    RippleDirective,
  ],
  templateUrl: './qr-dialog.component.html',
  styleUrl: './qr-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrDialogComponent {
  constructor(
    private readonly overlayService: OverlayService,
  ) {}

  public close(): void {
    this.overlayService.close(ModalsNames.Qr);
  }
}
