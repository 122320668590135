import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { BreakpointFacade } from '@core/facades';
import { RouterLink } from '@angular/router';
import { IconComponent } from '@shared/components';
import { RippleDirective } from '@shared/directives';

@Component({
  standalone: true,
  selector: 'cheelee-header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ CommonModule, RouterLink, NgOptimizedImage, RippleDirective, IconComponent ],
})
export class HeaderComponent {

  public isFixed = input<boolean>(false);

  public background = input<string | null>(null);

  public maxWidth = input<string | undefined>(undefined);

  public readonly isDesktop = this.breakpointFacade.isDesktop;

  constructor(
    private breakpointFacade: BreakpointFacade,
  ) { }
}
